import { CardBody, Row, Col, FormGroup, Input, Button, Label, UncontrolledTooltip } from "reactstrap"

import { useState, useEffect } from 'react'
import tooltipText from "../../config/tooltipText";
import WizardPlayer from "../Wizards/AdWizardComps/audioEditorComp/WizardPlayer";
function CreateAd(props) {

    const [chosenContent, setChosenContent] = useState([])

    const handleUrls = () => {
        const contnet = props.baseObject.content.find((con) => con.id == props.baseObject.selectedContent)
        setChosenContent(contnet)
    }

    useEffect(() => {
        if (props.baseObject?.content && props.baseObject?.content.length > 0) {
            handleUrls()
        }
    }, [props.baseObject])

    const handleDownload = async (downloadLink, language) => {
        try {
            const response = await fetch(downloadLink)
            const blob = await response.blob()

            const url = URL.createObjectURL(blob)

            const link = document.createElement('a')
            link.href = url
            link.download = `${props.baseObject.name}-${language}.mp3`
            document.body.appendChild(link)
            link.click()

            document.body.removeChild(link)
            URL.revokeObjectURL(url)
        } catch (error) {
            console.error('Download failed:', error)
        }
    };

    const [playingAudioId, setPlayingAudioId] = useState(null)

    return (
        <>
            <div className="content">
                <CardBody>
                    <Row>
                        <Col>

                            <FormGroup>
                                <Label className="labelText">Name</Label>
                                <Button
                                    className="btn-icon dropdownIgnore tooltipHover"
                                    color="danger"
                                    id="tooltip808966390111"
                                    size="sm"
                                    style={{ backgroundColor: "white" }}
                                >
                                    <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                                </Button>
                                <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966390111"
                                    style={{ backgroundColor: "#F33B30", color: "white" }}

                                >
                                    <p style={{ margin: 0 }}>{tooltipText.ADVERTS.ADVERT_NAME}</p>
                                </UncontrolledTooltip>
                                <Input className="inputStyle placeholderWhite" type="text" value={props.baseObject.name} onChange={(e) => props.setBaseObject({ ...props.baseObject, name: e.target.value })} />
                                {props.missingFields.includes("name") ? <p style={{ color: "#F33B30", fontWeight: "bold" }}>Please enter a name for the advert</p> : null}
                            </FormGroup>

                        </Col>

                        <Col>


                            <FormGroup className="mr-4">
                                <Label className="labelText">Budget</Label>
                                <Button
                                    className="btn-icon btn-neutral dropdownIgnore tooltipHover"

                                    id="tooltip808966390111323"
                                    size="sm"
                                    style={{ backgroundColor: "white" }}

                                >
                                    <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                                </Button>
                                <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966390111323"
                                    style={{ backgroundColor: "#F33B30", color: "white" }}
                                >
                                    <p style={{ margin: 0 }}>{tooltipText.ADVERTS.BUDGET}</p>
                                </UncontrolledTooltip>
                                <Input disabled className="inputStyle placeholderWhite" placeholder="0" type="number" value={props.baseObject.budget} onChange={(e) => props.setBaseObject({ ...props.baseObject, budget: e.target.value })} />
                                {props.missingFields.includes("budget") ? <p style={{ color: "#F33B30", fontWeight: "bold" }}>Budget must be more than 0</p> : null}
                            </FormGroup>

                        </Col>
                    </Row>
                    <Row>
    <Col>
        {chosenContent.urls && chosenContent.urls.length > 0 &&
            chosenContent.urls.map((aud, index) => {
                return (
                    <div
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between", 
                            marginBottom: "10px", 
                        }}
                        key={index}
                    >
                        <p
                            className="labelText"
                            style={{ minWidth: "80px", marginRight: 10, whiteSpace: "nowrap" }}
                        >
                            {props.languages.find((lang) => lang.value === aud.language)?.label}
                        </p>
                        
                        <div style={{ flex: "1" }}>
                            <WizardPlayer
                                fileURL={aud.url}
                                changeScheme={true}
                                playingAudioId={playingAudioId}
                                setPlayingAudioId={setPlayingAudioId}
                                audioId={index}
                            />
                        </div>

                        {chosenContent.downloadable && (
                            <div
                            style={{
                                display: "flex",
                                alignItems: "center",
                                paddingLeft: "10px",
                                cursor: "pointer", 
                            }}
                                onClick={() => handleDownload(aud.url, aud.language)}
                        >
                            <i
                                className="fa fa-file-arrow-down"
                                style={{
                                    color: "#FFFFFF", 
                                    fontSize: "1.5rem",
                                    paddingTop: "1.5rem"
                                }}
                            />
                        </div>
                        )}
                    </div>
                );
            })
        }
    </Col>
</Row>


                </CardBody>
            </div>

        </>
    )
}

export default CreateAd