import React, { useEffect, useState } from "react";

import apiConfig from "config/apiConfig";
import axios from "axios"
import ReactPlayer from "react-player";

// reactstrap components
import {
    CardBody,
    Row,
    Col,
    Spinner
} from "reactstrap";
import VoiceCard from "../../components/Wizards/BusinessWizardSteps/VoiceCard";

function Voices() {

    const [isLoading, setIsLoading] = useState(false)
    const [premiumVoices, setPremiumVoices] = useState([])
    const getPremiumVoices = async () => {
        setIsLoading(true)
        let url = `${apiConfig.baseUrl}${apiConfig.VOICE.GET_EL_VOICES}`
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    const updatedVoices = res.data.map(voice => {
                        return {
                            ...voice, // Copy all other properties
                            voiceAiValue: voice.voicePreview, // Set voiceAiValue to the value of voicePreview
                        };
                    });
                    setPremiumVoices(updatedVoices);
                }
            })
            .catch((err) => {
                console.error(err)
            });

        setIsLoading(false)
    }


    const [voiceSearchResults, setVoiceSearchResults] = useState([])
    const [isUserSearch, setIsUserSearch] = useState(false)
    const [userSearchString, setUserSearchString] = useState(null)

    const filterPremiumVoices = (searchString) => {
        const lowerCaseSearchString = searchString.toLowerCase();

        const filteredVoices = premiumVoices.filter(voice => {
            return (
                (voice.age && voice.age.toLowerCase().includes(lowerCaseSearchString)) ||
                (voice.name && voice.name.toLowerCase().includes(lowerCaseSearchString)) ||
                (voice.style && voice.style.toLowerCase().includes(lowerCaseSearchString)) ||
                (voice.gender && voice.gender.toLowerCase().includes(lowerCaseSearchString))
            );
        });

        const uniqueVoices = [];
        const voiceSet = new Set();

        filteredVoices.forEach(voice => {
            const voiceKey = `${voice.age}-${voice.name}-${voice.style}-${voice.gender}`;
            if (!voiceSet.has(voiceKey)) {
                voiceSet.add(voiceKey);
                uniqueVoices.push(voice);
            }
        });

        setVoiceSearchResults(uniqueVoices);
        setIsUserSearch(true)
    };

    const [selectedVoiceUrl, setSelectedVoiceUrl] = useState(null)
    const [isVoicePreview, setIsVoicePreview] = useState(false)
    const handlePreviewVoice = async (previewUrl) => {
        await setSelectedVoiceUrl(null)
        await setIsVoicePreview(false)
        await setSelectedVoiceUrl(previewUrl)
        await setIsVoicePreview(true)
    }

    const handleSelectVoice = () => {
        return
    }

    <ReactPlayer url={selectedVoiceUrl}
        playing={isVoicePreview}
        controls={false}
        height='0px'
        width='0px'
        className="podcastPlayer"
        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
    />

    useEffect(() => {
        getPremiumVoices()
    }, [])


    useEffect(() => {
        if (userSearchString === null) {
            setIsUserSearch(false)
        }
        if (premiumVoices.length > 0 && userSearchString) {
            filterPremiumVoices(userSearchString)
        }
    }, [premiumVoices, userSearchString])

    useEffect(() => {
        const handleVoiceSearch = (event) => {

            if (event.detail.searchString === null) {
                setUserSearchString(null)
                setIsUserSearch(false)
                return
            }

            const searchValue = event.detail.searchString.toLowerCase();
            setUserSearchString(searchValue);
        };

        document.addEventListener('voiceSearch', handleVoiceSearch);

        return () => {
            document.removeEventListener('voiceSearch', handleVoiceSearch);
        };
    }, []);


    return (
        <>
            <ReactPlayer url={selectedVoiceUrl}
                playing={isVoicePreview}
                controls={false}
                height='0px'
                width='0px'
                className="podcastPlayer"
                config={{ file: { attributes: { controlsList: 'nodownload' } } }}
            />
            <div className="content" style={{ marginTop: 78, paddingLeft: "5vh", paddingRight: "5vh" }}>
                <CardBody>
                    <Row className="justify-content-center pt-4 mt-2">
                        <Col>
                            {!isLoading ?
                                premiumVoices.length > 0 ? (

                                    <Row>
                                        {!isUserSearch ? premiumVoices.map((e, index) => {
                                            return (
                                                <Col sm="6">
                                                    <VoiceCard voiceData={e} index={index} handlePreviewVoice={handlePreviewVoice} handleSelectVoice={handleSelectVoice} colorSchemeTheme={"light"} isAdVoice={true} />
                                                </Col>
                                            )
                                        }) :
                                            voiceSearchResults.map((e, index) => {
                                                return (
                                                    <Col sm="6">
                                                        <VoiceCard voiceData={e} index={index} handlePreviewVoice={handlePreviewVoice} handleSelectVoice={handleSelectVoice} colorSchemeTheme={"light"} isAdVoice={true} />
                                                    </Col>
                                                )
                                            })
                                        }
                                    </Row>


                                ) :
                                    <Row>
                                        <Col className="text-center">
                                            <h4>No voices found</h4>
                                        </Col>
                                    </Row>
                                : <Row>
                                    <Col className="text-center">
                                        <h4>Loading previews</h4>
                                        <Spinner style={{ color: "#F33B30" }} />
                                    </Col>
                                </Row>
                            }
                        </Col>
                    </Row>
                </CardBody>
            </div>
        </>
    );
}

export default Voices;
