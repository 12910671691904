import { CardBody, Row, Col, FormGroup, Input, Button, Label, UncontrolledTooltip } from "reactstrap"

import { useState, useEffect } from 'react'
import Select from "react-select";
import tooltipText from "../../config/tooltipText";
import MusicEditor from "./MusicEditor";
function CreateMusic(props) {
    const [multipleSelect, setMultipleSelect] = useState(null);
    const handleTagSelect = (value) => {

        if (value === null) {
            setMultipleSelect([])
            return
        }
        setMultipleSelect(value)

        const reformatObject = value.map((e) => ({
            id: e.value,
            name: e.label
        }))

        props.setBaseObject({ ...props.baseObject, tags: reformatObject })
    }

    useEffect(() => {
        if (props.baseObject.tags.length > 0) {
            const reformatTags = props.baseObject.tags.map((tag) => {
                return ({
                    value: tag.id,
                    label: tag.name
                })
            })
            setMultipleSelect(reformatTags)
        }
    }, [])


    return (
        <>
            <div className="content">
                <CardBody>
                    <Row>
                        <Col>

                            <MusicEditor baseObject={props.baseObject} setBaseObject={props.setBaseObject} />
                            {props.missingFields.includes("audio") ? <p style={{ color: "#F33B30", fontWeight: "bold" }}>Please enter add some audio</p> : null}
                            <FormGroup>
                                <Label className="labelText">Name</Label>
                                <Button
                                    className="btn-icon dropdownIgnore tooltipHover"
                                    color="danger"
                                    id="tooltip808966390111"
                                    size="sm"
                                    style={{ backgroundColor: "white" }}
                                >
                                    <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                                </Button>
                                <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966390111"
                                    style={{ backgroundColor: "#F33B30", color: "white" }}
                                    
                                >
                                    <p style={{ margin: 0 }}>{tooltipText.ADVERTS.ADVERT_NAME}</p>
                                </UncontrolledTooltip>
                                <Input className="inputStyle placeholderWhite" type="text" value={props.baseObject.name} onChange={(e) => props.setBaseObject({ ...props.baseObject, name: e.target.value })} />
                                {props.missingFields.includes("name") ? <p style={{ color: "#F33B30", fontWeight: "bold" }}>Please enter a name for the advert</p> : null }
                            </FormGroup>

                            <Label className="labelText">Music Tags</Label>
                            <FormGroup>
                                <Select
                                    className="react-select klaxon-multi"
                                    classNamePrefix="react-select"
                                    placeholder="Music Tags"
                                    name="multipleSelect"
                                    closeMenuOnSelect={false}
                                    isMulti
                                    value={multipleSelect}
                                    onChange={(value) => handleTagSelect(value)}
                                    options={props.musicTagData}
                                />
                                {props.missingFields.includes("tags") ? <p style={{ color: "#F33B30", fontWeight: "bold" }}>Please add at least one tag</p> : null}
                            </FormGroup>
                        </Col>

                    </Row>

                    <Row>
                        <Col>

                        </Col>
                    </Row>
                    
                </CardBody>
            </div>

        </>
    )
}

export default CreateMusic