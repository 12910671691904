import axios from "axios";
import apiConfig from "config/apiConfig";
import Cookies from "js-cookie";

const apiCall = async (url, method = "post", data = null) => {
    const cookie = JSON.parse(Cookies.get("userData"));
    const token = cookie?.token;

    const headers = {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json', 
   
    };

    const axiosConfig = {
        method,
        headers,
    };

    axiosConfig.url = apiConfig.baseUrl + url;

    if (data) {
        axiosConfig.data = data;
    }

    try {
        const response = await axios(axiosConfig);

        // If the response is successful (status 200), return the data
        if (response.status === 200) {
            return response;
        }

        // If the response status is 401, check the response message
        if (response.status === 401) {
            console.log('401 reponse -----', response)
            if (response.data === "") {
                // Return 401 if the message is blank
                throw new Error("Unauthorized");

            } else if (response.data === "token expired") {
                // Call the RefreshToken method to get a new token
                const newToken = await refreshAccessToken();

                // Retry the original API call with the new token
                const retryResponse = await axios.post(url, data, {
                    headers: { Authorization: `Bearer ${newToken}` },
                });

                // Return the data from the retried call
                return retryResponse;
            }
        }

        // Handle other error cases
        throw new Error(`API call failed with status: ${response.status}`);
    } catch (error) {

        // Log or handle the error as needed
        console.error("API call error:", error);

        try {
            const newToken = await refreshAccessToken();

            axiosConfig.headers = {
                Authorization: `Bearer ${token}`,
            };

            const retryResponse = await axios(axiosConfig);
            return retryResponse.data;

        } catch (error) {
            throw error; // Propagate the error to the calling code
        }

    }
};

const refreshAccessToken = async () => {
    const cookie = JSON.parse(Cookies.get("userData"));

    let data = {
        token: cookie?.token,
        refreshToken: cookie?.refreshToken,
    };

    const refreshedToken = await axios.post(
        apiConfig.baseUrl + apiConfig.Account.RefreshToken,
        data
    );

    Cookies.set("userData", JSON.stringify(refreshedToken?.data));

    return refreshedToken;
};

export default apiCall;
