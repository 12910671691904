import React, { useState } from "react"
import { Row, Col, Spinner } from "reactstrap"
import StripeContainer from "../../Containers/StripeContainer"

function PaymentStep2(props) {

    const [alert, setAlert] = useState(null)
    const subTierObject = props.subscriptionTypes.find((e) => e.id === props.baseObject.subscriptionId)
    
    return (
        <>
            {alert ? <Col sm="6" className="text-center" style={{color: "red", fontWeight: "bold"}}>{alert}</Col> : null }
        <Row>
            <Col className="d-flex align-items-center justify-content-center">
                    {!props.isLoading ? <StripeContainer baseObject={props.baseObject} setBaseObject={props.setBaseObject} setAlert={setAlert} /> : <Spinner /> }
            </Col>
            <Col className="d-flex justify-content-center text-white">
                    <Col className="inputStyle p-4" style={{borderRadius: 5}} sm="10">
                        <Row className="justify-content-between">
                        <p style={{ margin: 0, marginBottom: 15, paddingRight: 15, paddingLeft: 15, fontSize: 18 }}>Membership Tier:</p>
                            <p style={{ margin: 0, marginBottom: 15, paddingRight: 15, paddingLeft: 15, fontSize: 18 }}>{subTierObject.name.split(" ")[0]}</p>
                    </Row>
                    <Row className="justify-content-between">
                            <p style={{ margin: 0, marginBottom: 15, paddingRight: 15, paddingLeft: 15, paddingTop: 15, fontSize: 18 }}>Subscription Price:</p>
                            <p style={{ margin: 0, marginBottom: 15, paddingRight: 15, paddingLeft: 15, paddingTop: 15, fontSize: 18 }}>&pound;{subTierObject.price}</p>
                    </Row>
                    <Row className="justify-content-between">
                            <p style={{ margin: 0, marginBottom: 15, paddingRight: 15, paddingLeft: 15, paddingTop: 15, fontSize: 18 }}>Subscription Duration:</p>
                            <p style={{ margin: 0, marginBottom: 15, paddingRight: 15, paddingLeft: 15, paddingTop: 15, fontSize: 18 }}>{subTierObject.name.split(" ")[1].replace(/\(|\)/g, '')}</p>
                    </Row>
                </Col>
            </Col>
            </Row>
        </>
    )
}

export default PaymentStep2