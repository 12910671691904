import { Row, Col, Button, Input, FormGroup } from "reactstrap"

import KlaxonLogo from "assets/img/klaxonImages/klaxonFull.png"

import MIC from "assets/img/clonepage/mic.jpg"
import { useState } from "react"
import { useEffect } from "react"
import axios from "axios"
import apiConfig from "../../config/apiConfig"

const KlaxonWaitList = () => {

    const [isContactFormOpen, setIsContactFormOpen] = useState(false)
    const [contactForm, setContactForm] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        industry: "",
        message: ""
    })

    const [invalidFirstName, setInvalidFirstName] = useState(false)
    const [invalidLastName, setInvalidLastName] = useState(false)
    const [invalidEmail, setInvalidEmail] = useState(false)

    const [listJoined, setListJoined] = useState(false)
    const [listError, setListError] = useState("")

    const handleSubmit = async () => {
        let isError = false
        if (contactForm.firstName === "") {
            setInvalidFirstName(true)
            isError = true
        }

        if (contactForm.lastName === "") {
            setInvalidLastName(true)
            isError = true
        }

        if (contactForm.email === "") {
            setInvalidEmail(true)
            isError = true
        }

        if (isError)
            return

        const { email, firstName, lastName, phone, industry, message } = contactForm
        let url = `${apiConfig.baseUrl}${apiConfig.MAILING_LIST.JOIN_CONTACT_LIST}?email=${email}&firstName=${firstName}&lastName=${lastName}`

        if (phone != "") {
            url = url + `&phoneNumber=${phone}`
        }

        if (industry != "") {
            url = url + `&industry=${industry}`
        }

        let waitingListMessage = "WAITING LIST SIGN UP"
        if (message != "") {

            url = url + `&message=${waitingListMessage} - ${message}`
        } else {
            url = url + `&message=${waitingListMessage}`
        }


        await axios
        .post(url)
        .then((res) => {
            if (res?.status === 200) {
                setListJoined(true)
            } 
        })
        .catch((err) => {    
            if (err.response?.data) {
            setListError(err.response.data);
        } else {
            setListError("An unexpected error occurred."); // Fallback message
        }
        });
    } 
    
    const resetContactForm = () => {
        setIsContactFormOpen(false)
        setListError("")
        setListJoined(false)
    }

    useEffect(() => {
        setInvalidFirstName(false)
    }, [contactForm.firstName])
    useEffect(() => {
        setInvalidLastName(false)
    }, [contactForm.lastName])
    useEffect(() => {
        setInvalidEmail(false)
    }, [contactForm.email])

    return (
        <>

            <Col>
                <Row>
                    <div style={{
                        position: "relative",
                        width: "100%",
                        minHeight: "70vh",
                        overflow: "hidden"
                    }}>
                        <div style={{
                            backgroundImage: `url(${require("assets/img/bg/bg.jpg")})`,
                            width: "100%",
                            height: "100%",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                            backgroundRepeat: "no-repeat",
                            filter: "brightness(0.6)",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            zIndex: 0
                        }} />
                        <Row>
                            <Col>

                                <div style={{ width: "100%", textAlign: "center" }} className="mt-4">
                                    <img src={KlaxonLogo} alt="Logo" style={{ height: 45 }} />
                                </div>
                                <Row style={{ display: "flex", justifyContent: "center", width: "", margin: "auto" }} className="mt-4 waitingListTop">
                                    <div style={{ display: "flex", justifyContent: "center" }} className="changeDir">
                                        <div style={{ position: "relative", zIndex: 1, width: "50%", display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center" }} className="mt-4">

                                            <div style={{ width: "100%", display: "flex", justifyContent: "center" }} className="mt-5 pt-2">
                                                <p style={{ width: "80%", textAlign: "center", color: "#fff", fontSize: 22 }}>
                                                    Need news on the go but too busy to scroll blogs and websites?
                                                </p>
                                            </div>
                                            <div>
                                                <p style={{ color: "yellow", textAlign: "center", fontSize: 30, fontWeight: "bold" }}>Create personalised podcasts at the touch of a button</p>
                                                <p style={{ color: "#fff", textAlign: "center", fontSize: 22 }}>No need for a Script, Tech Skills, Recording Equipment, or even a ‘Broadcast’ Voice. </p>

                                                <p style={{ color: "yellow", textAlign: "center", fontWeight: "", fontStyle: "italic", fontSize: 20 }}>COMING SOON</p>

                                            </div>
                                        </div>

                                        <div style={{ display: "flex", justifyContent: "center", height: "100%", width: "50%"}}>
                                            <div style={{ backgroundColor: "#fff", borderRadius: 15, display: "flex", flexDirection: "column", justifyContent: listJoined && "center", width: "80%", minHeight: "", alignItems: "center", padding: 10, gap: 10 }}>

                                                {!listJoined && listError == "" ?
                                                    <>
                                                        <div>
                                                            <p style={{ margin: 0, fontWeight: "bold", padding: 5 }}>JOIN THE PRE-LAUNCH LIST</p>
                                                            <p style={{ margin: 0, padding: 5 }}>Get priority access to the beta!</p>
                                                        </div>

                                                        <div>
                                                            <Col>
                                                                <Row>
                                                                    <Col>
                                                                        <FormGroup className="backgroundOutside">
                                                                            <Input className="softerBorder" style={{ border: !invalidFirstName ? "1px solid black" : "1px solid red" }} placeholder="First name *" onChange={(e) => setContactForm({ ...contactForm, firstName: e.target.value })} />
                                                                        </FormGroup>
                                                                    </Col>

                                                                    <Col>
                                                                        <FormGroup className="backgroundOutside">
                                                                            <Input className="softerBorder" style={{ border: !invalidLastName ? "1px solid black" : "1px solid red" }} placeholder="Last name *" onChange={(e) => setContactForm({ ...contactForm, lastName: e.target.value })} />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col>
                                                                        <FormGroup className="backgroundOutside">
                                                                            <Input className="softerBorder" style={{ border: !invalidEmail ? "1px solid black" : "1px solid red" }} placeholder="Email *" onChange={(e) => setContactForm({ ...contactForm, email: e.target.value })} />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col>
                                                                        <FormGroup className="backgroundOutside">
                                                                            <Input className="softerBorder" style={{ border: "1px solid black", maxHeight: "none" }} placeholder="Message" onChange={(e) => setContactForm({ ...contactForm, message: e.target.value })} type="textarea" rows={4} />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </div>

                                                        <div style={{ display: "flex", flexDirection: "column", textAlign: "center", alignItems: "center", flexGrow: 1 }}>
                                                            <Button style={{ backgroundColor: "darkorange", color: "#fff", width: "90%", fontWeight: "normal", textTransform: "none", fontSize: 18, borderRadius: 13 }} onClick={handleSubmit} className="buttonChanger">Submit</Button>
                                                            <div style={{ backgroundColor: "", width: "90%", margin: "auto", display: "flex", alignItems: "center",  }}>

                                                                By registering you consent to receive reminder emails and occasional promotional emails which you can opt out of at any time

                                                            </div>
                                                        </div>
                                                    </> : null
                                                }
                                                {listJoined && 
                                                    <>      
                                                        <div>
                                                            <p style={{ margin: 0, fontWeight: "bold", padding: 5 }}>JOIN THE PRE-LAUNCH LIST</p>
                                                            <p style={{ margin: 0, padding: 5 }}>Get priority access to the beta!</p>
                                                        </div>

                                                        <div>
                                                        <div style={{ display: "flex", flexDirection: "column", textAlign: "center", alignItems: "center" }}>
                                                            <div style={{ backgroundColor: "", width: "100%", margin: "auto" }}>
                                                                Thanks {contactForm.firstName}, we'll be in touch
                                                            </div>
                                                            <Button style={{ backgroundColor: "rgb(28, 120, 254)", color: "#fff", width: "90%", fontWeight: "normal", textTransform: "none" }} onClick={() => resetContactForm()} className="buttonChanger">Great</Button>
                                                            </div>    
                                                        </div>
                                                    </>
                                                }
                                                {listError !== "" && 
                                                    <>       
                                                        <div>
                                                            <p style={{ margin: 0, fontWeight: "bold", padding: 5 }}>JOIN THE PRE-LAUNCH LIST</p>
                                                            <p style={{ margin: 0, padding: 5 }}>Get priority access to the beta!</p>
                                                        </div>

                                                        <div>
                                                            <div style={{ display: "flex", flexDirection: "column", textAlign: "center", alignItems: "center" }}>
                                                                <div style={{ backgroundColor: "", width: "100%", margin: "auto" }}>
                                                                    {listError}
                                                                </div>
                                                                <Button style={{ backgroundColor: "rgb(28, 120, 254)", color: "#fff", width: "90%", fontWeight: "normal", textTransform: "none" }} onClick={() => resetContactForm()} className="buttonChanger">Great</Button>
                                                            </div>    
                                                        </div>
                                                    </>
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </Row>

                            </Col>
                        </Row>





                    </div>
                </Row>
                <Row>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column" }} className="mt-5 pt-4">
                        <div>
                            <h3 className="cc">CREATE YOUR OWN PERSONALISED PODCAST IN A FEW CLICKS</h3>
                        </div>
                        <div style={{
                            width: "80%"
                        }}>
                            <Row className="changeSide">
                                <Col>
                                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", gap: 15, textAlign: "center" }}>
                                        <div style={{ width: 75, height: 75, borderRadius: "50%", backgroundColor: "#000", color: "#fff", display: "flex", alignItems: "center", justifyContent: "center", fontSize: 32, fontWeight: "bold" }}>
                                            1
                                        </div>
                                        <p style={{ margin: 0, fontWeight: "bold" }}>Choose up to 5 topics</p>
                                        <p style={{ margin: 0 }}>Whether it's the latest on business, your football team or favourite hobby, choose up to five topics for your podcast</p>
                                    </div>
                                </Col>
                                <Col>
                                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", gap: 15, textAlign: "center" }}>
                                        <div style={{ width: 75, height: 75, borderRadius: "50%", backgroundColor: "#000", color: "#fff", display: "flex", alignItems: "center", justifyContent: "center", fontSize: 32, fontWeight: "bold" }}>
                                            2
                                        </div>
                                        <p style={{ margin: 0, fontWeight: "bold" }}>Select the length and frequency</p>
                                        <p style={{ margin: 0 }}>From 5 minutes to 30, decide how long you want your podcast to be. Then choose whether you want it to be a one-off or automatically publish on a daily, weekly, fortnightly or monthly basis.</p>
                                    </div>
                                </Col>
                                <Col style={{ display: "flex", alignItems: "" }}>
                                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", gap: 15, textAlign: "center" }}>
                                        <div style={{ width: 75, height: 75, borderRadius: "50%", backgroundColor: "#000", color: "#fff", display: "flex", alignItems: "center", justifyContent: "center", fontSize: 32, fontWeight: "bold" }}>
                                            3
                                        </div>
                                        <p style={{ margin: 0, fontWeight: "bold" }}>Which language?</p>
                                        <p style={{ margin: 0 }}>Choose from dozens of languages and accents to voice your podcast. From English to Hindi, we've got you covered. </p>
                                    </div>
                                </Col>
                                <Col style={{ display: "flex", alignItems: "" }}>
                                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", gap: 15, textAlign: "center" }}>
                                        <div style={{ width: 75, height: 75, borderRadius: "50%", backgroundColor: "#000", color: "#fff", display: "flex", alignItems: "center", justifyContent: "center", fontSize: 32, fontWeight: "bold" }}>
                                            4
                                        </div>
                                        <p style={{ margin: 0, fontWeight: "bold" }}>Publish, listen and share</p>
                                        <p style={{ margin: 0 }}>Klaxon crawls 60,000 websites for information and creates a unique script for your podcast in just a couple of minutes. Edit the script (if you want) and publish. Listen at your leisure and share your podcast with friends and followers. (You can even earn income from ad revenue as your audience grows.)</p>
                                    </div>
                                </Col>

                            </Row>


                        </div>
                    </div>
                </Row>
                <Row>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column" }} className="mt-5 pt-4">
                        <div>
                            <h3 style={{ textAlign: "center" }} className="definedTitle">Want to know more?</h3>
                            <h3 style={{ textAlign: "center" }} className="definedTitle">Listen to our Klaxon Podcast</h3>
                        </div>
                        <div className="ytCont">
                            <iframe className="ytSize" src="https://www.youtube.com/embed/UuQJ6uU3Ti8?si=qmlwLEtKv1FSeV9U" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
                        </div>
                    </div>
                </Row>


                <Row>
                    <div style={{ width: "100%", display: "flex", alignItems: "center", flexDirection: "column" }} className="mt-5 pt-4 mb-5">


                        <div style={{ backgroundColor: "", width: "", display: "flex", justifyContent: "center" }} className="cardContain">


                            <div style={{ borderRadius: 35, width: "", display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "" }} className="bigger">
                                <img src={MIC} style={{ borderRadius: "50%", height: 150, width: 150 }} />
                                <div style={{ width: "90%" }} className="mt-2">

                                    <p style={{ textAlign: "center", margin: 0, fontSize: 13, fontStyle: "italic" }} className="mt-2">
                                        "By using technology to make the process of podcast production easier and more creator-accessible, Klaxon AI can really democratize the podcasting industry. This may result in a considerable amount of diverse voices and perspectives being brought into the limelight across the podcasting community."
                                    </p>
                                    <p style={{ textAlign: "center", margin: 0, fontSize: 14, fontWeight: "bold" }} className="mt-2">Podwires.com</p>
                                </div>
                            </div>


                        </div>
                    </div>

                </Row>


            </Col>
        </>
    )
}

export default KlaxonWaitList