import React, { useEffect, useState } from "react";

import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import Swal from "sweetalert2";
import ReactBSAlert from "react-bootstrap-sweetalert"
import MyModal from "components/Shared/MyModal";
import TutorialModal from "../../components/Shared/TutorialModal";
import tutorialText from "config/tutorialText";
import "../../assets/css/custom-css.css"

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Table,
    UncontrolledTooltip,
    Input,
    Spinner

} from "reactstrap";
import CreateMusicTag from "../../components/Music/CreateMusicTag";

function MusicTag() {

    const modalWidth = window.innerWidth < 1050 ? "90%" : "1000px"

    const [searchResults, setSearchResults] = useState([])
    const [musicTagData, setMusicTagData] = useState([])
    const [selectedMusicTag, setSelectedMusicTag] = useState([])
    const [modalOpen, setModalOpen] = useState(false);
    const [alert, setAlert] = useState(null)
    const [showTutorial, setShowTutorial] = useState(true);
    const [isLoading, setIsLoading] = useState(false)
    const [missingFields, setMissingFields] = useState([])


    const hideAlert = () => {
        setAlert(null)
    }


    const [baseObject, setBaseObject] = useState({
        id: 0,
        name: "",
    })



    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const handleSearchBar = (query) => {
        const originalSearch = musicTagData
        const searchName = originalSearch.filter((e) => e.name.toLowerCase().includes(query.toLowerCase()))
        setSearchResults(Array.from(new Set([...searchName, ...searchDescription])))
    }

    const handleCreateMusic = () => {
        setMissingFields([])
        setSelectedMusicTag(baseObject)
        toggleModal()
    }

    const handleEditMusic = (id) => {
        setMissingFields([])
        setSelectedMusicTag(musicTagData.find((ad) => ad.id === id))
        toggleModal()
    }

    const handleDeleteMusicTagClicked = (id) => {
        setSelectedMusicTag(musicTagData.find((ad) => ad.id === id))
        const fastData = musicTagData.find((ad) => ad.id === id)

        setAlert(
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => handleMusicTagDelete(id)}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="danger"
                confirmBtnText="Yes, delete!"
                cancelBtnText="Cancel"
                showCancel
                btnSize=""
            >
                You are about to delete {fastData.name}.
            </ReactBSAlert>
        )
    }

    const handleMusicTagDelete = async (id) => {
        try {
            const resp = await apiCall(
                apiConfig.ADVERT.DELETE + `?id=${id}`,
                "delete"
            );
            if (resp.status === 200) {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Music Tag Deleted",
                    showConfirmButton: false,
                    timer: 2500,
                    customClass: {
                        container: 'custom-swal-container',
                        popup: 'custom-swal-popup',
                        title: 'custom-swal-title',
                        icon: 'custom-swal-icon',
                    },
                })
                hideAlert()
                getMusicTag()
            } else {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Something went wrong",
                    showConfirmButton: false,
                    timer: 2500,
                    customClass: {
                        container: 'custom-swal-container',
                        popup: 'custom-swal-popup',
                        title: 'custom-swal-title',
                        icon: 'custom-swal-icon',
                    },
                })
            }


        } catch (error) {
            console.error(error.message);
            hideAlert()
        }
    }

    const getMusicTag = async () => {
        setIsLoading(true)

        try {
            const resp = await apiCall(
                apiConfig.MUSIC_TAG.GET_ALL,
                "get"
            );
            if (resp.status === 200) {
                setIsLoading(false)
                setMusicTagData(resp.data)
                setSearchResults(resp.data)
            }

        } catch (error) {
            console.error(error.message);
            setIsLoading(false)
        }
    };

    const createEditMusicTag = async () => {
        if (!handleValidSubmit()) {
            return
        }

        if (selectedMusicTag.id === 0) {

            try {
                const resp = await apiCall(
                    apiConfig.MUSIC_TAG.ADD,
                    "post", selectedMusicTag
                );
                if (resp.status === 200) {
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Music Tag created",
                        showConfirmButton: false,
                        timer: 2500,
                        customClass: {
                            container: 'custom-swal-container',
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            icon: 'custom-swal-icon',
                        },
                    })
                    toggleModal()
                    getMusicTag()
                } else {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Something went wrong",
                        showConfirmButton: false,
                        timer: 2500,
                        customClass: {
                            container: 'custom-swal-container',
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            icon: 'custom-swal-icon',
                        },
                    })
                }


            } catch (error) {
                console.error(error.message);
            }
        } else {
            try {
                const resp = await apiCall(
                    apiConfig.MUSIC_TAG.UPDATE + `?id=${selectedMusicTag.id}`,
                    "put", selectedMusicTag
                );
                if (resp.status === 200) {
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Music Tag Updated",
                        showConfirmButton: false,
                        timer: 2500,
                        customClass: {
                            container: 'custom-swal-container',
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            icon: 'custom-swal-icon',
                        },
                    })
                    getMusicTag()
                    toggleModal()
                } else {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Something went wrong",
                        showConfirmButton: false,
                        timer: 2500,
                        customClass: {
                            container: 'custom-swal-container',
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            icon: 'custom-swal-icon',
                        },
                    })
                }


            } catch (error) {
                console.error(error.message);
            }
        }
    };

    const handleValidSubmit = () => {
        const missingInputs = []
        if (selectedMusicTag.name === "") missingInputs.push("name")




        if (missingInputs.length > 0) {
            setMissingFields(missingInputs)
            return false
        }
        setMissingFields([])
        return true
    }

    // Update to use new endpoint
    //const userToken = getJWTToken().decoded.nameid
    //const superUser = [1, 2, 3, 4, 6]
    //const isSuperUser = superUser.includes(Number(userToken))
    const isSuperUser = true

    useEffect(() => {
        getMusicTag();
    }, []);


    return (
        <>
            {
                isSuperUser ? (
                    <>
                        {alert}
                        < TutorialModal
                            showTutorial={showTutorial}
                            pageName='ads'
                            title="Ads"
                            text={tutorialText.ADS}
                            setShowTutorial={setShowTutorial} />
                        <MyModal isOpen={modalOpen} toggle={toggleModal} title="Music Tags" width={modalWidth} buttonAction={createEditMusicTag}>
                            <CreateMusicTag baseObject={selectedMusicTag} setBaseObject={setSelectedMusicTag} missingFields={missingFields} />
                        </MyModal>
                        <div className="content">

                            <Row>
                                <Col md="12">
                                    <Card className="formStyle text-white">
                                        <CardHeader>
                                            <Col>
                                                <Row className="justify-content-between">
                                                    <CardTitle tag="h4">Music Tags</CardTitle>
                                                    <button className="buttonStyle buttonPrimary" onClick={handleCreateMusic}>Add Tag</button>
                                                </Row>
                                                <Row sm="1" md="2" lg="4"><Input className="inputStyle placeholderWhite" type="text" placeholder="Search" onChange={(e) => handleSearchBar(e.target.value)} /></Row>
                                            </Col>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="tableOverflow">
                                                <Table className="table">
                                                    <thead className="text-primary">
                                                        <tr style={{ color: "grey" }}>
                                                            <th>Name</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {!isLoading ?
                                                            musicTagData.length > 0 ?
                                                                searchResults.map((e) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td>{e.name}</td>
                                                                                <td className="text-right">
                                                                                    <Button
                                                                                        className="btn-icon btn-neutral dropdownIgnore editButton"
                                                                                        style={{ color: "" }}
                                                                                        id="tooltip159182735"
                                                                                        size="sm"
                                                                                        type="button"
                                                                                        onClick={() => handleEditMusic(e.id)}
                                                                                    >
                                                                                        <i className="fa fa-edit" style={{ fontSize: 18 }} />
                                                                                    </Button>
                                                                                    <UncontrolledTooltip
                                                                                        delay={0}
                                                                                        target="tooltip159182735"
                                                                                        style={{ backgroundColor: "#F33B30", color: "white" }}
                                                                                    >
                                                                                        Edit
                                                                                    </UncontrolledTooltip>
                                                                                    <Button
                                                                                        className="btn-icon btn-neutral dropdownIgnore"
                                                                                        style={{ color: "#f33b30" }}
                                                                                        id="tooltip808966390"
                                                                                        size="sm"
                                                                                        type="button"
                                                                                        onClick={() => handleDeleteMusicTagClicked(e.id)}
                                                                                    >
                                                                                        <i className="fa fa-times" style={{ fontSize: 18 }} />
                                                                                    </Button>
                                                                                    <UncontrolledTooltip
                                                                                        delay={0}
                                                                                        target="tooltip808966390"
                                                                                        style={{ backgroundColor: "#F33B30", color: "white" }}
                                                                                    >
                                                                                        Delete
                                                                                    </UncontrolledTooltip>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                })
                                                                : <tr>
                                                                    <td colSpan="8">
                                                                        <div style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}>
                                                                            <p>No Music Tags available</p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            : <tr>
                                                                <td colSpan="8">
                                                                    <div style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}>
                                                                        <Spinner color="danger" />
                                                                    </div>
                                                                </td>
                                                            </tr>}

                                                    </tbody>
                                                </Table>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </>
                ) : (
                    <div className="content">
                        <h2>You do not have permission to view this page</h2>
                    </div>
                )}</>
    );
}

export default MusicTag;