import React, { useState, useEffect } from "react"
import apiConfig from "../../config/apiConfig"
import apiCall from "../../utils/apiCall"
import { useNavigate } from "react-router-dom"


const AdWizard = (props) => {
    const [currentPage, setCurrentPage] = useState(0)
    const [isFirstPage, setIsFirstPage] = useState(true)
    const [isLastPage, setIsLastPage] = useState(false)
    const [isAdvertCreated, setIsAdvertCreated] = useState(false) // back to false
    const [isGenerating, setIsGenerating] = useState(false)
    const [isOwnAdvert, setIsOwnAdvert] = useState(false)
    const [isGenerate, setIsGenerate] = useState(true)


    const navigation = useNavigate()


    const handleNavigateWizard = async (isNext) => {
        setCurrentPage(prevPage => {
            const newPage = isNext ? prevPage + 1 : prevPage - 1;
            return Math.max(0, Math.min(newPage, props.wizardSteps.length - 1));
        });

    };

    useEffect(() => {
        setIsFirstPage(currentPage === 0);
        setIsLastPage(currentPage === props.wizardSteps.length - 1);
        props.setSelectedTitle(currentPage)
    }, [currentPage, props.wizardSteps.length]);

    const handleAddAdvert = async () => {
        props.setIsGeneratingError(false)
        const copyObject = { ...props.baseObject }
        copyObject.categories = null

        if (copyObject.id !== 0) {
            try {
                const resp = await apiCall(
                    apiConfig.ADVERT.DELETE + `?id=${copyObject.id}`,
                    "delete", copyObject
                )
                if (resp.status === 200) {
                    copyObject.id = 0
                }
            } catch (error) {

                console.error(error.message)
            }
        }

        if (copyObject.uploadedBase64) {
            copyObject.uploadedBase64 = null
        }

        const isValidAd = props.validateAdvertP1(copyObject)
        if (!isValidAd) {
            return
        }
        setIsGenerating(true)
        try {
            const resp = await apiCall(
                apiConfig.ADVERT.ADD,
                "post", copyObject
            )
            if (resp.status === 200) {
                const updatedContent = resp.data.content.map(item => ({
                    ...item,
                    translations: [
                        {
                            url: item.urls[0]?.url || '', // Use the first URL if it exists
                            language: "English",
                            languageCode: "en",
                            selected: true
                        }
                    ]
                }));

                props.setBaseObject({
                    ...props.baseObject,
                    id: resp.data.id,
                    content: updatedContent,
                    selectedContent: resp.data.content[0]?.id
                });

                props.getCredits();
                setIsGenerating(false);
                setIsAdvertCreated(true);
                return true;
            }
        } catch (error) {
            if (error.data === "Insufficient credits") {
                props.setIsMissingCredits(true)
                props.setModalPage("credits")
            } else {
                props.setModalPage("error")
            }
            setIsGenerating(false)
            console.error(error.message)
        }
    }

    const [requiredMissing, setRequiredMissing] = useState([])
    const [invalidDateRange, setInvalidDateRange] = useState(false)

    const handleUpdateAdvert = async (ignoreValidation = false, isJustDownload = false) => {
        const copyObject = { ...props.baseObject }
        setRequiredMissing([])

        if (!ignoreValidation) {
            let tempReq = []
            if (copyObject.name == "") {
                tempReq.push("name")
            }

            if (!copyObject.runningFrom) {
                tempReq.push("runningFrom")
            }

            if (!copyObject.runningTo) {
                tempReq.push("runningTo")
            }

            if (!isJustDownload)
                if (copyObject.budget <= 49) {
                    tempReq.push("budget")
                }

            // If required fields not entered
            if (tempReq.length > 0) {
                setRequiredMissing(tempReq)
                return false
            }

            //Check that dates are valid
            const st = new Date(copyObject.runningFrom)
            const end = new Date(copyObject.runningTo)

            if (st > end) {
                setInvalidDateRange(true)
                return false
            }



            // copyObject.geoLocations = copyObject.geoLocations.length > 0 ? copyObject.geoLocations : null
            // copyObject.age = copyObject.age.length > 0 ? copyObject.age : null
            // copyObject.interests = copyObject.interests.length > 0 ? copyObject.interests : null
            // copyObject.time = copyObject.time.length > 0 ? copyObject.time : null
            // copyObject.adPositions = copyObject.adPositions.length > 0 ? copyObject.adPositions : null

        }
        copyObject.categories = copyObject.categories && copyObject.categories.length > 0 ? copyObject.categories.map((cat) => cat.id).join(", ") : ignoreValidation ? null : "0"

        try {
            const resp = await apiCall(
                apiConfig.ADVERT.UPDATE + `?id=${copyObject.id}`,
                "put", copyObject
            )
            if (resp.status === 200) {
                setCurrentPage(currentPage + 1)
                return true
            }
            return false
        } catch (error) {
            console.error(error.message)
        }
    }


    const handleGenerateTranslations = async (languageCodes) => {
        const copyObject = { ...props.baseObject }
        const usedContent = copyObject.content.find((content) => content.id === copyObject.selectedContent)

        setIsGenerating(true)

        try {
            const resp = await apiCall(
                apiConfig.ADVERT.ADD_TRANSLATIONS + `?languageCodes=${languageCodes}`,
                "post", usedContent
            )
            if (resp.status === 200) {
                setIsGenerating(false)
                return resp.data
            }
        } catch (error) {
            console.error(error.message)
        }

        setIsGenerating(false)

    }

    const handleSelectedTranslations = async () => {
        const usedContent = props.baseObject.content.find((content) => content.id === props.baseObject.selectedContent)
        const translations = usedContent.translations.filter(item => item.selected)
        const languageCodes = translations && translations
            ? translations.map(item => item.languageCode).join(', ')
            : '';
        try {
            const resp = await apiCall(
                apiConfig.ADVERT.ADD_SELECTED_TRANSLATIONS + `?languageCodes=${languageCodes}`,
                "post", usedContent
            )
            if (resp.status === 200) {
            }
        } catch (error) {
            console.error(error.message)
        }
    }

    const [purchaseObject, setPurchaseObject] = useState(null)
    const [isPurchaseLoading, setIsPurchaseLoading] = useState(false)
    const [isPurchaseExist, setIsPurchaseExist] = useState(false)
    const [purchaseComplete, setPurchaseComplete] = useState(false)
    const [advertCost, setAdvertCost] = useState({
        isDownload: false,
        isNetwork: true,
    })

    const handleInitAdvertSpend = async () => {
        await handleSelectedTranslations();
        const downloadCost = 99
        const { isDownload, isNetwork } = advertCost

        let downloadAmount = 0
        let downloadQuantity = 0
        let budget = 0

        if (isDownload) {
            downloadQuantity = props.baseObject.content.find(content => content.id === props.baseObject.selectedContent)
                ?.translations?.filter(translation => translation.selected).length || 0;

            downloadAmount = downloadQuantity * downloadCost;
        }

        if (isNetwork) {
            budget = Number(props.baseObject.budget)
        }

        const tempPurchaseObject = {
            transactionType: 2,
            advertId: props.baseObject.apiCall,
            productId: null,
            description: "Advert Spend",
            downloadAmount: downloadAmount,
            downloadQuantity: downloadQuantity,
            budget: budget,
            amount: budget + downloadAmount
        }

        try {
            const resp = await apiCall(
                apiConfig.TRANSACTION.INIT_PURCHASE,
                "post", tempPurchaseObject
            );
            if (resp.status === 200) {
                setPurchaseObject({
                    ...resp.data,
                    downloadAmount: downloadAmount,
                    downloadQuantity: downloadQuantity,
                    budget: Number(props.baseObject.budget)
                })
                return true
            }
            return false

        } catch (error) {
            console.error(error.message);
        }
    }

    const handleNavigatePayment = async () => {
        setIsPurchaseLoading(true)
        const isOnlyDownload = advertCost.isDownload && !advertCost.isNetwork
        const isAdMade = await handleUpdateAdvert(false, isOnlyDownload)
        if (isAdMade) {
            const isPurchaseReady = await handleInitAdvertSpend()
            setIsPurchaseExist(isPurchaseReady)
            setIsPurchaseLoading(false)
        }
    }

    const handleFinishWizard = () => {
        navigation("/admin/ads")
    }

    useEffect(() => {
        const panel = document.querySelector('.main-panel');
        if (panel) {
            panel.scrollTop = 0;
        }
    }, [currentPage])

    const deleteOldPodcast = async (id) => {
        try {
            const resp = await apiCall(
                apiConfig.ADVERT.DELETE + `?id=${id}`,
                "delete"
            )
            if (resp.status === 200) {
                return true
            }
        } catch (error) {
            console.error(error.message)
        }
    }


    const handleUploadOwnAdvert = async () => {

        let resetId = false
        if (props.baseObject.id != 0) {
            await deleteOldPodcast(props.baseObject.id)
            resetId = true
        }

        const copyObject = { ...props.baseObject }
        copyObject.categories = null
        if (resetId) {
            copyObject.id = 0
        }
        copyObject.script = "User uploaded their own script"


        setIsGenerating(true)
        try {
            const resp = await apiCall(
                apiConfig.ADVERT.ADD,
                "post", copyObject
            )
            if (resp.status === 200) {
                const newAd = resp.data
                newAd.selectedContent = resp.data.content[0].id
                props.setBaseObject(newAd)
                props.getCredits()
                setIsGenerating(false)
                setCurrentPage(2)
                setIsAdvertCreated(true)
                return true
            }
        } catch (error) {
            if (error.data === "Insufficient credits") {
                props.setIsMissingCredits(true)
                props.setModalPage("credits")
            }
            setIsGenerating(false)
            console.error(error.message)
        }
    }

    const [isSkipForward, setIsSkipForward] = useState(false)
    useEffect(() => {
        if (isOwnAdvert) {
            if (currentPage == 1) {
                if (isSkipForward) {
                    setCurrentPage(2)
                } else {
                    setCurrentPage(0)
                }
                setIsSkipForward(!isSkipForward)
            }
        }
    }, [currentPage])

    const [script, setScript] = useState(props.baseObject.script || "");
    const [nonExactScript, setNonExactScript] = useState("")
    const [exactScript, setExactScript] = useState("")

    return (
        <div style={{ minHeight: "410px" }}>
            <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                {props.wizardSteps && props.wizardSteps.length > 0 &&
                    props.wizardSteps.map((step, index) => {
                        return (
                            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", width: index === 0 || index === 3 ? "20%" : "30%" }} key={index}>
                                {index > 0 && (
                                    <div style={{
                                        flexGrow: 1,
                                        height: "2px",
                                        backgroundColor: "rgba(100, 100, 100, 0.3",
                                        flexShrink: 0,  // Prevents shrinking
                                        marginRight: "10px",  // Adjust as needed
                                        marginTop: -15
                                    }} />
                                )}

                                {/* Step content */}
                                <div style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                    <div style={{
                                        backgroundColor: currentPage >= index ? "rgb(243, 59, 48)" : "#404040",
                                        padding: 5,
                                        borderRadius: 35,
                                        minHeight: 35,
                                        maxHeight: 35,
                                        minWidth: 35,
                                        maxWidth: 35,
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }} className="text-white">
                                        {index + 1}
                                    </div>
                                    <div className="">{step.title}</div>
                                </div>

                                {index < props.wizardSteps.length - 1 && (
                                    <div style={{
                                        flexGrow: 1,
                                        height: "2px",
                                        backgroundColor: "rgba(100, 100, 100, 0.3",
                                        flexShrink: 0,  // Prevents shrinking
                                        marginLeft: "10px",  // Adjust as needed
                                        marginTop: -15
                                    }} />
                                )}
                            </div>
                        );
                    })
                }
            </div>

            <div className="mt-2 mb-5">
                {props.wizardSteps && props.wizardSteps.length > 0 &&
                    React.cloneElement(props.wizardSteps[currentPage].component, { ...props.wizardSteps[currentPage], handleAddAdvert, handleGenerateTranslations, isGenerating, purchaseObject, isPurchaseLoading, isPurchaseExist, purchaseComplete, setPurchaseComplete, requiredMissing, invalidDateRange, advertCost, setAdvertCost, setIsOwnAdvert, isGenerate, setIsGenerate, loadingAudio: props.loadingAudio, isGeneratingError: props.isGeneratingError, setCurrentPage, script, setScript, nonExactScript, setNonExactScript, exactScript, setExactScript })
                }
            </div>
            <div style={{ position: "absolute", bottom: 0, width: "100%", display: "flex", justifyContent: "center", }}>
                <div style={{ display: "flex", width: "98%", justifyContent: isFirstPage ? "flex-end" : "flex-end", margin: 10, gap: 15, padding: 10 }}>
                    {!isFirstPage && !purchaseComplete && <button onClick={() => handleNavigateWizard(false)} type="submit" className="buttonStyle buttonPrimary">Back</button>}
                    {!isLastPage && isAdvertCreated && currentPage != 0 && currentPage != 2 && currentPage != 3 && <button onClick={() => handleNavigateWizard(true)} type="submit" className="buttonStyle buttonPrimary">Next</button>}
                    {isGenerate && isAdvertCreated && currentPage == 0 && <button onClick={() => handleUpdateAdvert(true)} type="submit" className="buttonStyle buttonPrimary">Next</button>}
                    {!isFirstPage && isAdvertCreated && currentPage == 2 && <button onClick={() => handleNavigatePayment()} type="submit" className="buttonStyle buttonPrimary">Next</button>}
                    {!isGenerate && isFirstPage && isOwnAdvert && <button className="buttonStyle buttonPrimary" onClick={handleUploadOwnAdvert}>Next</button>}
                    {!isFirstPage && isAdvertCreated && currentPage == 3 && purchaseComplete && <button onClick={() => handleFinishWizard()} type="submit" className="buttonStyle buttonPrimary">Go to Ads section</button>}
                </div>
            </div>
        </div>
    )
}

export default AdWizard