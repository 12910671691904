import React, { useState } from "react"
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js"

import "assets/css/custom-css.css"

const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
        base: {
            iconColor: "#F33B30",
            color: "white",
            fontWeight: 500,
            fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
            fontSize: "16px",
            fontSmoothing: "antialiased",
            ":-webkit-autofill": { color: "#F33B30" },
            "::placeholder": { color: "white" },
            
        },
        invalid: {
            iconColor: "#F33B30",
            color: "white"
        }
    }
}

function PaymentForm(props) {

    const [success, setSuccess] = useState(false)
    const stripe = useStripe()
    const elements = useElements()

    const handleSubmit = async (e) => {
        e.preventDefault()
        props.setAlert(null)
        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement)
        })
        if (!error) {
            props.setBaseObject({ ...props.baseObject, paymentTransactionId: paymentMethod.id })
        } else {
            props.setAlert("Card details invalid")
            console.error(error.message)
        }
    }

    return (
        <>
            <form onSubmit={handleSubmit} style={{width: "100%"} }>
                <fieldset className="FormGroup">
                    <div className="FormRow">
                        <CardElement options={CARD_OPTIONS} />
                    </div>
                </fieldset>
                <div className="d-flex justify-content-center">
                    <button type="submit" class="buttonStyle buttonPrimary">Pay</button>
                </div>
            </form>
        </>
    )
}

export default PaymentForm