import { CardBody, Row, Col, FormGroup, Input } from "reactstrap"
function ChangePassword(props) {


    if (props.newPass === props.confirmNew && props.oldPass.length > 0 && props.newPass.length > 0) {
        props.setAllowPassChange(true)
    } else {
        props.setAllowPassChange(false)
    }

    return (
        <>
            <div className="content">
                <CardBody>
                    <Row>
                        <Col>
                            <FormGroup>
                                <label>Old Password</label>
                                <Input
                                    placeholder="Old Password"
                                    type="password"
                                    value={props.oldPass}
                                    onChange={(e) => props.setOldPass(e.target.value)}
                                    className="inputStyle placeholderWhite"

                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <label>New Password</label>
                                <Input
                                    placeholder="New password"
                                    type="password"
                                    value={props.newPass}
                                    onChange={(e) => props.setNewPass(e.target.value)}
                                    className="inputStyle placeholderWhite"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <label>Confirm new password</label>
                                <Input
                                    placeholder="Confirm new password"
                                    type="password"
                                    value={props.confirmNew}
                                    onChange={(e) => props.setConfirmNew(e.target.value)}
                                    className="inputStyle placeholderWhite"
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                </CardBody>
            </div>

        </>
    )
}

export default ChangePassword