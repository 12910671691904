import React, { useState } from 'react'; // Don't forget to import useState
import { useFormik } from 'formik';
import apiConfig from "config/apiConfig";
import * as Yup from 'yup';
import "assets/css/custom-css.css"
import KlaxonLogoNew from "assets/img/klaxonImages/klaxonFull.png"
import axios from "axios"

import {
    Row
} from "reactstrap";

const validationSchema = Yup.object({
    message: Yup.string().min(20, 'Message must be 20 or more characters'),
});

function FeedbackForm(props) {
    const [messageSent, setMessageSent] = useState(false);
    const [sendError, setSendError] = useState(false);

    // Move the MyForm component outside of the Contact function
    const MyForm = () => {
        const formik = useFormik({
            initialValues: {
                message: '',
            },
            validationSchema: validationSchema,
            onSubmit: async values => { // Add async keyword to onSubmit function
                //Reset error on click to make it more obvious if it fails again
                setSendError(false)
                
                try {
                    const contactUs = {
                        name: props.name != undefined ? props.name : "Logged in user",
                        email: props.email,
                        message: `CUSTOMER FEEDBACK: ${values.message}`
                    }

                    let url = `${apiConfig.baseUrl}${apiConfig.HOME.CONTACT_US}`;
                    await axios
                        .post(url, contactUs)
                        .then((res) => {
                            if (res.status === 200) {
                                setMessageSent(true)
                                setSendError(false)
                            }

                        })

                } catch (err) {
                    console.error(err)
                    setSendError(true); // Handle fetch errors by setting error state
                    setMessageSent(false); // Reset the success state
                }
            },
        });

        return (
            <form onSubmit={formik.handleSubmit}>
                

                <div>
                    <label htmlFor="message" className="text-white">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.message}
                        placeholder="Leave feedback"
                        rows={12}
                        className="inputStyle placeholderWhite"
                        style={{borderRadius: 5} }
                    />
                    {formik.touched.message && formik.errors.message ? (
                        <div className="contact-error">{formik.errors.message}</div>
                    ) : null}
                </div>
                <center>
                    <button className="buttonStyle buttonPrimary mt-3" type="submit">Send</button>
                    <p className="formTerms mt-3">By submitting this form, you agree to our <a style={{
                        color: "white",
                        textDecoration: "underline white",
                        cursor: "pointer"
                    }} href="/privacy">Privacy Policy</a>.</p>
                </center>
            </form>
        );
    };

    return (
        <>
            {!messageSent &&
                <Row className="contact-form pt-4 text-white formStyle" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    {!messageSent && (
                        <>
                            <img className="mb-4 mt-2" src={KlaxonLogoNew} style={{ width: "40%" }} />
                            <h3 class="readForm">Let us know your thoughts</h3>
                            <p style={{fontSize: 16, width: "90%", textAlign: "center"} }>Got an idea on how we can improve Klaxon for you? Leave us your feedback in the box below</p>
                            <MyForm />
                        </>
                    )}
                    {sendError && (
                        <div className="d-flex justify-content-center align-items-center text-center">
                            <h5 style={{ width: "90%", margin: 5 }}>There was an error sending your message. If this persists, please send an email to  <a style={{ textDecoration: "underline", color: "white" }} href="mailto:hello@klaxon.ai">hello@klaxon.ai</a></h5>
                        </div>
                    )}
                </Row>
            }
            {messageSent && (
                <div className="contacted d-flex align-items-center text-white" style={{height: "70vh"} }>
                    <h3>Thank you for your feedback!</h3>
                </div>
            )}
        </>
    );
}

export default FeedbackForm;

