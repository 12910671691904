import React, { useEffect, useState } from "react";
import apiConfig from "../../config/apiConfig";
import apiCall from "../../utils/apiCall";
import getJWTToken from "../../config/jwtToken";
import PaymentStep1 from "../Stripe/PaymentSteps/PaymentStep1";
import PaymentStep2 from "../Stripe/PaymentSteps/PaymentStep2";
import PaymentStep3 from "../Stripe/PaymentSteps/PaymentStep3";
function PaymentContainer(props) {
    const [firstLoad, setFirstLoad] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [holdUser, setHoldUser] = useState([]);
    const [subscriptionTypes, setSubscriptionTypes] = useState([]);
    const [stepperStep, setStepperStep] = useState(1);
    const [baseObject, setBaseObject] = useState({
        userId: Number(getJWTToken().decoded.nameid),
        subscriptionId: 0,
        paymentTransactionId: null,
        amount: 0,
        stripeUserId: null
    });

    const getUserId = async () => {
        try {
            const resp = await apiCall(
                apiConfig.USER.GET_SINGLE + `?id=${baseObject.userId}`,
                "get"
            );
            if (resp.status === 200) {
                setHoldUser(resp.data)
                setBaseObject(prevBaseObject => ({ ...prevBaseObject, stripeUserId: resp.data.stripeUserId }))
            }

            
        } catch (error) {
            console.error(error.message);
        }
    }

    const getSubscriptionTypes = async () => {
        try {
            const resp = await apiCall(
                apiConfig.USER_SUBSCRIPTION.GET_SUBSCRIPTIONS,
                "get"
            );
            if (resp.status === 200) {
                setSubscriptionTypes(resp.data)
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const addSubscription = async () => {
        setIsLoading(true)
        try {
            const resp = await apiCall(
                apiConfig.USER_SUBSCRIPTION.UPDATE + `?userId=${baseObject.userId}&paymentMethodId=${baseObject.paymentTransactionId}&subscriptionId=${baseObject.subscriptionId}`,
                "put", baseObject
            );
            if (resp.status === 200) {
                setStepperStep(3)
                setIsLoading(false)
            }

        } catch (error) {
            console.error(error.message);
        } finally {
            props.completeSubscriptionUpdate();
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (firstLoad) {
            setFirstLoad(false)
        } 

        if (!firstLoad) {
            addSubscription()
        }
    }, [baseObject.paymentTransactionId])

    useEffect(() => {
        getUserId()
        getSubscriptionTypes()
    }, [])

    return (
        <>
            {stepperStep === 1 && <PaymentStep1 holdUser={holdUser} subscriptionTypes={subscriptionTypes} baseObject={baseObject} setBaseObject={setBaseObject} setStepperStep={setStepperStep} />}
            {stepperStep === 2 && <PaymentStep2 holdUser={holdUser} baseObject={baseObject} setBaseObject={setBaseObject} setStepperStep={setStepperStep} addSubscription={addSubscription} subscriptionTypes={subscriptionTypes} isLoading={isLoading} />}
            {stepperStep === 3 && <PaymentStep3 holdUser={holdUser} baseObject={baseObject} setBaseObject={setBaseObject} toggle={props.toggle} />}
        </>
    );
}

export default PaymentContainer;
