import React from 'react'
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import PaymentForm from '../Forms/PaymentForm'

const PUBLIC_KEY = "pk_test_51OUU6uHMA9RoLHrnh9h3uVa10s5CgYX3NbX23Vbh5eJOaQtu9NAdAjV4UuNVqJZTHn6Ot3ezzrRybEpiwXPTECTe00iDmcjyi0"

const stripeTestPromise = loadStripe(PUBLIC_KEY)
function StripeContainer(props) {
    return (
        <Elements stripe={stripeTestPromise}>
            <PaymentForm baseObject={props.baseObject} setBaseObject={props.setBaseObject} setAlert={props.setAlert} />
        </Elements>
    )
}

export default StripeContainer