import { useEffect, useState } from "react";
import VoiceCard from "../BusinessWizardSteps/VoiceCard";

const AdVoice = (props) => {

    const [selectedVoice, setSelectedVoice] = useState(null)
    useEffect(() => {
        const isValidVoice = props.voices.find(voice => voice?.voiceId == props.selectedModalObject.voiceId)?.voiceName

        if (isValidVoice) {
            setSelectedVoice(isValidVoice)
        } else {
            setSelectedVoice(null)
        }
    }, [props.selectedModalObject.voiceId])

    return (
        <div className="formStyle pt-2">
            <p className="pl-4">Choose from our library of voices.</p>
            <div style={{ display: "flex", flexWrap: "wrap", gap: 10, justifyContent: "center" } }>
                {props.voices.map((e, index) => {
                    return (
                        <div style={{width: "47%", backgroundColor: ""} }>
                            <VoiceCard voiceData={e} index={index} colorSchemeTheme={"dark"} selectedVoice={selectedVoice && selectedVoice} isAdVoice={true} setSelectedModalObject={props.setSelectedModalObject} selectedModalObject={props.selectedModalObject} showId={false} handlePreviewVoice={props.handlePreviewVoice} currentPlayingVoice={props.currentPlayingVoice} loadingAudio={props.loadingAudio} />
                        </div>
                    )
                }) }
            </div>
        </div>
    )
}

export default AdVoice