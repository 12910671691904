import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const ModalNoFoot = ({ isOpen, toggle, children, title, buttonAction, width }) => {
    return (
        <Modal isOpen={isOpen} toggle={toggle} style={{ width: width, maxWidth: width, paddingTop: 0 }} className="paymentModal">
            <ModalHeader style={{borderBottom: "none"}}>
                <div className="d-flex justify-content-between align-items-center text-white">
                    <div className="ml-auto flex-grow-1">
                        {title}
                    </div>
                    <p onClick={toggle} style={{ cursor: "pointer" }}><i className="nc-icon nc-simple-remove" /></p>
                </div>

            </ModalHeader>
            <ModalBody>
                {/* Content of your modal */}
                {children}

            </ModalBody>
        </Modal>
    );
};

export default ModalNoFoot;