//Klaxon
import Podcasts from "./views/klaxonai/Podcasts";
import ProfilePage from "./views/klaxonai/UserProfile"
import Library from "./views/klaxonai/Library";
import RegisterPage from "./views/klaxonai/Register"
import Feedback from "./views/klaxonai/Feedback"
import PodcastBlocking from "./views/klaxonai/PodcastBlocking"
//Klaxon site nav
import LoginPage from "./views/klaxonai/Login"
import AboutUs from "./views/klaxonai/AboutUs";
import Pricing from "./views/klaxonai/Pricing";
import Advertise from "./views/klaxonai/Advertise";
import ContactUs from "./views/klaxonai/ContactUs";
import ForgotPassword from "./views/klaxonai/ForgotPassword";
import Privacy from "./views/klaxonai/Privacy";
import ReviewPage from "./views/klaxonai/ReviewPage";
import Dashboard from "./views/klaxonai/Dashboard"
import CreatePodcast from "./views/klaxonai/CreatePodcast";
import Ads from "./views/klaxonai/Ads";
import TermsAndConditions from "./views/klaxonai/TermsAndConditions";
import ComingSoon from "./views/klaxonai/ComingSoon";
import Verification from "./views/klaxonai/Verification"
import ResetPassword from "./views/klaxonai/ResetPassword";
import HowItWorks from "views/klaxonai/HowItWorks";
import FAQ from "./views/klaxonai/FAQ";

import ManageBusinesses from "./views/klaxonai/ManageBusinesses";

import CookiePolicy from "./views/klaxonai/CookiePolicy";
import ModernSlaveryStatement from "./views/klaxonai/ModernSlaveryStatement";
import AcceptableUsePolicy from "./views/klaxonai/AcceptableUsePolicy";
import TermsOfWebsiteUse from "./views/klaxonai/TermsOfWebsiteUse";

//Lightmode test
import PodcastPlayerLight from "./views/klaxonai/PodcastPlayerLight";
import ClientLibrary from "./views/klaxonai/ClientLibrary";


import Voices from "./views/klaxonai/Voices"

// New test stuff
import AdsWithWizard from "./views/klaxonai/AdsWithWizard";
import Music from "./views/klaxonai/Music";
import MusicTags from "./views/klaxonai/MusicTag";
import Home from "./views/klaxonai/Home";
import KlaxonWaitList from "./views/klaxonai/KlaxonWaitList";

const routes = [
    {
        path: "/home",
        name: "Home",
        icon: "nc-icon nc-bank",
        component: <Home />,
        layout: "/light",
    },
    {
        path: "/waitlist",
        name: "Wait List",
        icon: "nc-icon nc-bank",
        component: <KlaxonWaitList />,
        layout: "/light",
    },
    {
        path: "/player",
        name: "player",
        icon: "nc-icon nc-bank",
        component: <PodcastPlayerLight />,
        layout: "/light",
    },
    {
        path: "/company/:clientSlug",
        name: "Podcasts",
        icon: "nc-icon nc-bank",
        component: <ClientLibrary />,
        layout: "/light",
    },
    {
        path: "/dashboard",
        name: "Dashboard",
        icon: "nc-icon nc-layout-11",
        component: <Dashboard />,
        layout: "/admin",
    },
    {
        path: "/music-library",
        name: "Music",
        icon: "nc-icon nc-layout-11",
        component: <Music />,
        layout: "/admin",
    },
    {
        path: "/music-tag",
        name: "Music Tags",
        icon: "nc-icon nc-layout-11",
        component: <MusicTags />,
        layout: "/admin",
    },
    {
        path: "/voices",
        name: "Voices",
        icon: "nc-icon nc-layout-11",
        component: <Voices />,
        layout: "/light",
    },
    {
        path: "/manage-businesses",
        name: "Manage Businesses",
        icon: "nc-icon nc-layout-11",
        component: <ManageBusinesses />,
        layout: "/admin",
    },
    {
        path: "/ads",
        name: "Ads",
        icon: "nc-icon nc-bank",
        component: <Ads />,
        layout: "/admin",
    },
     {
         path: "/create-ad",
         name: "Advert Wizard",
         icon: "nc-icon nc-bank",
         component: <AdsWithWizard />,
         layout: "/admin",
     },
    {
        path: "/create-podcast",
        name: "Create Podcast",
        icon: "nc-icon nc-bank",
        component: <CreatePodcast />,
        layout: "/admin",
    },
    {
        path: "/podcast-blocking",
        name: "Podcast Blocking",
        icon: "nc-icon nc-lock-circle-open",
        component: <PodcastBlocking />,
        layout: "/admin",
    },
    {
        path: "/feedback",
        name: "Feedback",
        icon: "nc-icon nc-bank",
        component: <Feedback />,
        layout: "/admin",
    },
    {
        path: "/privacy",
        name: "Privacy",
        icon: "nc-icon nc-bank",
        component: <Privacy />,
        layout: "/light",
    },
    {
        path: "/terms-and-conditions",
        name: "Terms and Conditions",
        icon: "nc-icon nc-bank",
        component: <TermsAndConditions />,
        layout: "/light",
    },
    {
        path: "/cookie-policy",
        name: "Cookie Policy",
        icon: "nc-icon nc-bank",
        component: <CookiePolicy />,
        layout: "/",
    },
    {
        path: "/modern-slavery-statement",
        name: "Modern Slavery Statement",
        icon: "nc-icon nc-bank",
        component: <ModernSlaveryStatement />,
        layout: "/",
    },
    {
        path: "/acceptable-use-policy",
        name: "Acceptable Use Policy",
        icon: "nc-icon nc-bank",
        component: <AcceptableUsePolicy />,
        layout: "/",
    },
    {
        path: "/terms-of-website-use",
        name: "Terms of Website Use",
        icon: "nc-icon nc-bank",
        component: <TermsOfWebsiteUse />,
        layout: "/",
    },
    {
        path: "/library",
        name: "Library",
        icon: "nc-icon nc-bank",
        component: <Library />,
        layout: "/",
    },
    {
        path: "/how-it-works",
        name: "How It Works",
        icon: "nc-icon nc-bank",
        component: <HowItWorks />,
        layout: "/",
    },
    {
        path: "/about-us",
        name: "About Us",
        icon: "nc-icon nc-bank",
        component: <AboutUs />,
        layout: "/",
    },
    {
        path: "/faq",
        name: "FAQ",
        icon: "nc-icon nc-bank",
        component: <FAQ />,
        layout: "/",
    },
    {
        path: "/pricing",
        name: "Pricing",
        icon: "nc-icon nc-bank",
        component: <Pricing />,
        layout: "/",
    },
    {
        path: "/advertise",
        name: "Advertise",
        icon: "nc-icon nc-bank",
        component: <Advertise />,
        layout: "/",
    },
    {
        path: "/contact-us",
        name: "Contact Us",
        icon: "nc-icon nc-bank",
        component: <ContactUs />,
        layout: "/",
    },
    {
        path: "/podcasts",
        name: "Podcasts",
        icon: "nc-icon nc-headphones",
        component: <Podcasts />,
        layout: "/admin",
    },
    {
        path: "/review",
        name: "Review",
        icon: "nc-icon nc-bank",
        component: <ReviewPage />,
        layout: "/admin",
    },
    {
        path: "/profile",
        name: "Profile",
        icon: "nc-icon nc-bank",
        component: <ProfilePage />,
        layout: "/admin",
    },
    {
        path: "/login",
        name: "Login",
        mini: "L",
        component: <LoginPage />,
        layout: "/auth",
    },
    {
        path: "/verification",
        name: "Verification",
        mini: "L",
        component: <Verification />,
        layout: "/auth",
    },
    {
        path: "/forgot-password",
        name: "Forgot Password",
        mini: "L",
        component: <ForgotPassword />,
        layout: "/auth",
    },
    {
        path: "/reset-password",
        name: "Reset Password",
        mini: "L",
        component: <ResetPassword />,
        layout: "/auth",
    },
    {
        path: "/register",
        name: "Register",
        mini: "R",
        component: <RegisterPage />,
        layout: "/auth",
    },
    {
        path: "/subscribers",
        name: "Subscribers",
        icon: "nc-icon nc-bell-55",
        component: <ComingSoon />,
        layout: "/admin",
    },
    {
        path: "/newsletters",
        name: "Newsletters",
        icon: "nc-icon nc-email-85",
        component: <ComingSoon />,
        layout: "/admin",
    },
];

export default routes;
