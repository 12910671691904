import React, { useState, useEffect } from "react";
// react plugin used to create charts
import { Line } from "react-chartjs-2";
// react plugin for creating vector maps

import { useNavigate } from "react-router-dom"
import getJWTToken from "config/jwtToken";
import TutorialModal from "../../components/Shared/TutorialModal";
import tutorialText from "config/tutorialText";


// reactstrap components
import {
    Card,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col
} from "reactstrap";

import apiCall from "../../utils/apiCall";
import apiConfig from "../../config/apiConfig";

function Dashboard() {
    const [showTutorial, setShowTutorial] = useState(true);
    const [dashboardData, setDashboardData] = useState([])
    const [userSubTier, setUserSubTier] = useState([])
    const [graphData, setGraphData] = useState(null)
    const [graphStartDate, setGraphStartDate] = useState("")
    const [graphEndDate, setGraphEndDate] = useState("")
    const [listensLastMonth, setListensLastMonth] = useState([])


    const navigation = useNavigate()

    const getUser = async () => {
        try {
            const resp = await apiCall(
                apiConfig.USER.GET_SINGLE + `?id=${getJWTToken().decoded.nameid}`,
                "get"
            );
            //set as podcast data to maintain original
            setUserSubTier(resp.data.subscriptionTierId)

        } catch (error) {
            console.error(error.message);
        }
    };

    const getDashboardData = async () => {

        let isClientAccount
        const accData = localStorage.getItem("accountType")
        if (!accData || accData == 0) {
            isClientAccount = false
        } else {
            isClientAccount = true
        }

        let clientId
        if (isClientAccount) {
            const clientData = localStorage.getItem("cId")
            clientId = clientData
        }

        if (!clientId) {
            try {
                const resp = await apiCall(
                    apiConfig.DASHBOARD.GET_DASHBOARD_DATA,
                    "get"
                );
                setDashboardData(resp.data)
                populateGraphData(resp.data.annualListensBreakdown)

            } catch (error) {
                console.error(error.message);
            }
        } else {
            try {
                const resp = await apiCall(
                    apiConfig.DASHBOARD.GET_DASHBOARD_DATA_BUSINESS + `?clientId=${clientId}`,
                    "get"
                );
                setDashboardData(resp.data)
                populateGraphData(resp.data.annualListensBreakdown)

            } catch (error) {
                console.error(error.message);
            }
        }

    }



    const populateGraphData = (graphDataObject) => {

        const graphDataArray = Object.entries(graphDataObject).map(([month, value]) => {
            const [monthName, year] = month.split(" - ");
            return { month: monthName, year, value };
        });
        const graphLabels = graphDataArray.map((e) => {
            return e.month
        })

        const graphValues = graphDataArray.map((e) => {
            return e.value
        })

        setGraphStartDate(graphDataArray[graphDataArray.length - 1].year)
        setGraphEndDate(graphDataArray[0].year)
        setListensLastMonth(graphValues[1])




        const chartData = {
            data: {
                labels: graphLabels.reverse(),
                datasets: [
                    {
                        label: "Listeners",
                        borderColor: "#F33B30",
                        pointRadius: 0,
                        pointHoverRadius: 0,
                        fill: false,
                        borderWidth: 3,
                        barPercentage: 1.6,
                        tension: 0.4,
                        data: graphValues.reverse()
                    },
                ],
            },
            options: {
                plugins: {
                    legend: {
                        display: false,
                    },

                    tooltips: {
                        enabled: false,
                    },
                },

                scales: {
                    y: {
                        ticks: {
                            color: "#9f9f9f",
                            beginAtZero: false,
                            maxTicksLimit: 5,
                        },
                        grid: {
                            drawBorder: false,
                            display: false,
                        },
                    },
                    x: {
                        grid: {
                            drawBorder: false,
                            display: false,
                        },
                        ticks: {
                            padding: 20,
                            color: "#9f9f9f",
                        },
                    },
                },
            },
        };

        setGraphData(chartData)

    }

    const handleSubscribeNavigation = () => {
        navigation("/pricing")
    }

    useEffect(() => {
        getDashboardData()
        getUser()
    }, [])

    useEffect(() => {
        const handleClientChanged = () => getDashboardData();

        // Add the event listener when the component mounts
        window.addEventListener("clientChanged", handleClientChanged);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener("clientChanged", handleClientChanged);
        };

    }, [])


    return (
        <>
            <TutorialModal
                showTutorial={showTutorial}
                pageName='dashboard'
                title="Dashboard"
                text={tutorialText.DASHBOARD}
                setShowTutorial={setShowTutorial} />

            <div className="content">
                <Row>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats formStyle">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-headphones text-danger" />
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Total Listeners</p>
                                            <CardTitle tag="p" className="text-white">{dashboardData.totalListeners}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    <p style={{ margin: 0 }}>{listensLastMonth} last month</p>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                        <Card className="formStyle card-stats">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-diamond text-success" />
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        <div className="numbers">
                                            <p className="card-category">Subscriptions</p>
                                            <CardTitle tag="p" className="text-white">{"Coming soon"}</CardTitle>
                                            <p />
                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                <div className="stats">
                                    0 new subscribers
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats formStyle">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-chart-pie-36 text-danger" />
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        {userSubTier === 1 ?
                                            <div className="numbers">
                                                <p className="card-category">Monthly Ad Revenue</p>
                                                <CardTitle tag="p" className="text-white">&pound;0</CardTitle>
                                                <p />
                                            </div>
                                            :
                                            <div className="numbers">
                                                <p className="card-category">Monthly Ad Revenue</p>
                                                <CardTitle tag="p" className="text-white">&pound;{dashboardData.monthlyAdRevenue}</CardTitle>
                                                <p />
                                            </div>

                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                {userSubTier === 1 ?
                                    <div className="stats">
                                        Compared to last month
                                    </div>
                                    :
                                    <>
                                        <div className="stats">
                                            <p style={{ margin: 0 }}>&pound;{dashboardData.monthlyAdRevenueLastMonth} last month</p>
                                        </div>
                                    </>
                                }
                            </CardFooter>
                        </Card>
                    </Col>

                    <Col lg="3" md="6" sm="6">
                        <Card className="card-stats formStyle">
                            <CardBody>
                                <Row>
                                    <Col md="4" xs="5">
                                        <div className="icon-big text-center icon-warning">
                                            <i className="nc-icon nc-money-coins text-success" />
                                        </div>
                                    </Col>
                                    <Col md="8" xs="7">
                                        {userSubTier === 1 ?
                                            <div className="numbers">
                                                <p className="card-category">Total Ad Revenue</p>
                                                <CardTitle tag="p" className="text-white">&pound;0</CardTitle>
                                                <p />
                                            </div>
                                            :
                                            <div className="numbers">
                                                <p className="card-category">Total Ad Revenue</p>
                                                <CardTitle tag="p" className="text-white">&pound;{dashboardData.totalAdRevenue}</CardTitle>
                                                <p />
                                            </div>
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                            <CardFooter>
                                <hr />
                                {userSubTier === 1 ?
                                    <div className="stats">
                                        Compared to last month
                                    </div>
                                    :
                                    <div className="stats">
                                        <p style={{ margin: 0 }}>&pound;{dashboardData.totalAdRevenueLastMonth} last month</p>
                                    </div>
                                }
                            </CardFooter>
                        </Card>
                    </Col>
                </Row>


                <Row className="justify-content-center">

                    <Col lg="12" sm="12">
                        <Card className="formStyle">

                            <CardBody>
                                <p className="" style={{ fontSize: 16 }}>Monthly Listens</p>
                                {graphData !== null &&
                                    <>
                                        <Line
                                            data={graphData.data}
                                            options={graphData.options}
                                            height={280}
                                            width={826}
                                        />
                                        <Row className="justify-content-between pl-4 pr-4"><p>{graphStartDate}</p><p>{graphEndDate}</p></Row>
                                    </>
                                }
                            </CardBody>

                        </Card>
                    </Col>
                </Row>




            </div>
        </>
    );
}

export default Dashboard;
