import React, { useEffect, useState } from "react";

import apiConfig from "config/apiConfig";
import axios from "axios";
import { useNavigate, useLocation } from "react-router-dom";

import defaultImage from "../../assets/img/header.jpg"
import "../../assets/css/custom-css.css"

// reactstrap components
import {
    CardBody,
    CardTitle,
    Row,
    Col,
    Input,
    InputGroup
} from "reactstrap";
function Library() {
    const location = useLocation()
    const getTopic = new URLSearchParams(location.search).get('topic')
    const [podcastData, setPodcastData] = useState([])
    const [trendingData, setTrendingData] = useState([])
    const [suggestedData, setSuggestedData] = useState([])
    const [topicData, setTopicData] = useState([])
    const [searchResults, setSearchResults] = useState([])
    const [searchBar, setSearchBar] = useState("")

    const [tagOption, setTagOption] = useState(getTopic ? getTopic : "all")

    const navigation = useNavigate()

    const getPodcasts = async () => {
        let url = `${apiConfig.baseUrl}${apiConfig.HOME.GET_BY_TOPIC}`;
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    setSearchResults(res.data)
                    setPodcastData(res.data)
                }

            })
            .catch((err) => {
                //setError(err.response.data);
            });
    }


    const getTrending = async () => {
        let url = `${apiConfig.baseUrl}${apiConfig.HOME.GET_TRENDING}`;
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    setTrendingData(res.data)
                }

            })
            .catch((err) => {
                //setError(err.response.data);
            });
    }


    const getSuggested = async () => {
        let url = `${apiConfig.baseUrl}${apiConfig.HOME.GET_SUGGESTED}`;
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    setSuggestedData(res.data)
                }

            })
            .catch((err) => {
                //setError(err.response.data);
            });
    }

    const getTopics = async () => {
        let url = `${apiConfig.baseUrl}${apiConfig.HOME.GET_SUGGESTED_TOPICS}`;
        await axios
            .get(url)
            .then((res) => {
                if (res.status === 200) {
                    setTopicData(res.data)
                }

            })
            .catch((err) => {
                //setError(err.response.data);
            });
    }

    const handleSearchBar = (query) => {
        setSearchBar(query)
        const originalSearch = podcastData
        const searchName = originalSearch.filter((e) => e.name.toLowerCase().includes(query.toLowerCase()))

        setSearchResults(searchName)

    }

    const handleSelectPodcast = (podcastId) => {
        navigation(`/player?podcastId=${podcastId}`)
    }


    useEffect(() => {
        getPodcasts();
        getTrending();
        getSuggested();
        getTopics();
    }, []);

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])


    return (
        <>
            {/* <TutorialModal
                showTutorial={showTutorial}
                pageName='home'
                title="Home"
                text={tutorialText.HOME}
                setShowTutorial={setShowTutorial} /> */}
            <div className="content sitePadding" style={{ marginTop: 78, paddingLeft: "5%", paddingRight: "5%" }}>

                <Row>
                    <Col md="12">
                        <CardBody>
                            <Row>
                                <Col className="d-flex align-items-center" style={{ paddingTop: 10 }} sm="12" md="12" lg="12" xl="6">
                                    <InputGroup style={{ paddingLeft: 15 }}>
                                        <Input className="inputStyle" type="text" placeholder="Search" value={searchBar} onChange={(e) => handleSearchBar(e.target.value)} />

                                    </InputGroup>
                                </Col>
                                <Col className="align-items-center d-flex">
                                    <InputGroup style={{ marginBottom: 0 }} className="d-flex align-items-center justify-content-center">
                                        <Row className="doubleDotted" style={{ paddingRight: 10 }}>
                                            <Col sm="12" md="12" lg="6" xl="2" className="text-center topicDiv" onClick={() => setTagOption("all")}><p className={`topicItem ${tagOption === "all" ? "topicItemActive" : ""}`}>All</p></Col>
                                            {topicData?.length > 0 && (
                                                topicData.slice(0, 4).map((topic) => {
                                                    return (
                                                        <Col sm="12" md="12" lg="6" xl="2" onClick={() => setTagOption(topic)} className="topicDiv" >
                                                            <p className={`text-center topicItem ${tagOption === topic ? "topicItemActive" : ""}`}>{topic}</p>
                                                        </Col>
                                                    )
                                                })
                                            )}

                                        </Row>
                                    </InputGroup>
                                </Col>
                            </Row>
                            <Col>
                                <CardTitle className="text-center pb-2 text-white mt-4" tag="h5">Suggested for you</CardTitle>
                                <Row className="justify-content-around">
                                    {suggestedData?.length > 0 ? (
                                        suggestedData.map((podcast) => {
                                            return (
                                                <Col className="podcastThumbnail mb-4" onClick={() => handleSelectPodcast(podcast.id)} key={podcast.id}>
                                                    <img src={podcast.image ? podcast.image : defaultImage} />
                                                    <p>{podcast.name}</p>
                                                </Col>
                                            )
                                        })
                                    ) : (
                                        <Col>No suggestions</Col>
                                    )}
                                </Row>
                            </Col>

                        </CardBody>



                        <CardBody>

                            <Col>
                                <CardTitle className="text-center pb-2 dottedBorderListen" tag="h5">Trending</CardTitle>
                                <Row className="justify-content-around">
                                    {trendingData?.length > 0 ? (
                                        trendingData.map((podcast) => {
                                            return (
                                                <Col onClick={() => handleSelectPodcast(podcast.id)} className="podcastThumbnail mb-4" key={podcast.id}><img src={podcast.image ? podcast.image : defaultImage} />
                                                    <p>{podcast.name}</p>
                                                </Col>
                                            )
                                        })
                                    ) : (
                                        <Col>No suggestions</Col>
                                    )}
                                </Row>
                            </Col>

                        </CardBody>



                        <CardBody>

                            <Col>
                                <CardTitle className="text-center pb-2 dottedBorderListen" tag="h5">All Podcasts</CardTitle>


                                <Row className="paddingBox">
                                    {podcastData?.length > 0 ? (

                                        searchResults.map((podcast, index) => (
                                            <React.Fragment key={podcast.id}>
                                                <Col className="podcastThumbnail mb-4" onClick={() => handleSelectPodcast(podcast.id)}>
                                                    <img src={podcast.image ? podcast.image : defaultImage} />
                                                    <p>{podcast.name}</p>
                                                </Col>
                                            </React.Fragment>
                                        ))

                                    ) : (
                                        <Col>No suggestions</Col>
                                    )}
                                </Row>
                            </Col>

                        </CardBody>

                    </Col>
                </Row>


            </div>
        </>
    );
}

export default Library;
