import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Row } from 'reactstrap';


const TutorialModal = (props) => {
  // State to track whether the modal should be shown or not

  // Function to handle the "OK" button click
  const handleOkClick = () => {
      props.setShowTutorial(false);
  };

  const handleHideClick = () => {
    props.setShowTutorial(false);
    localStorage.setItem(props.pageName, 'true');
      if (props.handleScrollToTop) props.handleScrollToTop();
  };

  // Use useEffect to run the code only on the first render
  useEffect(() => {
    // You can use localStorage or sessionStorage to persist the state
    const hasVisitedBefore = localStorage.getItem(props.pageName);

    // If the user has visited before, do not show the modal
    if (hasVisitedBefore) {
        props.setShowTutorial(false);
    } 
  }, []); // Empty dependency array ensures that this effect runs only once on mount

  // Render the modal
  return (
    <Modal isOpen={props.showTutorial} backdrop="static" keyboard={false} size="lg">
      <Col className='pt-4'>
              <h3 style={{ textAlign: "center" }} className="tutModalText">{props.title}</h3>
              <Col style={{ width: "90%", margin: "auto" }} className="tutModalText">
                  {props.text.length > 1 ?
                      props.text.map((e) => {
                          return (
                              <p style={{marginBottom: 10}}>{e}</p>
                          )
                      })
                      : <p>{props.text}</p>}
              </Col>
              <Col className="pb-4">
                  <Row className="justify-content-center pb-2"><button onClick={() => handleOkClick()} type="submit" className="buttonStyle buttonPrimary">I understand</button></Row>
                  <Row className="justify-content-center tutModalText"><u onClick={() => handleHideClick()} style={{textDecoration:"underline", color: "", cursor: "pointer"} }><strong>Don't show again</strong></u></Row>
              </Col>
      </Col>
    </Modal>
  );
};

export default TutorialModal;
