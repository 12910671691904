import React, { useState, useEffect } from 'react';
import {Col, Row } from 'reactstrap';


const AdTutorialModal = (props) => {

    const navigatePage = (isForward) => {
        props.setTutorialPage((prevPage) => {
            if (isForward) {
                return prevPage < 4 ? prevPage + 1 : prevPage;
            } else {
                return prevPage > 0 ? prevPage - 1 : prevPage;
            }
        });
    };

    useEffect(() => {
        const panel = document.querySelector('.main-panel');
        if (panel) {
            panel.scrollTop = 0;
        }
    }, [props.tutorialPage])

    return (
        <Col>
            <Row>
                {props.selectedTutorialPage}  
            </Row>

            <Row style={{display: "flex", justifyContent: "center"} }>
                <div>
                    <Row>
                        <Col>
                            {props.tutorialPage !== 0 &&
                                <button onClick={() => navigatePage(false)} type="submit" className="buttonStyle buttonPrimary">Previous</button>
                            }
                        </Col>
                        <Col>
                            {props.tutorialPage !== 4 &&
                                <button onClick={() => navigatePage(true)} type="submit" className="buttonStyle buttonPrimary">Next</button>

                            }
                        </Col>
                    </Row>
                </div>
                <div style={{backgroundColor: "", position: "absolute", right: 25} }>
                    <button onClick={() => props.setIsShowTutorial(false)} type="submit" className="buttonStyle buttonPrimary">Close</button>
                </div>
            </Row>
        </Col>
    );
};

export default AdTutorialModal;
