/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Routes, useLocation } from "react-router-dom";

import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footer/AdminFooter";
import Sidebar from "components/Sidebar/Sidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";
import { useState } from "react";
import { useEffect } from "react";

var ps;

function Admin(props) {
    const location = useLocation();

    const [backgroundColor, setBackgroundColor] = React.useState("red");
    const [activeColor, setActiveColor] = React.useState("primary");
    const [sidebarMini, setSidebarMini] = React.useState(false);

    const mainPanel = React.useRef();

    React.useEffect(() => {
        if (navigator.platform.indexOf("Win") > -1) {
            document.documentElement.className += " perfect-scrollbar-on";
            document.documentElement.classList.remove("perfect-scrollbar-off");
            ps = new PerfectScrollbar(mainPanel.current);
        }
        return function cleanup() {
            if (navigator.platform.indexOf("Win") > -1) {
                ps.destroy();
                document.documentElement.className += " perfect-scrollbar-off";
                document.documentElement.classList.remove("perfect-scrollbar-on");
            }
        };
    });

    React.useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        mainPanel.current.scrollTop = 0;
    }, [location]);

    const scrollToTop = () => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        if (mainPanel.current) {
            mainPanel.current.scrollTop = 0;
        }
    }

    window.addEventListener("userClickedNext", scrollToTop)

    const getRoutes = (routes) => {
        return routes.map((prop, key) => {
            if (prop.collapse) {
                return getRoutes(prop.views);
            }
            if (prop.layout === "/admin") {
                return (
                    <Route path={prop.path} element={prop.component} key={key} exact />
                );
            } else {
                return null;
            }
        });
    };

    const handleMiniClick = () => {
        if (document.body.classList.contains("sidebar-mini")) {
            setSidebarMini(false);
        } else {
            setSidebarMini(true);
        }
        document.body.classList.toggle("sidebar-mini");
    };

    const [isLightMode, setIsLightMode] = useState(false)
    const toggleLightMode = () => {
        if (isLightMode) {
            document.body.classList.remove('light-mode');
            localStorage.setItem("light-mode", "darkmode")
        } else {
            document.body.classList.add('light-mode');
            localStorage.setItem("light-mode", "lightmode")
        }
        setIsLightMode(!isLightMode)
    }


    useEffect(() => {
        const userLightMode = localStorage.getItem("light-mode")

        if (userLightMode == "lightmode") {
            setIsLightMode(true)
            document.body.classList.add('light-mode');
        } else {
            setIsLightMode(false)
            document.body.classList.remove('light-mode');
        }
        const event = new CustomEvent("lightmodechanged", {
            detail: { mode: "lightmode" } 
        });
        window.dispatchEvent(event)
    }, [])


    return (
        <div className="wrapper">
            <Sidebar
                {...props}
                routes={routes}
                bgColor={backgroundColor}
                activeColor={activeColor}
                isLightMode={isLightMode}
            />
            <div className="main-panel backgroundMain2" ref={mainPanel}>
                <AdminNavbar {...location} handleMiniClick={handleMiniClick} toggleLightMode={toggleLightMode} isLightMode={isLightMode} />
                <Routes>{getRoutes(routes)}</Routes>
                {
                    // we don't want the Footer to be rendered on full screen maps page
                    location.pathname.indexOf("full-screen-map") !== -1 ? null : (
                        <AdminFooter fluid />
                    )
                }
            </div>
            {/* <FixedPlugin
        bgColor={backgroundColor}
        activeColor={activeColor}
        sidebarMini={sidebarMini}
        handleActiveClick={handleActiveClick}
        handleBgClick={handleBgClick}
        handleMiniClick={handleMiniClick}
      /> */}
        </div>
    );
}

export default Admin;
