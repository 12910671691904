import { Col, Row, Label, Spinner } from "reactstrap"
import { useState, useEffect } from "react"
import apiCall from "../../../utils/apiCall"
import apiConfig from "../../../config/apiConfig"
import StripeGenericContainer from "../../Stripe/stripeGeneric/StripeGenericContainer";


const WizardAdPayment = (props) => {
    const [downloadLink, setDownloadLink] = useState(null)
    const [fileName, setFileName] = useState('')

    useEffect(() => {
        if (props.baseObject.selectedContent !== 0 && props.baseObject.content && props.baseObject.content.length > 0) {
            const selectedContent = props.baseObject.content.find((con) => con.id === props.baseObject.selectedContent)
            if (selectedContent) {
                const downloadUrl = selectedContent.urls[0].url
                const title = props.baseObject.name || 'Your Podcast'
                setFileName(title.replace(/\s+/g, '-').toLowerCase() + '.mp3')
                setDownloadLink(downloadUrl)
            }
        }
    }, [props.baseObject]);


    const handleDownloadTranslation = async (linkUrl, languageCode) => {
        const translationTitle = `${fileName}`.replace('.mp3', `-${languageCode}.mp3`)

        try {
            const response = await fetch(linkUrl)
            const blob = await response.blob()

            const url = URL.createObjectURL(blob)

            const link = document.createElement('a')
            link.href = url
            link.download = translationTitle
            document.body.appendChild(link)
            link.click()

            document.body.removeChild(link)
            URL.revokeObjectURL(url)
        } catch (error) {
            console.error('Download failed:', error)
        }
    }

    const handlePurchase = async () => {
        try {
            // Confirm purchase
            const resp = await apiCall(
                apiConfig.TRANSACTION.CONFIRM_PURCHASE + `?id=${props.purchaseObject.id}&advertId=${props.baseObject.id}`,
                "put"
            );
            if (resp.status === 200) {
                props.setPurchaseComplete(true)
            }

            // Update download on ad content so we can download later
            resp = await apiCall(
                apiConfig.ADVERT.UPDATE_DOWNLOAD_STATUS + `?id=${props.baseObject.selectedContent}&downloadable=${props.advertCost.isDownload}`,
                "put"
            );
            if (resp.status === 200) {
                props.setPurchaseComplete(true)
            }
            
        } catch (error) {
            console.error(error.message);
        }
    }

    const selectedContent = props.baseObject.content.find(
        (item) => item.id === props.baseObject.selectedContent
    );

    return (
        <div className="pb-5">

            {!props.purchaseComplete ?
                props.purchaseObject ?
                    <Col>
                        <Row>
                            <Col>
                                <Label className="labelText">Cost</Label>
                                <div style={{ display: "flex", flexDirection: "column", width: "40%" }}>
                                    {props.advertCost.isNetwork &&
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <p>Ad Budget:</p>
                                            <p>&pound;{props.purchaseObject?.budget}</p>
                                        </div>
                                    }
                                    {props.advertCost.isDownload &&
                                        <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                            <p>Downloading {props.purchaseObject?.downloadQuantity}x advert:</p>
                                            <p>&pound;{props.purchaseObject?.downloadAmount}</p>
                                        </div>
                                    }
                                    <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                                        <p>Total:</p>
                                        <p>&pound;{props.purchaseObject?.amountDue}</p>
                                    </div>
                                </div>
                            </Col>

                            <Col>
                                <Row>
                                    <Col>
                                        <Label className="labelText">Card</Label>
                                        <StripeGenericContainer baseObject={props.purchaseObject} isAdSpend={true} setPurchaseComplete={props.setPurchaseComplete} handlePurchase={handlePurchase} />
                                    </Col>
                                </Row>

                            </Col>
                        </Row>
                    </Col> : <Col className="text-center mt-4">
                        <Spinner />
                    </Col>

                :
                <Col>
                    <Row>
                        <Col className="text-center mt-4">
                            <p>Thank you, your payment has been successful. A confirmation email will be sent to you and you can now see your advert(s) in the Ads section.</p>
                            {props.advertCost.isDownload &&
                                <Col className="">
                                    {selectedContent &&
                                        selectedContent.translations &&
                                        selectedContent.translations.length > 0 && (
                                            <>
                                                {selectedContent.translations.filter(item => item.selected).map((dl) => (
                                                    <Col key={dl.languageCode}>
                                                        <Row className="mt-2 mb-2 justify-content-center">
                                                            <button
                                                                className="buttonStyle buttonPrimary"
                                                                onClick={() => handleDownloadTranslation(dl.url, dl.languageCode)}
                                                            >
                                                                <div
                                                                    style={{
                                                                        display: "flex",
                                                                        alignItems: "center",
                                                                        justifyContent: "space-between",
                                                                        paddingLeft: 7,
                                                                        paddingRight: 7,
                                                                    }}
                                                                >
                                                                    <span>{dl.language}</span>
                                                                    <i className="fa fa-file-arrow-down" />
                                                                </div>
                                                            </button>
                                                        </Row>
                                                    </Col>
                                                ))}
                                            </>
                                        )
                                    }
                                </Col>
                            }

                        </Col>
                    </Row>
                </Col>
            }
        </div>
    )
}

export default WizardAdPayment 