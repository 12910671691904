import React, { useEffect } from "react";
import PropTypes from "prop-types";
import defaultImage from "assets/img/image_placeholder.jpg";
import defaultAvatar from "assets/img/placeholder.jpg";

function CustomImageUpload(props) {
    const [fileState, setFileState] = React.useState(null);
    const [imagePreviewUrl, setImagePreviewUrl] = React.useState(
        props.userImage ? props.userImage : defaultAvatar
    );
    const fileInput = React.useRef();
    const handleImageChange = (e) => {
        e.preventDefault();
        let reader = new FileReader();
        let file = e.target.files[0];
        reader.onloadend = () => {
            setFileState(file);
            props.setUserImage(reader.result)
            setImagePreviewUrl(reader.result);
        };
        if (file) {
            reader.readAsDataURL(file);
        }

    };
    const handleClick = () => {
        fileInput.current.click();
    };

    const updateUserImage = () => {
        setImagePreviewUrl(props.userImage)
    }

    useEffect(() => {
        updateUserImage()
    }, [props.userImage])
    return (
        <div className="fileinput text-center">
            <input type="file" onChange={handleImageChange} ref={fileInput} />
            <div className={"thumbnail" + (props.avatar ? " img-circle" : "")} onClick={() => handleClick()}>
                <img src={imagePreviewUrl ? imagePreviewUrl : defaultImage} alt="..." style={{ width: props.widthProp, height: props.heightProp }} />
            </div>

        </div>
    );
}

CustomImageUpload.propTypes = {
    avatar: PropTypes.bool,
};

export default CustomImageUpload;
