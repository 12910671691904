import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Row } from 'reactstrap';


const HelpModal = (props) => {

    return (
        <Modal isOpen={props.isHelpModalOpen} backdrop="static" keyboard={false} size="lg">
            <Col className='pt-4 labelText'>

                {props?.selectedHelpModal && props.selectedHelpModal}

                <Col className="pb-4">
                    <Row className="justify-content-center pb-2">
                        <button onClick={() => props.setSelectedHelpModal(null)} type="submit" className="buttonStyle buttonPrimary">Okay</button>
                    </Row>
                </Col>
            </Col>
        </Modal>
    );
};

export default HelpModal;
