import React, { useEffect, useState } from "react";
import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import getJWTToken from "config/jwtToken";
// reactstrap components
import {
    CardBody,
    Row,
    Col,
} from "reactstrap";
import FeedbackForm from "../../components/Forms/FeedbackForm";
function Feedback() {
    const [userInfo, setUserInfo] = useState([])
    const getUser = async () => {
        try {
            const resp = await apiCall(
                apiConfig.USER.GET_SINGLE + `?id=${getJWTToken().decoded.nameid}`,
                "get"
            );
            
            setUserInfo(resp.data)


        } catch (error) {
            console.error(error.message);
        }

    }

    useEffect(() => {
        getUser()
    }, [])

    return (
        <>
            <div className="content" style={{ marginTop: 78, paddingLeft: "5%", paddingRight: "5%" }}>
                <Row>
                    <Col md="12">
                        
                            
                            <CardBody className="mt-4 mb-4">
                            <Row>
                                <Col className="d-flex justify-content-center">
                                    <FeedbackForm name={ userInfo.name } email={ userInfo.email } />
                                </Col>
                                    
                                </Row>
                            </CardBody>
                        
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default Feedback;
