import { Col, Row } from "reactstrap"

const TutorialTranslate = () => {
    return (
        <Col className="labelText">

            <Row>
                <Col>
                    <h3>
                        Translate
                    </h3>
                    <p>
                        With Klaxon, your advert can be translated into numerous languages so your message can target and be understood by audiences around the world. Each translated advert you create is available to download and use however you like for just &pound;99.
                    </p>
                    <p>
                        If you want your advert in another or multiple languages just follow these simple steps:
                    </p>
                    <h3>
                        Step 1:
                    </h3>
                    <p>
                        Select each language you want your advert in from the list of options and click the right arrow button to add it to your selection. You can choose as many as you like (each translation uses 2 credits but you get 25 free credits every month and can top up at any time).
                    </p>
                    <h3>Step 2:</h3>
                    <p>
                        Once you're happy with your selection, click the Generate Translations button. The translated versions of your advert will appear after a few seconds for you to listen to and select. Choose the versions you want and click the Next button.
                    </p>
                </Col>
            </Row>



            <Row>
                <Col>
                    <h3>Questions? Email us at <a style={{ textDecoration: "underline", color: "", }} href="mailto:hello@klaxon.ai">hello@klaxon.ai</a></h3>
                </Col>
            </Row>


        </Col>
    )
}

export default TutorialTranslate