import { useState } from "react"
import { Row, Col, Input, FormGroup, Spinner, UncontrolledTooltip } from "reactstrap"
import GeneratedAd from "../AdWizardComps/GeneratedAd"
import WizardPlayer from "../AdWizardComps/audioEditorComp/WizardPlayer"

const WizardAdLanguage = (props) => {
    const [selectedLanguages, setSelectedLanguages] = useState([])
    const [currentSelectedLang, setCurrentSelectedLang] = useState(null)

    const handleLanguageSelect = (lang) => {
        const findLang = props.languages.find((langFromList) => langFromList.value == lang.target.value)
        setCurrentSelectedLang(findLang)
    }

    const handleAddLanguage = () => {
        if (!currentSelectedLang)
            return

        const alreadySelected = selectedLanguages.some(
            (lang) => lang.value === currentSelectedLang.value
        );
        if (alreadySelected)
            return

        const copySelected = [...selectedLanguages]
        copySelected.push(currentSelectedLang)
        setSelectedLanguages(copySelected)
        setCurrentSelectedLang(null)
    }

    const handleRemoveLanguage = () => {
        if (!currentSelectedLang)
            return

        const isNotSelected = !selectedLanguages.some(
            (lang) => lang.value === currentSelectedLang.value
        );
        if (isNotSelected)
            return

        const updatedSelectedLanguages = selectedLanguages.filter(
            (lang) => lang.value !== currentSelectedLang.value
        )

        setSelectedLanguages(updatedSelectedLanguages)
        setCurrentSelectedLang(null)
    };

    const addLanguagesToTranslations = (translations) => {
        return translations.map((translation) => {
            const regex = /\/podcast-ads\/(\d+)-([a-z]{2,3})\.mp3/;
            const match = regex.exec(translation);

            const languageCode = match ? match[2] : null; // Extract language code
            const language = props.languages.find((lang) => lang.value == languageCode)?.label;

            return {
                url: translation,
                language: language,
                languageCode: languageCode,
                selected: false,
            };
        });
    };

    const [isShowingHint, setIsShowingHint] = useState(false)
    const handleGenerateTranslationClicked = async () => {
        const copyLang = [...selectedLanguages];
        const languageArr = copyLang.map((lang) => lang.value);

        const makeTranslations = await props.handleGenerateTranslations(languageArr);

        if (makeTranslations) {
            const translationsWithLanguages = await addLanguagesToTranslations(makeTranslations);

            props.setBaseObject((prevBaseObject) => {
                const updatedContent = prevBaseObject.content.map((item) => {
                    if (item.id === props.baseObject.selectedContent) {
                        return {
                            ...item,
                            translations: [
                                ...(item.translations || []),
                                ...translationsWithLanguages,
                            ],
                        };
                    }
                    return item;
                });

                return {
                    ...prevBaseObject,
                    content: updatedContent,
                };
            });
        }

        setIsShowingHint(true)
    };


    const [playingAudioId, setPlayingAudioId] = useState(null)

    const handleNoTranslation = () => {
        props.setIsTranslation(false)
        props.setCurrentPage((page) => page + 1)
    }

    // Rendering the translations
    const renderTranslations = () => {
        const selectedContent = props.baseObject.content.find(
            (item) => item.id === props.baseObject.selectedContent
        );

        if (!selectedContent?.translations) return null;

        return selectedContent.translations.map((ad, index) => {
            const handleButtonClick = () => {
                props.setBaseObject((prevBaseObject) => {
                    const updatedContent = prevBaseObject.content.map((item) => {
                        if (item.id === props.baseObject.selectedContent) {
                            const updatedTranslations = item.translations.map((translation, i) =>
                                i === index ? { ...translation, selected: !translation.selected } : translation
                            );

                            return { ...item, translations: updatedTranslations };
                        }
                        return item;
                    });

                    return { ...prevBaseObject, content: updatedContent };
                });
            };

            return (
                <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
                    <div style={{ flex: 1 }}>
                        <GeneratedAd
                            advert={ad.url}
                            isLanguage={true}
                            setModalPage={props.setModalPage}
                            voices={props.voices}
                            music={props.music}
                            singleLoadId={props.singleLoadId}
                            language={ad.language}
                            playingAudioId={playingAudioId}
                            setPlayingAudioId={setPlayingAudioId}
                            audioId={index}
                        />
                    </div>
                    <button
                        className="buttonStyle buttonPrimary"
                        onClick={ad.language !== "English" ? () => handleButtonClick() : null}
                        style={{ cursor: ad.language === "English" && "default" }}
                        id={ad.language === "English" && "defaultLangWarning"}
                    >
                        {ad.language === "English" ? <div>
                            <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", }}>
                                <p style={{ margin: 0 }}>Selected</p>
                                <i className="fa fa-check" />
                            </div>
                        </div> :
                            ad.selected ? <div style={{ display: "flex", alignItems: "center", justifyContent: "space-around", }}>
                                <p style={{ margin: 0 }}>Selected</p>
                                <i className="fa fa-check" />
                            </div>
                                :
                                <p style={{ margin: 0 }}>Not selected</p>}
                    </button>
                    <UncontrolledTooltip
                        delay={0}
                        target="defaultLangWarning"
                        style={{ backgroundColor: "#F33B30", color: "white" }}

                    >
                        <p style={{ margin: 0 }}>You cannot deselect the default langauge</p>
                    </UncontrolledTooltip>
                </div>
            );
        });
    };

    return (
        <div className="">
            <Col>
                <Row className="justify-content-between mt-4">
                    <Col style={{ borderRadius: 7, padding: 7, cursor: "pointer", marginLeft: 5, marginRight: 5, minHeight: 85, display: "flex", justifyContent: "center", flexDirection: "column", backgroundColor: !props.isTranslation && "rgba(243,59,48)" }} className={`borderLight`} sm="" onClick={() => handleNoTranslation()}>
                        <p style={{ margin: 0, marginLeft: 5 }}>No Translation Required</p>
                        <p style={{ margin: 0, marginLeft: 5 }}>Just broadcast or download your advert in English.</p>
                    </Col>
                    <Col style={{ borderRadius: 7, padding: 7, cursor: "pointer", marginLeft: 5, marginRight: 5, minHeight: 85, display: "flex", justifyContent: "center", flexDirection: "column", backgroundColor: props.isTranslation && "rgba(243,59,48)" }} className={`borderLight`} sm="" onClick={() => props.setIsTranslation(true)}>
                        <p style={{ margin: 0, marginLeft: 5 }}>Translate your Adverts</p>
                        <p style={{ margin: 0, marginLeft: 5 }}>Hear and download your advert in different languages.</p>
                    </Col>
                </Row>

                <Row className="mt-4 pb-5">
                    {props.isTranslation ? <div style={{ width: "100%" }}>
                        <div style={{ display: "flex", flexDirection: "column" }} >
                            Translation Options
                            <span style={{ fontSize: 12 }}>You can skip this step if you don't want any translations</span>
                        </div>

                        <div>
                            <Row className="justify-content-center mt-4">
                                <Col sm="2">
                                    <FormGroup>
                                        <Input
                                            id="exampleSelect"
                                            multiple
                                            name="select"
                                            type="select"
                                            onClick={handleLanguageSelect}
                                            onDoubleClick={handleAddLanguage}
                                            style={{ minHeight: 200 }}
                                        >
                                            {props.languages.map((lang) => {
                                                if (selectedLanguages.some(selectedLang => selectedLang.value === lang.value)) {
                                                    return null
                                                }
                                                return (
                                                    <option key={lang.value} value={lang.value}>
                                                        {lang.label}
                                                    </option>
                                                );
                                            })}
                                        </Input>
                                    </FormGroup>
                                </Col>
                                <Col sm="2">
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", gap: 10, minHeight: 200 }}>
                                        <div style={{ justifyContent: "center", alignItems: "center", display: "flex", borderRadius: 7, minHeight: 35, maxHeight: 35, minWidth: 70, maxWidth: 70, cursor: "pointer" }} onClick={handleAddLanguage} className="smallAltButton">
                                            <i class="fas fa-chevron-right" style={{}} />
                                        </div>
                                        <div style={{ justifyContent: "center", alignItems: "center", display: "flex", borderRadius: 7, minHeight: 35, maxHeight: 35, minWidth: 70, maxWidth: 70, cursor: "pointer" }} onClick={handleRemoveLanguage} className="smallAltButton">
                                            <i class="fas fa-chevron-left" style={{}} />
                                        </div>

                                    </div>
                                </Col>
                                <Col sm="2">
                                    <FormGroup>

                                        <Input
                                            id="exampleSelect"
                                            multiple
                                            name="select"
                                            type="select"
                                            onClick={handleLanguageSelect}
                                            onDoubleClick={handleRemoveLanguage}
                                            style={{ minHeight: 200 }}
                                        >
                                            {selectedLanguages.map((lang) => {
                                                return (
                                                    <option value={lang.value}>{lang.label}</option>
                                                )
                                            })}
                                        </Input>
                                    </FormGroup>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col sm="4" className="text-center">
                                    {selectedLanguages && selectedLanguages.length > 0 &&
                                        <button onClick={() => handleGenerateTranslationClicked()} type="submit" style={{ minWidth: "100%", backgroundColor: "#F33B30", minHeight: 40, maxHeight: 40, border: "none", boxShadow: "none", borderRadius: 5, color: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", gap: 10 }}><i className="fa fa-list-alt" />
                                            GENERATE TRANSLATIONS ({selectedLanguages.length * 2} CREDITS)
                                            {props.isGenerating &&
                                                <Spinner size="sm" />
                                            }
                                        </button>
                                    }

                                </Col>
                            </Row>
                        </div>

                        <Row className="mt-2">
                            <Col>
                                <div className="mt-2 mb-2" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>
                                        Adverts
                                    </div>
                                    <div>
                                        {isShowingHint &&
                                            "Select Translations"
                                        }
                                    </div>
                                </div>

                                <div>{renderTranslations()}</div>
                            </Col>
                        </Row>
                    </div>
                        :
                        null}
                </Row>
            </Col>
        </div>
    )
}

export default WizardAdLanguage 