import { Col, Row } from "reactstrap"

const TutorialCreateAd = () => {
    return (
        <Col className="labelText">

            <Row>
                <Col>
                    <h3>
                        Create your Podcast or Radio Advert for <span style={{ textTransform: "uppercase" }}>free</span>
                    </h3>
                    <ul>
                        <li>Create a podcast or radio advert for free in less than five minutes - no technical skills, recording equipment, or scripts required!</li>
                        <li>Broadcast your advert in podcasts created by Klaxon users - you set your advertising budget (from just &pound;1) and what categories of podcasts it's heard in.</li>
                        <li>Option to download your advert to use wherever you like for just &pound;99. Use it on your website, on social media, on the radio, or anywhere else with no restrictions or licence fees.</li>
                        <li>Create your advert in multiple different languages and download them for use in different markets.</li>
                    </ul>
                </Col>
            </Row>

            <Row>
                <Col>
                    <p>
                        Welcome to the Klaxon Advert Creation System. Here you can create a podcast or radio advert quickly and easily without needing an existing audio file, technical expertise, or even a script or microphone. And it's FREE to create an advert if you want to broadcast it in podcasts created by our users, or just &pound;99 if you want to download it and use it somewhere else (or to create a version in a different language that you can download).
                    </p>
                    <h3>
                        Have your advert broadcast in our users' podcasts, download it, or do both. The choice is yours.
                    </h3>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h3>Broadcast in our users' podcasts</h3>
                    <p>
                        Klaxon is much more than just an audio advert creation system. Every day people use our consumer focused podcast creation service to create podcasts on a wide range of topics and subjects, from sport and news to hobbies, lifestyle, learning, and many more. And our library of user podcasts is growing rapidly, enabling you to reach new audiences around the world.
                    </p>

                    <p>
                        If you want your advert to be played in podcasts created by Klaxon users (and become part of the Klaxon Adverting Network (KAN)), then you can create your advert for FREE. You just pay for your advert to be broadcast in these podcasts, with you setting a budget that is right for you (from just &pound;1). The higher your budget, the more plays your advert will receive and the more widely it will be heard. You can set when you want your adverting campaign to start and finish and what categories of podcasts it's heard in.
                    </p>
                </Col>
            </Row>

            <Row>
                <Col>

                    <h3>Download your advert</h3>
                    <p>If you want to download the advert you create here so that it can be used somewhere else - like on radio, on social media, or even on a different advertising network - but not in Klaxon users' podcasts, it's just &pound;99 per download. And there are no restrictions or licence fees to pay! It's also the same price to create a downloadable version of your advert in a different language, perfect if you want to target audiences around the world. You create your advert in the exactly the same way as if you were creating an advert to be broadcast in our users' podcasts, but then select 'Download' on the Target page.</p>
                    <p>Your advert will be available to download after you've made your payment in the checkout.</p>

                </Col>
            </Row>

            <Row>
                <Col>
                    <h3>Both - broadcast in user podcasts and download your advert</h3>
                    <p>
                        If you want to broadcast your advert in Klaxon users' podcasts AND download it to use elsewhere, you can select this option on the Target page. You set your advertising budget in the usual way and then the download fee (&pound;99 per download) will be added to this in the checkout.
                    </p>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h3>Already have an advert?</h3>
                    <p>
                        If you already have an audio advert of around 30 seconds long, you can upload it to be broadcast in our users' podcasts. You simply select this option on the Create page, upload your advert, set your budget on the Target page, and then checkout.
                    </p>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h3>Questions? Email us at <a style={{ textDecoration: "underline", color: "", }} href="mailto:hello@klaxon.ai">hello@klaxon.ai</a></h3>
                </Col>
            </Row>


        </Col>
    )
}

export default TutorialCreateAd