import { CardBody, Row, Col, FormGroup, Input, Button, Label, UncontrolledTooltip, Spinner } from "reactstrap"

import { useState, useEffect } from 'react'
import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import PodcastEditImage from "components/CustomUpload/PodcastEditImage.js";
import ReactDatetime from "react-datetime";

import Select from "react-select";
import tooltipText from "../../config/tooltipText";



function CreatePodcast(props) {
    const [dropdownFrequency, setDropdownFrequency] = useState([])
    const [dropdownDuration, setDropdownDuration] = useState([])
    const [dropdownReviewPeriod, setDropdownReviewPeriod] = useState([])

    const [singleSelectFrequency, setSingleSelectFrequency] = useState([])
    const [singleSelectDuration, setSingleSelectDuration] = useState([])
    const [singleSelectReviewPeriod, setSingleSelectReviewPeriod] = useState([])
    const [multiSelectCategory, setMultiSelectCategory] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const [topicArray, setTopicArray] = useState([])
    const [topicBar, setTopicBar] = useState("")
    const [alert, setAlert] = useState(null)


    const getDropdownFrequency = async () => {
        try {
            const resp = await apiCall(
                apiConfig.PODCAST.GET_FREQUENCIES,
                "get"
            );
            setDropdownFrequency(resp.data.map((e) => ({
                value: e.id,
                label: e.name
            })))
        } catch (error) {
            console.error(error.message);
        }
    };

    const getDropdownDuration = async () => {
        try {
            const resp = await apiCall(
                apiConfig.PODCAST.GET_DURATIONS,
                "get"
            );
            setDropdownDuration(resp.data.map((e) => ({
                value: e.id,
                label: e.name
            })))
        } catch (error) {
            console.error(error.message);
        }
    };

    const getDropdownReviewPeriods = async () => {
        try {
            const resp = await apiCall(
                apiConfig.PODCAST.GET_REVIEW_PERIODS,
                "get"
            );
            setDropdownReviewPeriod(resp.data.map((e) => ({
                value: e.id,
                label: e.name
            })))
        } catch (error) {
            console.error(error.message);
        }
    };


    const handleSelectCategory = (value) => {

        if (value === null) {
            setMultiSelectCategory([])
            props.setBaseObject({ ...props.baseObject, categories: [] })
            return
        }
        setMultiSelectCategory(value)

        const reformatObject = value.map((cat) => {
            return (
                cat.value
            )
        }).join(", ")

        props.setBaseObject({ ...props.baseObject, categories: reformatObject })
    }

    const handleDurationChange = (value) => {
        setSingleSelectDuration(value)
        props.setBaseObject({ ...props.baseObject, durationName: value.label, durationId: value.value })
    }

    const handleFrequencyChange = (value) => {
        setSingleSelectFrequency(value)
        props.setBaseObject({ ...props.baseObject, frequencyName: value.label, frequencyId: value.value })
    }

    const handleReviewPeriodChange = (value) => {
        setSingleSelectReviewPeriod(value)
        props.setBaseObject({ ...props.baseObject, reviewPeriodName: value.label, reviewPeriodId: value.value })
    }


    const makeCalls = async () => {
        setIsLoading(true)
        await getDropdownFrequency()
        await getDropdownDuration()
        await getDropdownReviewPeriods()
        setIsLoading(false)
    }

    useEffect(() => {
        setAlert(null)
    }, [topicBar])

    useEffect(() => {
        const currentReviewPeriod = dropdownReviewPeriod.find((e) => e.label === props.baseObject.reviewPeriodName)
        setSingleSelectReviewPeriod(currentReviewPeriod)
        const currentFrequency = dropdownFrequency.find((e) => e.label === props.baseObject.frequencyName)
        setSingleSelectFrequency(currentFrequency)
        const currentDuration = dropdownDuration.find((e) => e.label === props.baseObject.durationName)
        setSingleSelectDuration(currentDuration)
        setTopicArray(props.baseObject.topics)
    }, [dropdownDuration, dropdownFrequency, dropdownReviewPeriod])

    useEffect(() => {
        makeCalls()
    }, []);

    const formatMultiSelect = () => {
        if (props.baseObject.categories.includes(", ")) {
            const idArr = props.baseObject.categories.split(", ")
            const multiSelectArr = idArr.map((catId) => {
                const foundCategory = props.categories.find((cat) => cat.value == catId)
                return (foundCategory)
            })
            setMultiSelectCategory(multiSelectArr)
        }
    }

    useEffect(() => {
        formatMultiSelect()
    }, [])

    return (
        <>
            <div className="content">
                <CardBody>

                    {!isLoading ? <><Row>
                        <Col>

                            <Label className="labelText">Name</Label>
                            <Button
                                className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                id="tooltip80896639011152525"
                                size="sm"
                                style={{ backgroundColor: "white" }}
                            >
                                <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                            </Button>
                            <UncontrolledTooltip
                                delay={0}
                                target="tooltip80896639011152525"
                                style={{ backgroundColor: "#F33B30", color: "white" }}
                            >
                                <p style={{ margin: 0 }}>{tooltipText.EDIT_PODCAST.NAME}</p>
                            </UncontrolledTooltip>
                            <FormGroup>
                                <Input className="placeholderWhite inputStyle" placeholder="Name" type="text" value={props.baseObject.name} onChange={(e) => props.setBaseObject({ ...props.baseObject, name: e.target.value })} />
                            </FormGroup>

                            <Label className="labelText">Description</Label>
                            <Button
                                className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                id="tooltip808966390111525"
                                size="sm"
                                style={{ backgroundColor: "white" }}
                            >
                                <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                            </Button>
                            <UncontrolledTooltip
                                delay={0}
                                target="tooltip808966390111525"
                                style={{ backgroundColor: "#F33B30", color: "white" }}
                            >
                                <p style={{ margin: 0 }}>{tooltipText.EDIT_PODCAST.DESCRIPTION}</p>
                            </UncontrolledTooltip>
                            <FormGroup>
                                <Input className="placeholderWhit inputStyle" placeholder="Description" type="textarea" value={props.baseObject.description} onChange={(e) => props.setBaseObject({ ...props.baseObject, description: e.target.value })} />
                            </FormGroup>


                            <Label className="labelText">Duration</Label>
                            <Button
                                className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                id="tooltip1180896639011152525"
                                size="sm"
                                style={{ backgroundColor: "white" }}
                            >
                                <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                            </Button>
                            <UncontrolledTooltip
                                delay={0}
                                target="tooltip1180896639011152525"
                                style={{ backgroundColor: "#F33B30", color: "white" }}
                            >
                                <p style={{ margin: 0 }}>{tooltipText.EDIT_PODCAST.DURATION}</p>
                            </UncontrolledTooltip>
                            <Select
                                className="react-select "
                                classNamePrefix="react-select"
                                name="singleSelect"
                                value={singleSelectDuration}
                                onChange={(value) => handleDurationChange(value)}
                                options={dropdownDuration}
                            />
                        </Col>
                        <Col md="4" className="text-center">
                            <Label className="labelText">Image</Label>
                            <Button
                                className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                id="tooltip80896639011152525123"
                                size="sm"
                                style={{ backgroundColor: "white" }}
                            >
                                <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                            </Button>
                            <UncontrolledTooltip
                                delay={0}
                                target="tooltip80896639011152525123"
                                style={{ backgroundColor: "#F33B30", color: "white" }}
                            >
                                <p style={{ margin: 0 }}>{tooltipText.EDIT_PODCAST.IMAGE}</p>
                            </UncontrolledTooltip>
                            <FormGroup>
                                <PodcastEditImage currentData={props.baseObject} setData={props.setBaseObject} />
                            </FormGroup>
                        </Col>
                    </Row>
                        <Row>
                            <Col>
                                <Label className="labelText">Frequency</Label>
                                <Button
                                    className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                    id="tooltip8089663901115252509"
                                    size="sm"
                                    style={{ backgroundColor: "white" }}
                                >
                                    <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                                </Button>
                                <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip8089663901115252509"
                                    style={{ backgroundColor: "#F33B30", color: "white" }}
                                >
                                    <p style={{ margin: 0 }}>{tooltipText.EDIT_PODCAST.FREQUENCY}</p>
                                </UncontrolledTooltip>
                                <Select
                                    className="react-select "
                                    classNamePrefix="react-select"
                                    name="singleSelect"
                                    value={singleSelectFrequency}
                                    onChange={(value) => handleFrequencyChange(value)}
                                    options={dropdownFrequency}
                                />
                            </Col>

                            <Col>
                                <Label className="labelText">Review Period</Label>
                                <Button
                                    className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                    id="tooltip80896639011152124"
                                    size="sm"
                                    style={{ backgroundColor: "#F33B30" }}
                                >
                                    <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                                </Button>
                                <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip80896639011152124"
                                    style={{ backgroundColor: "#F33B30", color: "white" }}
                                >
                                    <p style={{ margin: 0 }}>{tooltipText.EDIT_PODCAST.REVIEW_PERIOD}</p>
                                </UncontrolledTooltip>
                                <Select
                                    className="react-select "
                                    classNamePrefix="react-select"
                                    name="singleSelect"
                                    value={singleSelectReviewPeriod}
                                    onChange={(value) => handleReviewPeriodChange(value)}
                                    options={dropdownReviewPeriod}
                                />
                            </Col>
                        </Row>

                        <Row>
                            <Col sm="6">
                                <FormGroup>
                                    <Label className="labelText">Categories</Label>
                                    <Select
                                        className="react-select klaxon-multi"
                                        classNamePrefix="react-select"
                                        placeholder="Categories"
                                        name="multipleSelect"
                                        closeMenuOnSelect={false}
                                        isMulti
                                        value={multiSelectCategory}
                                        onChange={(value) => handleSelectCategory(value)}
                                        options={props.categories}
                                    />
                                </FormGroup>
                            </Col>
                            <Col md="4">
                                {props.baseObject.currentEpisode !== props.baseObject.maxEpisodes ? <> <Label className="labelText">Next Release Date</Label>
                                    <Button
                                        className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                        id="tooltip80896639011152525887"
                                        size="sm"
                                        style={{ backgroundColor: "white" }}
                                    >
                                        <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                                    </Button>
                                    <UncontrolledTooltip
                                        delay={0}
                                        target="tooltip80896639011152525887"
                                        style={{ backgroundColor: "#F33B30", color: "white" }}
                                    >
                                        <p style={{ margin: 0 }}>{tooltipText.EDIT_PODCAST.NEXT_RELEASE_DATE}</p>
                                    </UncontrolledTooltip>
                                    <FormGroup>
                                        <ReactDatetime
                                            inputProps={{
                                                className: "form-control",
                                                initialValue: ""
                                            }}
                                            value={new Date(props.baseObject.nextEpisode)}
                                            onChange={(date) => props.setBaseObject({ ...props.baseObject, nextEpisode: date.toISOString('O') })}
                                            dateFormat="DD/MM/YYYY"
                                        />
                                    </FormGroup></> : null}

                            </Col>
                        </Row></> : <Row><div style={{ display: "flex", justifyContent: "center", width: "100%" }}><Spinner /></div></Row>}

                </CardBody>
            </div>

        </>
    )
}

export default CreatePodcast