import React from "react";
// reactstrap components
import {
    Container,
    Card,
    CardHeader,
    CardBody,
    Collapse,
    Row,
    Col,
} from "reactstrap";
import FAQTabs from "../../config/FAQTabs";

function FAQ() {
    const [openedCollapses, setOpenedCollapses] = React.useState([
        
    ]);
   

    const collapsesToggle = (collapse) => {
        if (openedCollapses.includes(collapse)) {
            setOpenedCollapses(openedCollapses.filter((item) => item !== collapse));
        } else {
            openedCollapses.push(collapse);
            setOpenedCollapses([...openedCollapses, collapse]);
        }
    };

    return (
        <>

            <div className="content" style={{ marginTop: 78 }}>
                <Container>
                    <Row>
                        <Col>
                            <h3 className="text-center" style={{ color: "white", marginTop: 20 }}><b>Frequently Asked Questions</b></h3>
                            <h3 className="text-white">General</h3>
                            <Card className="card-plain">
                                {FAQTabs.GeneralFAQ.map((e, index) => {
                                    return (
                                        <Card className="card-plain">
                                            <CardHeader role="tab" className="faqCard">
                                                <a
                                                    aria-expanded={openedCollapses.includes(`collapse${index}`)}
                                                    href="#pablo"
                                                    data-parent="#accordion"
                                                    data-toggle="collapse"
                                                    onClick={() => collapsesToggle(`collapse${index}`)}
                                                    style={{ color: "white" }}
                                                >
                                                    {e.question }
                                                    <i className="nc-icon nc-minimal-down" />
                                                </a>
                                            </CardHeader>
                                            <Collapse
                                                role="tabpanel"
                                                isOpen={openedCollapses.includes(`collapse${index}`)}
                                            >
                                                <CardBody>
                                                    <p style={{ color: "white" }} dangerouslySetInnerHTML={{ __html: e.answer }}></p>
                                                </CardBody>
                                            </Collapse>
                                        </Card>
                                    )
                                }) }
                            </Card>
                            <h3 className="text-white">For Advertisers / Business</h3>
                            <Card className="card-plain">
                                {FAQTabs.BusinessFAQ.map((e, index) => {
                                    return (
                                        <Card className="card-plain">
                                            <CardHeader role="tab" className="faqCard">
                                                <a
                                                    aria-expanded={openedCollapses.includes(`collapse${index+30}`)}
                                                    href="#pablo"
                                                    data-parent="#accordion"
                                                    data-toggle="collapse"
                                                    onClick={() => collapsesToggle(`collapse${index+30}`)}
                                                    style={{ color: "white" }}
                                                >
                                                    {e.question}
                                                    <i className="nc-icon nc-minimal-down" />
                                                </a>
                                            </CardHeader>
                                            <Collapse
                                                role="tabpanel"
                                                isOpen={openedCollapses.includes(`collapse${index+30}`)}
                                            >
                                                <CardBody>
                                                    <p style={{ color: "white" }} dangerouslySetInnerHTML={{ __html: e.answer} }></p>
                                                </CardBody>
                                            </Collapse>
                                        </Card>
                                    )
                                })}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
}

export default FAQ;
