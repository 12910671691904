const FAQTabs = {
    GeneralFAQ: [
        { question: "What is Klaxon AI / what does Klaxon AI do?", answer: "Klaxon AI is the world's first completely automated 'done for you' podcast creation and publishing system that allows anyone anywhere with an internet connection to quickly and easily make one-off or regular podcasts (a series) without the need for recording equipment, research and creation of a script, a 'broadcast' voice, or any special skills or expertise. With Klaxon AI, you can reach and engage with new audiences anywhere in the world in their local language, and also generate an income too!" },
        { question: "How do I earn money from my Klaxon AI podcasts?", answer: "Once your podcast or podcast episode has been listened to 50 times in a month, you receive a share of the advertising revenue generated from it (if advertising has been included in it). We automatically insert adverts into podcasts created using Klaxon AI wherever possible (which not only means you can earn money but also means we can offer free accounts), and the more listens your podcast or episodes gets, the greater the share that you receive. To maximise the amount you earn, share links to your podcasts and encourage as many people as possible to listen to them. You can see how your podcasts are performing and how much you have earned on the dashboard in your account." },
        { question: "What do I need to create a podcast using Klaxon AI?", answer: "Nothing at all other than an account. You don't need recording equipment, a script, audio editing software, or even a 'broadcast' voice. And you don't need to upload a podcast / audio file you have created offline. The Klaxon AI system walks you through the whole podcast creation process, and in as little as 5 minutes (or less) you could have a unique podcast published and live, ready to be heard by listeners and earning revenue for you." },
        { question: "Can I create more than one podcast or podcast series with Klaxon AI?", answer: "Yes absolutely. You can create as many podcasts or podcasts series as you want subject to the number of minutes you have available in your account. Free account holders have 200 minutes per month to use, while Premium account holders have 1,000 minutes per month (and access to more voices and languages). Users that want more minutes per month to create more or longer podcasts or more series - and the ability to remove adverts from their podcasts or even insert their own adverts - should consider a Bespoke account." },
        { question: "What if I don't want adverts in my podcast?", answer: "Only Bespoke account holders have the option of creating advert-free podcasts or podcasts that feature their own advertising. Adverts in podcasts created by Free and Premium account holders enable us to offer the free account and share revenue with users in the same way that YouTube and TikTok does with video producers." },
        { question: "How long does it take to create a podcast with Klaxon AI?", answer: "You can create a podcast or a podcast series with Klaxon AI in less than 5 minutes! Read <a style=\"text-decoration: underline; color: #F33B30\" href=\"/how-it-works\">How it works</a> to find out more." },
        { question: "Can Klaxon AI really automatically create a podcast series for me?", answer: "Yes 100%. The system has been designed to automate the whole podcast creation and publishing process, including creating a podcast series that has new episodes (featuring the latest content) every day, week, fortnight, or month. You'll receive an email before each episode is published so you can review it before it goes live and make any amendments to the script if you choose to. Alternatively you can be completely 'hands off' and let episodes publish without reviewing them; the choice is 100% yours." },
        { question: "How much does Klaxon AI cost?", answer: "Free account are, um, free! Nada, zilch, nothing! Premium accounts are &pound;24.99 per month (pricing accurate Q1 2024) which gives you access to 1,000 podcast minutes per month (instead of 200), access to all languages, and access to hundreds of voice styles. With both the Free and Premium accounts, you earn revenue from your podcasts once a podcast or episode has more than 50 listens in a month (and the more listens it gets, the greater the share of the revenue)." },
        { question: "What's the difference between a Free and Premium account?", answer: "Please see our pricing page for full details." },
        { question: "What is a Bespoke account?", answer: "A Bespoke account is for businesses or individuals who want more than 1,000 minutes per month, the option to create advert-free podcasts or podcasts that include their own advertising, access to premium voices, a custom revenue share arrangement (if they still want to include Klaxon adverting), the option to use their own voice in their podcasts (using the latest voice cloning technology), API access, and the ability to download their Klaxon AI podcasts to use on their own sites. Each Bespoke account is priced individually based on what the user requires so they don't pay for features they don't need. <a style=\"text-decoration: underline; color: #F33B30\" href=\"/contact-us\">Contact Us</a> if you would like to discuss a Bespoke account." },
        { question: "Who created Klaxon AI and why?", answer: "The concept for Klaxon AI came from three former journalists who know from first-hand experience how difficult it is to create podcasts the 'usual' way. Typically it takes more than 5 hours (often much more) to create a 30 minute podcast and costs over &pound;100 (just factoring in the resources needed), which is why it is estimated 90% of podcasters quit after creating just three episodes. The time and money needed also mean that although podcasts are the fastest growing media format in the world, relatively few people make them. Wanting to change this and democratise podcasting so that everyone - no matter their experience and access to resources - could easily and quickly create podcasts, they developed the unique fully automated Klaxon AI system which enables anybody to reach and engage with new audiences anywhere in the world in any voice and language they want, or any topic they choose, in just a few minutes, for free (and make money from it too!)." },
        { question: "How do I edit or take down one of my podcasts?", answer: "Just go to your list of podcasts in your account, select the podcast you want to edit or take down, and choose the action you want to take." },
        { question: "How do I get more minutes to create more podcasts?", answer: "If you have a Free account and want more minutes to create more or longer podcasts, you'll need to upgrade to a Premium account that gives you 1,000 minutes per month, more than enough to create daily 30 minute podcasts or several different daily or weekly podcast series. If you want even more minutes, please <a style=\"text-decoration: underline; color: #F33B30\" href=\"/contact-us\">contact us</a> about a Bespoke account." },
        { question: "Can I downgrade my Premium account to a Free account?", answer: "Yes you can. To do this, just..." },
        { question: "How many voices and languages are available?", answer: "Premium account holders have access to more than 100 different voice types and styles, and more than 100 different languages including Spanish, French, Hindi, Mandarin, Arabic, Portuguese, and many more. Bespoke account holders also have access to premium voices and the ability to clone their own voice so their podcasts sound like they are being read by them! Free account holders have access to just one language and a more limited range of voices." },
        { question: "Are the voices realistic?", answer: "Yes, amazingly so! Gone are the days when computer generated voices sounded robotic; nowadays it's hard (and becoming harder everyday as technology develops) to tell the difference between an AI generated voice and a 'real' voice. Find out for yourself by listening to any of the podcasts on our site or by creating a Klaxon AI podcast yourself." },
        { question: "What if I want to use my own voice instead?", answer: "We offer Bespoke account holders the option to clone their own voice should they want to use this in their podcasts instead of one of our AI voices. To find out more about a Bespoke account, please <a style=\"text-decoration: underline; color: #F33B30\" href=\"/contact-us\">contact us</a>." },
        { question: "How does Klaxon AI work?", answer: "Please see our <a style=\"text-decoration: underline; color: #F33B30\" href=\"/how-it-works\">How It Works</a> page for details." },
        { question: "Are podcasts still popular?", answer: "According to research and multiple international studies, podcasts are the fastest growing media format in the world with millions more people becoming podcast listeners every year. The USA is the largest consumer of podcasts, but the region where their popularity is growing the fastest is Latin America. Podcasts are also booming across Europe, Australia, India, parts of Africa, and many other parts of the world, with the number of UK listeners expected to reach 20m this year (2024)." },
        { question: "Can I create the same podcast in multiple languages?", answer: "Yes you can! To do this, simply..." },
        { question: "Who do I contact if I have other questions?", answer: "You can contact our friendly customer service team at any time via the <a style=\"text-decoration: underline; color: #F33B30\" href=\"/contact-us\">contact us</a> page." },
    ],
    BusinessFAQ: [
        { question: "Is podcasting advertising effective?", answer: "Studies including by <a style=\"text-decoration: underline; color: #F33B30\" href=\"https://www.theguardian.com/gnm-press-office/2022/mar/16/new-research-podcast-advertising-commands-highest-levels-of-attention\" target=\"_blank\">The Guardian</a> have found that podcast advertising generates more attention and engagement than any other form of advertising around. In fact, research revealed that 64% of listeners give their full attention to podcasts and a huge 62% acted on a brand message heard within a podcast. This makes podcast advertising far more effective than TV, online, social media, print, display, or any other form of advertising. This is why global podcast advertising revenue is forecast to grow from &pound;23bn in 2023 to &pound;130bn by 2030." },
        { question: "How do I advertise in Klaxon AI podcasts?", answer: "If you're a business or agency that wants to advertise in Klaxon AI podcasts, contact us now for more information." },
        { question: "Can I use Klaxon AI to create podcasts for my staff or customers?", answer: "Yes you can, and if you have a Bespoke account you have the option of creating podcasts that don't include any advertising or just feature your own brand messages. Due to their high levels of engagement, and the fact that many people listen to podcasts to learn something whilst doing other things (such as commuting into work, exercising, or simply taking the dog for a walk), they are a great way to share news or information with staff or customers - and far more effective than email newsletters and traditional communication methods. <a style=\"text-decoration: underline; color: #F33B30\" href=\"/contact-us\">Contact us</a> for more information." },
    ]
}

export default FAQTabs