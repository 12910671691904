import { Col, Row } from "reactstrap"

const HelpCreateAd = () => {
    return (
        <Col>

            <Row>
                <Col>
                    <h3>
                        Overview
                    </h3>
                    <p>
                        On this page, you can either Create or Upload an advert. If you are generating an advert from scratch, you have two options:
                    </p>
                    <ul>
                        <li>Enter some keywords or phrases about your business and let our AI come up with an advert script for you.</li>
                        <li>Enter the exact text you want used in your advert (up to 75 words).</li>
                    </ul>
                    <p>
                        Once you've made your selection, click the Create Advert button. This uses 1 credit but you get 25 credits free per month and can always top up if you want more (for example, if you want to make multiple adverts in different languages).
                    </p>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h3>
    Good to know
                    </h3>

                    <p>
                        Our system will magically generate three versions of your advert to choose from. Listen to each of them and change any of the voices or the background music if you like. Just remember to click the Regenerate button after making each change.
                    </p>
                    <p>
                        Once you're happy, simply select your chosen advert and then click the Next button at the bottom of the page.
                    </p>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h3>
                    Want to change the script?
                    </h3>

                    <p>
                        If you want to change the words in your advert, simply go back up to the top of the page and change the keywords or phrases you entered or the exact wording you wrote. Then simply click the Create Advert button again.
                    </p>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h3>Questions? Email us at <a style={{ textDecoration: "underline", color: "",}} href="mailto:hello@klaxon.ai">hello@klaxon.ai</a></h3>
                </Col>
            </Row>


        </Col>
    )
}

export default HelpCreateAd