import React, { useState, useEffect } from "react";
import { FormGroup, Input, Row, Col, Spinner } from "reactstrap";
const BStep3 = React.forwardRef((props, ref) => {

    const [contentSection, setContentSection] = useState("")
    const [contentError, setContentError] = useState(null)
    const [isGenerating, setIsGenerating] = useState(false)
    const [isGenerated, setIsGenerated] = useState(false)
    const [userGeneratedScript, setUserGeneratedScript] = useState(null)

    React.useImperativeHandle(ref, () => ({
        isValidated: async () => {
            if (contentSection === "") {
                props.setTitleNumber(props.titleNumber + 1)
                return true
            }

            if (contentSection) {
                if (isGenerating) {
                    return false
                }

                if (isGenerated) {
                    props.setTitleNumber(props.titleNumber + 1)
                    return true
                } 

            }

            return false
        },
    }));

    const handleGenerateScript = async () => {
        
            let expectedLength
            let stringLength
            if (contentSection !== "") {
                let isValid = false

                expectedLength = 50
                stringLength = contentSection.trim().split(" ").filter(Boolean).length

                if (stringLength >= expectedLength) {
                    setIsGenerating(true)
                    await props.generateScriptForUser(contentSection)
                    setIsGenerating(false)

                } else {
                    setContentError(`More content is required to generate a script. Please enter at least ${expectedLength - stringLength} more words`)
                }
                return isValid

            }
        
    }

    const populateContent = () => {

        const podIntro = { type: "section", value: props.userGeneratedContent.intro, sectionName: "Intro" }
        const podOutro = { type: "section", value: props.userGeneratedContent.outro, sectionName: "Outro" }

        const mapUserContent = props.userGeneratedContent.sections.map((e) => {
            return ({
                type: "section", value: e.content, id: 0, sectionName: e.title
            })
        })
        mapUserContent.unshift(podIntro)
        mapUserContent.push(podOutro)

        setIsGenerated(true)
        setUserGeneratedScript(mapUserContent)
    }

    useEffect(() => {

        if (props.userGeneratedContent)
            populateContent()

    }, [props.userGeneratedContent])
    

    const handleOnChange = (e) => {
        const textarea = e.target
        textarea.style.height = "auto"
        textarea.style.height = `${textarea.scrollHeight}px`
        textarea.style.maxHeight = "none"
        setContentSection(textarea.value)
    }

    useEffect(() => {
        setContentError(null)
    }, [contentSection])

    return (
        <>
            <Col>
                <Row>
                    {!isGenerating && !isGenerated &&
                        <Col>
                            <h4 className="text-center">Enter your content below to create a script</h4>
                            <p className="text-center">You can ignore this step if you have your own script</p>
                        </Col>    
                    }
                </Row>
                <Row className="text-center">
                    <Col>
                        {!isGenerating && !userGeneratedScript && !isGenerated &&
                            <FormGroup>
                                <Input type="textarea" className="placeholderWhite inputStyle" value={contentSection} onChange={(e) => handleOnChange(e)} />
                            </FormGroup>
                        }
                        {isGenerating && !isGenerated &&
                        <Col className="text-center">
                            <h4>Generating Content</h4>
                            <Spinner style={{ color: "#F33B30" }} />
                        </Col>    
                        }
                        {isGenerated &&
                            <Col className="text-center">
                                <h4>Script created</h4>
                            </Col> 
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h4 className="text-center" style={{ color: "#F33B30" }}><strong>{contentError}</strong></h4>
                        <h4 className="text-center" style={{ color: "#F33B30" }}><strong>{props.isScriptFailed && "Error generating, please try again"}</strong></h4>
                    </Col>
                </Row>
                <Row>
                    {!isGenerated && !isGenerating &&
                        <Col className="text-center">
                            <button className="buttonStyle buttonPrimary" onClick={handleGenerateScript}>Generate</button>
                        </Col>    
                    }
                </Row>
            </Col>
        </>
    );
});

export default BStep3;
