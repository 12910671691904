import React, {useState } from "react"

function PaymentStep1(props) {

    const [selectedOption, setSelectedOption] = useState('month');

    const handleOptionChange = (option) => {
        setSelectedOption(option);
    };

    const handlePurchaseItem = (subIdChosen) => {
        const subPrice = props.subscriptionTypes.find((e) => e.id === subIdChosen)
        props.setBaseObject({ ...props.baseObject, subscriptionId: subIdChosen, amount: subPrice.price })
        props.setStepperStep(2)
    }
    return (
        <div class="pricing5 text-white">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-md-8 text-center pb-4">
                        <h3 class="mb-3">Pricing</h3>
                        <h6 class="subtitle font-weight-normal">Choose a plan and get started</h6>
                        <div class="switcher-box mt-4">
                            <div class="btn-group rounded-pill" data-toggle="buttons">
                                <label class="btn btn-outline-secondary btn-md active rounded-left">
                                    <input type="radio" name="options" id="option1" checked={selectedOption === 'month'} onClick={() => handleOptionChange("month")} /> Monthly
                                </label>
                                <label class="btn btn-outline-secondary rounded-right btn-md buttonPrimary">
                                    <input type="radio" name="options" id="option2" checked={selectedOption === 'year'} onClick={() => handleOptionChange("year")} /> Yearly
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row text-center pricing-box">
                    <div class="col-md-4">
                        <div class="card card-shadow mb-4 inputStyle text-white">

                            <div class="card-body">
                                <h6 class="text-black mt-3 mb-0">FREE</h6>
                                <h2 class="text-black mt-0 monthly">&pound;0</h2>
                                <ul class="general-listing" style={{ paddingLeft: 0 }}>
                                    <li class="py-3 d-block">
                                        <h6 class="font-weight-normal">200 minutes per month</h6>

                                    </li>
                                    <li class="py-3 d-block">

                                        <h6 class="subtitle font-weight-normal">English language only</h6>
                                    </li>
                                    <li class="py-3 d-block">

                                        <h6 class="subtitle font-weight-normal">Set number of Azure voices*</h6>
                                    </li>

                                    <li class="py-3 d-block">

                                        <h6 class="subtitle font-weight-normal">Extra entry, with more words to make new line</h6>
                                    </li>
                                    <li class="py-3 d-block">

                                        <h6 class="subtitle font-weight-normal">Extra entry, with more words to make new line</h6>
                                    </li>
                                    <li class="py-3 d-block">

                                        <h6 class="subtitle font-weight-normal">Extra entry</h6>
                                    </li>
                                </ul>
                                {props.holdUser.subscriptionTierId !== 1 ? <button class="buttonStyle buttonPrimary" onClick={handlePurchaseItem}>DOWNGRADE</button> : <button class="buttonStyle buttonPrimary" >CURRENT TIER</button> }
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4 middle-box">
                        <div class="card card-shadow mb-4 inputStyle text-white">
                            <div class="card-body">
                                <h6 class="text-black mt-3 mb-0">PREMIUM</h6>
                                <h2 class="text-black mt-0 monthly">{selectedOption === "month" ? <p style={{ fontSize: 35 }}>&pound;24.99</p> : <p style={{ fontSize: 35 }}> &pound;249<s style={{ fontSize: 16 }}>&pound;299.88</s></p>}</h2>
                                
                                <ul class="general-listing" style={{ paddingLeft: 0 }}>
                                    <li class="py-3 d-block">

                                        <h6 class="subtitle font-weight-normal">1,000 minutes per month</h6>
                                    </li>
                                    <li class="py-3 d-block">
                                        <h6 class="subtitle font-weight-normal">All languages</h6>

                                    </li>
                                    <li class="py-3 d-block">
                                        <h6 class="subtitle font-weight-normal">All Azure voices*</h6>

                                    </li>
                                    <li class="py-3 d-block">

                                        <h6 class="subtitle font-weight-normal">Extra entry, with more words to make new line</h6>
                                    </li>
                                    <li class="py-3 d-block">

                                        <h6 class="subtitle font-weight-normal">Extra entry, with more words to make new line</h6>
                                    </li>
                                    <li class="py-3 d-block">

                                        <h6 class="subtitle font-weight-normal">Extra entry</h6>
                                    </li>
                                </ul>
                                {props.holdUser.subscriptionTierId === 2 || props.holdUser.subscriptionTierId === 3 ? <button class="buttonStyle buttonPrimary" >CURRENT TIER</button> : <button class="buttonStyle buttonPrimary" onClick={() => handlePurchaseItem(selectedOption === "month" ? 2 : 3)}>BUY PREMIUM</button> }
                            </div>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="card card-shadow mb-4 inputStyle text-white">
                            <div class="card-body">
                                <h6 class="text-black mt-3 mb-0">BESPOKE</h6>
                                <h2 class="text-black mt-0 monthly">Custom pricing</h2>
                                <ul class="general-listing" style={{ paddingLeft: 0 }}>
                                    <li class="d-block py-3">
                                        <h6 class="subtitle font-weight-normal">All Premium Benefits <span style={{ fontSize: 10 }}>(custom minutes)</span></h6>
                                    </li>
                                    <li class="d-block py-3">
                                        <h6 class="subtitle font-weight-normal">Ad-free option</h6>
                                    </li>
                                    <li class="d-block py-3">
                                        <h6 class="subtitle font-weight-normal">Voice cloning option</h6>
                                    </li>
                                    <li class="d-block py-3">
                                        <h6 class="subtitle font-weight-normal">Custom revenue share arrangements (with ads)</h6>
                                    </li>
                                    <li class="d-block py-3">
                                        <h6 class="subtitle font-weight-normal">MP3 file download option (for ad free podcasts)</h6>
                                    </li>
                                    <li class="d-block py-3">
                                        <h6 class="subtitle font-weight-normal">API access</h6>
                                    </li>

                                </ul>
                                {props.holdUser.subscriptionTierId === 4 ? <button class="buttonStyle buttonPrimary" >CURRENT TIER</button> : <button class="buttonStyle buttonPrimary" onClick={handlePurchaseItem}>GO BESPOKE</button>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PaymentStep1