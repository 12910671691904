import { Col, Row } from "reactstrap"

const HelpTarget = () => {
    return (
        <Col>

            <Row>
                <Col>
                    <h3>
                        Overview
                    </h3>
                    <p>
                        Here you decide whether to broadcast your advert in our user podcasts, to download it, or to do both. And you can set your advertising budget and select which category of podcasts it will be broadcast in.
                    </p>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h3>Step 1:</h3>
                    <p>
                        If your advert is to be broadcast in Klaxon users' podcasts (or in users' podcasts and being downloaded too), simply giving your advertising campaign a name, set your budget, and select the date range when you want it to be broadcast.
                    </p>

                    <h3>Step 2:</h3>
                    <p>
                        Next either select 'All categories' for your advert to be broadcast in all podcast genres or select 'Set own' to select the specific podcast categories you want it to be broadcast in. You can choose as many categories as you like.
                    </p>
                    <p>
                        You can also set the country and broadcasting time of day for your advert under the Advanced Targeting option.
                    </p>
                    <p>
                        Once you're done, simply click the Next button.
                    </p>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h3>Downloading only</h3>
                    <p>
                        If you just want to download your advert, you just need to give it a name and click Next. Versions of your advert in different languages that you've created will have the same name and will be available to download from the Ads section of the site after you've been to the Checkout.
                    </p>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h3>Questions? Email us at <a style={{ textDecoration: "underline", color: "",}} href="mailto:hello@klaxon.ai">hello@klaxon.ai</a></h3>
                </Col>
            </Row>


        </Col>
    )
}

export default HelpTarget