import React from 'react'
import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import StripeGenericPaymentForm from './StripeGenericPaymentForm'

const PUBLIC_KEY = "pk_test_51OUU6uHMA9RoLHrnh9h3uVa10s5CgYX3NbX23Vbh5eJOaQtu9NAdAjV4UuNVqJZTHn6Ot3ezzrRybEpiwXPTECTe00iDmcjyi0"

const stripeTestPromise = loadStripe(PUBLIC_KEY)
function StripeGenericContainer(props) {
    return (
        <Elements stripe={stripeTestPromise}>
            <StripeGenericPaymentForm baseObject={props.baseObject} setBaseObject={props.setBaseObject} setAlert={props.setAlert} renewCredits={props.renewCredits} handlePurchase={props.handlePurchase} isAdSpend={props.isAdSpend} setPurchaseComplete={props.setPurchaseComplete} />
        </Elements>
    )
}

export default StripeGenericContainer