import React, { useState, useEffect } from "react";
// reactstrap components
import {
    Button,
    FormGroup,
    Input,
    Row,
    Col,
    Label,
    UncontrolledTooltip
} from "reactstrap";
import Select from "react-select";
import tooltipText from "../../../config/tooltipText";
import CustomImageUpload from "../../../components/CustomUpload/CustomImageUpload";


const BStep1 = React.forwardRef((props, ref) => {

    const [holdImage, setHoldImage] = useState("")
    const [alert, setAlert] = useState(null)
    const [singleSelectDuration, setSingleSelectDuration] = useState([])
    const [singleSelectClient, setSingleSelectClient] = useState({})
    const [multiSelectCategory, setMultiSelectCategory] = useState([])



    React.useImperativeHandle(ref, () => ({
        isValidated: () => {
            if (props.baseObject.name === "") {
                setAlert("Please enter a name")
                return false
            }

            if (props.baseObject.description === "") {
                setAlert("Please enter a description")
                return false
            }

            if (props.baseObject.durationId === 0) {
                setAlert("Please select a duration")
                return false
            }

            if (props.baseObject.frequencyId === 0) {
                setAlert("Please select a frequency")
                return false
            }

            if (props.isBusiness && (!props.baseObject.clientId || props.baseObject.clientId == 0)) {
                setAlert("Please select a client")
                return false
            }

            //if (props.baseObject.categories === "" || props.baseObject.categories === "0") {
            //    setAlert("Please select at least one category")
            //    return false
            //}
            props.setTitleNumber(props.titleNumber+1)
            return true
        },
    }));

    const handleDurationChange = (value) => {
        setSingleSelectDuration(value)
        setAlert(null)
        props.setBaseObject({ ...props.baseObject, durationId: value.value, durationName: value.label })
    }

    const handleClientChange = (value) => {
        setSingleSelectClient(value)
        setAlert(null)
        props.setBaseObject({ ...props.baseObject, clientId: value.value })
        localStorage.setItem("cId", value.value)
        props.getAdverts()
    }

    const handleSelectCategory = (value) => {

        if (value === null) {
            setMultiSelectCategory([])
            props.setBaseObject({ ...props.baseObject, categories: [] })
            return
        }
        setMultiSelectCategory(value)

        const reformatObject = value.map((cat) => {
            return (
                cat.value
            )
        }).join(", ")

        props.setBaseObject({ ...props.baseObject, categories: reformatObject })
    }

    useEffect(() => {

        if (holdImage.length > 0) {
            const base64Image = holdImage.split(",")[1]
            props.setBaseObject({ ...props.baseObject, image: base64Image })
        }
    }, [holdImage])

    useEffect(() => {
        if (props.baseObject && props.dropdownClients && props.dropdownClients.length > 0) {
            const currentClient = props.dropdownClients.find((client) => client.value == props.baseObject.clientId)
            setSingleSelectClient(currentClient)
        }
    }, [props.baseObject, props.dropdownClients])

    return (
        <>

            <h4 className="text-center" style={{ color: "#F33B30" }}><strong>{alert}</strong></h4>
            <Row className="justify-content-center">
                <Col sm="12" md="12" lg="8">
                    <FormGroup>
                        <FormGroup>
                            <Label>Podcast Name</Label>
                            <Button
                                className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                id="tooltip8089663905252"
                                size="sm"
                                style={{ backgroundColor: "white" }}
                            >
                                <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                            </Button>
                            <UncontrolledTooltip
                                delay={0}
                                target="tooltip8089663905252"
                                style={{ backgroundColor: "#F33B30", color: "white" }}
                            >
                                <p style={{ margin: 0 }}>{tooltipText.NAME.PODCAST_NAME}</p>
                            </UncontrolledTooltip>
                        </FormGroup>
                        <Input className="inputStyle" type="text" onChange={(e) => props.setBaseObject({ ...props.baseObject, name: e.target.value })} />
                    </FormGroup>

                    <FormGroup>
                        <FormGroup>
                            <Label>Description</Label>
                            <Button
                                className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                id="tooltip8089663917878"
                                size="sm"
                                style={{ backgroundColor: "white" }}
                            >
                                <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                            </Button>
                            <UncontrolledTooltip
                                delay={0}
                                target="tooltip8089663917878"
                                style={{ backgroundColor: "#F33B30", color: "white" }}
                            >
                                <p style={{ margin: 0 }}>{tooltipText.NAME.DESCRIPTION}</p>
                            </UncontrolledTooltip>
                        </FormGroup>
                        <Input className="inputStyle" type="textarea" onChange={(e) => props.setBaseObject({ ...props.baseObject, description: e.target.value })} />
                    </FormGroup>

                    <Row>
                    {props.isBusiness &&
                        <Col>
                            <FormGroup>
                                <Label>Client</Label>
                                <Button
                                    className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                    id="tooltip808966391222123123123"
                                    size="sm"
                                    style={{ backgroundColor: "white" }}
                                >
                                    <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                                </Button>
                                <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966391222123123123"
                                    style={{ backgroundColor: "#F33B30", color: "white" }}
                                >
                                    <p style={{ margin: 0 }}>{tooltipText.DETAILS.CLIENT}</p>
                                </UncontrolledTooltip>
                            </FormGroup>
                            <Select
                                className="react-select "
                                classNamePrefix="react-select"
                                name="singleSelect"
                                value={singleSelectClient}
                                onChange={(value) => handleClientChange(value)}
                                options={props.dropdownClients}
                            />
                        </Col>
                        }
                        <Col md="6">
                            <FormGroup>
                                <Label>Duration</Label>
                                <Button
                                    className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                                    id="tooltip808966391222"
                                    size="sm"
                                    style={{ backgroundColor: "white" }}
                                >
                                    <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                                </Button>
                                <UncontrolledTooltip
                                    delay={0}
                                    target="tooltip808966391222"
                                    style={{ backgroundColor: "#F33B30", color: "white" }}
                                >
                                    <p style={{ margin: 0 }}>{tooltipText.DETAILS.DURATION}</p>
                                </UncontrolledTooltip>
                            </FormGroup>
                            <Select
                                className="react-select "
                                classNamePrefix="react-select"
                                name="singleSelect"
                                value={singleSelectDuration}
                                onChange={(value) => handleDurationChange(value)}
                                options={props.dropdownDurations}
                            />
                        </Col>
                        
                        {props.isBusiness &&
                        <Col className="text-center" style={{display: "flex", alignItems: "flex-end", justifyContent: "center"} }>
                            <FormGroup check>
                                <Label check onChange={() => props.setBaseObject({ ...props.baseObject, includeAds: !props.baseObject.includeAds })}>
                                    <Input type="checkbox" checked={props.baseObject.includeAds} />
                                    <span className="form-check-sign" />
                                    Include Ads
                                </Label>
                            </FormGroup>
                        </Col>
                        }
                    </Row>
                    {/*<Row>*/}
                    {/*    <Col sm="8">*/}
                    {/*        <FormGroup>*/}
                    {/*            <Label>Categories</Label>*/}
                    {/*            <Select*/}
                    {/*                className="react-select klaxon-multi"*/}
                    {/*                classNamePrefix="react-select"*/}
                    {/*                placeholder="Categories"*/}
                    {/*                name="multipleSelect"*/}
                    {/*                closeMenuOnSelect={false}*/}
                    {/*                isMulti*/}
                    {/*                value={multiSelectCategory}*/}
                    {/*                onChange={(value) => handleSelectCategory(value)}*/}
                    {/*                options={props.categories}*/}
                    {/*            />*/}
                    {/*        </FormGroup>*/}
                    {/*    </Col>*/}
                    {/*</Row>*/}

                </Col>
                <Col style={{ display: "flex", flexDirection: "column", alignItems: "center" }} sm="12" md="12" lg="4">
                    <FormGroup>
                        <Label>Image</Label>
                        <Button
                            className="btn-icon btn-neutral dropdownIgnore tooltipHover"
                            id="tooltip8089663925555"
                            size="sm"
                            style={{ backgroundColor: "white" }}
                        >
                            <i className="nc-icon nc-alert-circle-i" style={{ fontSize: "16px", color: "#f33b30" }} />
                        </Button>
                        <UncontrolledTooltip
                            delay={0}
                            target="tooltip8089663925555"
                            style={{ backgroundColor: "#F33B30", color: "white" }}
                        >
                            <p style={{ margin: 0 }}>{tooltipText.NAME.IMAGE}</p>
                        </UncontrolledTooltip>
                    </FormGroup>
                    <CustomImageUpload userImage={holdImage} setUserImage={setHoldImage} widthProp={150} heightProp={150} />
                </Col>
            </Row>
        </>
    );
});

export default BStep1;
