import { useState, useEffect } from "react"
import { Row, Col, Input, FormGroup, Label, InputGroupText , InputGroup} from "reactstrap"
import CollapseForm from "../AdWizardComps/CollapseForm"
import ReactDatetime from "react-datetime";
import Select from "react-select";

const WizardAdTarget = (props) => {
    const [isNetwork, setIsNetwork] = useState(true)
    const [isDownload, setIsDownload] = useState(false)
    
    //May be used later
    const [isBadTargeting, setIsBadTargeting] = useState(true)
    const [categoryTargetType, setCategoryTargetType] = useState("all")


    //Selects
    const [multipleSelectCategory, setMultipleSelectCategory] = useState(null);
    const handleSelectCategory = (value) => {

        if (value === null) {
            setMultipleSelectCategory([])
            props.setBaseObject({ ...props.baseObject, categories: [] })
            return
        }
        setMultipleSelectCategory(value)

        const reformatObject = value.map((e) => ({
            id: e.value,
            name: e.label
        }))

        props.setBaseObject({ ...props.baseObject, categories: reformatObject })
    }

    const [multipleSelectLocation, setMultipleSelectLocation] = useState(null);
    const handleSelectLocation = (value) => {

        if (value === null) {
            setMultipleSelectLocation([])
            props.setBaseObject({ ...props.baseObject, geoLocations: [] })
            return
        }
        setMultipleSelectLocation(value)

        const reformatObject = value.map((e) => ({
            id: e.value,
            name: e.label
        }))


        props.setBaseObject({ ...props.baseObject, geoLocations: reformatObject })
    }


    const [multipleSelectAudienceAge, setMultipleSelectAudienceAge] = useState(null);
    const handleSelectAudienceAge = (value) => {

        if (value === null) {
            setMultipleSelectAudienceAge([])
            props.setBaseObject({ ...props.baseObject, age: [] })
            return
        }
        setMultipleSelectAudienceAge(value)

        const reformatObject = value.map((e) => ({
            id: e.value,
            name: e.label
        }))


        props.setBaseObject({ ...props.baseObject, age: reformatObject })
    }

    const [multipleSelectAudienceInterest, setMultipleSelectAudienceInterest] = useState(null);
    const handleSelectAudienceInterest = (value) => {

        if (value === null) {
            setMultipleSelectAudienceInterest([])
            props.setBaseObject({ ...props.baseObject, interests: [] })
            return
        }
        setMultipleSelectAudienceInterest(value)

        const reformatObject = value.map((e) => ({
            id: e.value,
            name: e.label
        }))


        props.setBaseObject({ ...props.baseObject, interests: reformatObject })
    }

    const [multipleSelectTime, setMultipleSelectTime] = useState(null);
    const handleSelectTime = (value) => {

        if (value === null) {
            setMultipleSelectTime([])
            props.setBaseObject({ ...props.baseObject, time: [] })
            return
        }
        setMultipleSelectTime(value)

        const reformatObject = value.map((e) => ({
            id: e.value,
            name: e.label
        }))


        props.setBaseObject({ ...props.baseObject, time: reformatObject })
    }

    const [multipleSelectInventory, setMultipleSelectInventory] = useState(null);
    const handleSelectInventory = (value) => {

        if (value === null) {
            setMultipleSelectInventory([])
            props.setBaseObject({ ...props.baseObject, adPositions: [] })
            return
        }
        setMultipleSelectInventory(value)

        const reformatObject = value.map((e) => ({
            id: e.value,
            name: e.label
        }))

        props.setBaseObject({ ...props.baseObject, adPositions: reformatObject })
    }

    const [selectedTab, setSelectedTab] = useState("network")
    const handleTabChange = (tab) => {
        if (tab === "network") {
            setIsNetwork(true)
            setIsDownload(false)
        } else if (tab === "download") {
            setIsNetwork(false)
            setIsDownload(true)
        } else {
            setIsNetwork(true)
            setIsDownload(true)
        }
        setSelectedTab(tab)
    }

    const [isCampaignOpen, setIsCampaignOpen] = useState(true)

    //useEffect(() => {
    //    if (categoryTargetType === "all") {
    //        setMultipleSelectCategory([])
    //        props.setBaseObject({ ...props.baseObject, categories: [] })
    //    }
    //}, [categoryTargetType])

    useEffect(() => {
        if (selectedTab == "network") {
            props.setAdvertCost({ ...props.advertCost, isDownload: false, isNetwork: true })
        } else if (selectedTab === "download") {
            props.setAdvertCost({ ...props.advertCost, isDownload: true, isNetwork: false })
        } else {
            props.setAdvertCost({ ...props.advertCost, isDownload: true, isNetwork: true })
        }
    }, [selectedTab])
    
    const returnMappedArray = (arr) => {
        const newArr = arr.map((a) => {
            return ({
                value: a.id,
                label: a.name
            })
        })
        return newArr
    }

    const populateCategory = () => {
        const mapped = returnMappedArray(props.baseObject.categories)
        setMultipleSelectCategory(mapped)
    }

    const populateTargets = () => {
        if (props.baseObject.geoLocations && props.baseObject.geoLocations.length > 0) {
            const savedLocations = returnMappedArray(props.baseObject.geoLocations)
            setMultipleSelectLocation(savedLocations)

        }

        if (props.baseObject.age && props.baseObject.age.length > 0) {
            const savedAgess = returnMappedArray(props.baseObject.age)
            setMultipleSelectAudienceAge(savedAgess)

        }

        if (props.baseObject.interests && props.baseObject.interests.length > 0) {
            const savedInterests = returnMappedArray(props.baseObject.interests)
            setMultipleSelectAudienceInterest(savedInterests)

        }

        if (props.baseObject.time && props.baseObject.time.length > 0) {
            const savedTimes = returnMappedArray(props.baseObject.time)
            setMultipleSelectTime(savedTimes)
        }

        if (props.baseObject.adPositions && props.baseObject.adPositions.length > 0) {
            const savedAdPositions = returnMappedArray(props.baseObject.adPositions)
            setMultipleSelectInventory(savedAdPositions)
        }
    }

    useEffect(() => {
        if (props.baseObject.categories && props.baseObject.categories.length > 0) {
            populateCategory()
        }

        populateTargets()
    }, [props.baseObject])

    return (
        <div className=" pb-4">

            <Col>

                <Row className="justify-content-between mt-4">
                    <Col style={{ border: "1px solid rgba(225, 225, 225, 0.3)", borderRadius: 7, padding: 7, cursor: "pointer", marginLeft: 5, marginRight: 5, minHeight: 85, display: "flex", justifyContent: "center", flexDirection: "column", backgroundColor: isNetwork && !isDownload && "rgba(243,59,48)" }} sm="" onClick={() => handleTabChange("network")}>
                        <p style={{ margin: 0, marginLeft: 5 }}>Klaxon Ad Network</p>
                        <p style={{ margin: 0, marginLeft: 5 }}>Play your advert in Klaxon users' podcasts (English language adverts only).</p>
                    </Col>
                    <Col style={{ border: "1px solid rgba(225, 225, 225, 0.3)", borderRadius: 7, padding: 7, cursor: "pointer", marginLeft: 5, marginRight: 5, minHeight: 85, display: "flex", justifyContent: "center", flexDirection: "column", backgroundColor: !isNetwork && isDownload && "rgba(243,59,48)" }}  sm="" onClick={() => handleTabChange("download")}>
                        <p style={{ margin: 0, marginLeft: 5 }}>Download</p>
                        <p style={{ margin: 0, marginLeft: 5 }}>Download your English language or other language advert to use elsewhere.</p>
                    </Col>
                    <Col style={{ border: "1px solid rgba(225, 225, 225, 0.3)", borderRadius: 7, padding: 7, cursor: "pointer", marginLeft: 5, marginRight: 5, minHeight: 85, display: "flex", justifyContent: "center", flexDirection: "column", backgroundColor: isNetwork && isDownload && "rgba(243,59,48)" }} sm="" onClick={() => handleTabChange("networkanddownload")}>
                        <p style={{ margin: 0, marginLeft: 5 }}>Klaxon Ad Network & Download</p>
                        <p style={{ margin: 0, marginLeft: 5 }}>Play in users' podcasts and download to use elsewhere (English language adverts only).</p>
                    </Col>
                </Row>

                {isDownload && !isNetwork &&
                    <Row className="mt-3">
                        <Col>
                            <CollapseForm title={"Advertising campaign details"} setOpen={setIsCampaignOpen} startOpen={isCampaignOpen}>
                                <div style={{ backgroundColor: "", padding: 10 }} className="menuTopColor">
                                    <Row md={12}>
                                        <Col sm={6}>
                                            <Label className="labelText">Give your advertising campaign a name</Label>
                                            <FormGroup className="">
                                                <Label className="labelText">Name {props.requiredMissing && props.requiredMissing.includes("name") && <span style={{ color: "red", fontWeight: "bold" }}>required</span>}</Label>

                                                <Input className="inputStyle placeholderWhite inputAlt" placeholder="Enter Campaign Name" value={props.baseObject.name} onChange={(e) => props.setBaseObject({ ...props.baseObject, name: e.target.value })} />

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </CollapseForm>
                            <div style={{ width: "100%", backgroundColor: "#F33B30", border: "none", boxShadow: "none", borderRadius: 5, color: "#FFF", padding: 10, margin: "auto", display: "flex", flexDirection: "column", gap: 10 }}>
                                <div style={{ display: "flex", gap: 15, alignItems: "center" }}>
                                    <i className="fa fa-info-circle" />
                                    Download will be available after payment
                                </div>
                                <div>Advert .mp3 file will be available to download after successful payment and from the Ads page.</div>
                            </div>
                        </Col>
                    </Row>
                }

                {isNetwork &&
                    <Row className="mt-3">
                        <Col>
                            <CollapseForm title={"Advertising campaign details"} setOpen={setIsCampaignOpen} startOpen={isCampaignOpen}>
                                <div style={{ backgroundColor: "", padding: 10 }} className="menuTopColor">
                                    <Row>
                                        <Col sm={6}>
                                            <Label className="labelText">Give your advertising campaign a name, set your budget, and choose when your advert will be broadcast</Label>
                                            <FormGroup className="">
                                                <Label className="labelText">Name {props.requiredMissing && props.requiredMissing.includes("name") && <span style={{ color: "red", fontWeight: "bold" }}>required</span>}</Label>

                                                <Input className="inputStyle placeholderWhite inputAlt" placeholder="Enter Campaign Name" value={props.baseObject.name} onChange={(e) => props.setBaseObject({ ...props.baseObject, name: e.target.value })} />

                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <FormGroup className="">
                                                <Label className="labelText">
                                                    Budget @ &pound;15 per 1,000 advert plays (&pound;15 CPM) (&pound;50 minimum) {props.requiredMissing && props.requiredMissing.includes("budget") && <span style={{ color: "red", fontWeight: "bold" }}>Please increase your budget</span>}
                                                </Label>

                                                <InputGroup>
                                                    <div
                                                        style={{
                                                            backgroundColor: "transparent",
                                                            border: "none",
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                            paddingLeft: 3,
                                                            paddingRight: 5
                                                        } }
                                                    >
                                                    &pound;
                                                    </div>
                                                    <Input
                                                        className="inputStyle placeholderWhite inputAlt"
                                                        type="number"
                                                        placeholder="15 minumum"
                                                        value={props.baseObject.budget}
                                                        onChange={(e) => props.setBaseObject({ ...props.baseObject, budget: e.target.value })}
                                                    />
                                                </InputGroup>
                                            </FormGroup>
                                        </Col>
                                        <Col>
                                            {isCampaignOpen &&
                                                <Row>
                                                    <Col>
                                                        <FormGroup className="">
                                                            <Label className="labelText">From {props.requiredMissing && props.requiredMissing.includes("runningFrom") && <span style={{ color: "red", fontWeight: "bold" }}>required</span>}</Label>

                                                            <ReactDatetime
                                                                inputProps={{
                                                                    className: "form-control",
                                                                    initialValue: ""
                                                                }}
                                                                className="inputAlt"
                                                                value={new Date(props.baseObject.runningFrom)}
                                                                dateFormat="DD/MM/YYYY"
                                                                onChange={(date) => props.setBaseObject({ ...props.baseObject, runningFrom: date.toISOString() })}
                                                            />

                                                        </FormGroup>
                                                    </Col>
                                                    <Col>
                                                        <FormGroup className="">
                                                            <Label className="labelText">To {props.requiredMissing && props.requiredMissing.includes("runningTo") && <span style={{ color: "red", fontWeight: "bold" }}>required</span>}</Label>

                                                            <ReactDatetime
                                                                inputProps={{
                                                                    className: "form-control",
                                                                    initialValue: ""
                                                                }}
                                                                className="inputAlt"
                                                                value={new Date(props.baseObject.runningTo)}
                                                                dateFormat="DD/MM/YYYY"
                                                                onChange={(date) => props.setBaseObject({ ...props.baseObject, runningTo: date.toISOString() })}
                                                            />

                                                        </FormGroup>
                                                    </Col>
                                                </Row>
                                            }
                                        </Col>

                                    </Row>
                                    {props.invalidDateRange &&
                                        <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                            <Label className="whiteLabel" style={{ fontWeight: "bold" }}>The time your advert starts cannot be after it ends</Label>
                                        </div>
                                    }
                                </div>
                            </CollapseForm>

                            <CollapseForm title={"Select podcast categories"}>
                                <div style={{ backgroundColor: "", padding: 10 }} className="menuTopColor">
                                    <Row>
                                        <Col>
                                            <Label className="labelText">Pick specific podcasts categories your advert will be played in or select Al</Label>
                                            <FormGroup className="">
                                                <Label className="labelText">Categories</Label>

                                                <Row>

                                                    <Col>
                                                        <FormGroup check>
                                                            <Label check className="labelText">
                                                                <Input type="checkbox" checked={props.baseObject.isAllCat} onClick={() => props.setBaseObject({ ...props.baseObject, isAllCat: true })} />
                                                                <span className="form-check-sign" />
                                                                All categories
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>

                                                    <Col>
                                                        <FormGroup check>
                                                            <Label check className="labelText">
                                                                <Input type="checkbox" checked={!props.baseObject.isAllCat} onClick={() => props.setBaseObject({ ...props.baseObject, isAllCat: false })} />
                                                                <span className="form-check-sign" />
                                                                Pick categories
                                                            </Label>
                                                        </FormGroup>
                                                    </Col>
                                                </Row>

                                                {!props.baseObject.isAllCat &&
                                                    <FormGroup>
                                                        <Select
                                                            className="react-select klaxon-multi inputAlt"
                                                            classNamePrefix="react-select"
                                                            placeholder="Categories"
                                                            name="multipleSelect"
                                                            closeMenuOnSelect={false}
                                                            isMulti
                                                            value={multipleSelectCategory}
                                                            onChange={(value) => handleSelectCategory(value)}
                                                            options={props.categories}
                                                        />
                                                    </FormGroup>
                                                }

                                            </FormGroup>
                                        </Col>

                                    </Row>
                                </div>
                            </CollapseForm>

                            {/*<CollapseForm title={"Advanced Targeting - Optional"}>*/}
                            {/*    <div style={{ backgroundColor: "", padding: 10 }} className="menuTopColor">*/}
                            {/*        <Row>*/}
                            {/*            <Col>*/}
                            {/*                <Row>*/}
                            {/*                    <Col>*/}
                            {/*                        <Label className="labelText">*/}
                            {/*                            Location*/}
                            {/*                        </Label>*/}
                            {/*                    </Col>*/}
                            {/*                </Row>*/}
                            {/*                <Row>*/}
                            {/*                    <Col>*/}
                            {/*                        <div>*/}
                            {/*                            <Row className="mb-2">*/}
                            {/*                                <Col>*/}
                            {/*                                    <Select*/}
                            {/*                                        className="react-select inputAlt"*/}
                            {/*                                        classNamePrefix="react-select"*/}
                            {/*                                        name="singleSelect"*/}
                            {/*                                        options={[{ value: 2, label: "Country" }]}*/}
                            {/*                                        value={{ value: 2, label: "Country" }}*/}
                            {/*                                    />*/}
                            {/*                                </Col>*/}
                            {/*                                <Col>*/}
                            {/*                                    <Select*/}
                            {/*                                        className="react-select inputAlt"*/}
                            {/*                                        classNamePrefix="react-select"*/}
                            {/*                                        name="singleSelect"*/}
                            {/*                                        options={[{ value: 1, label: "Is any of" }]}*/}
                            {/*                                        value={{ value: 1, label: "Is any of" }}*/}
                            {/*                                    />*/}
                            {/*                                </Col>*/}
                            {/*                                <Col>*/}
                            {/*                                    <FormGroup>*/}
                            {/*                                        <Select*/}
                            {/*                                            className="react-select klaxon-multi inputAlt"*/}
                            {/*                                            classNamePrefix="react-select"*/}
                            {/*                                            placeholder="Locations"*/}
                            {/*                                            name="multipleSelect"*/}
                            {/*                                            closeMenuOnSelect={false}*/}
                            {/*                                            isMulti*/}
                            {/*                                            value={multipleSelectLocation}*/}
                            {/*                                            onChange={(value) => handleSelectLocation(value)}*/}
                            {/*                                            options={props.advertTargeting.filter(targ => targ.typeId === 1)}*/}
                            {/*                                        />*/}
                            {/*                                    </FormGroup>*/}
                            {/*                                </Col>*/}
                            {/*                            </Row>*/}

                            {/*                        </div>*/}
                            {/*                    </Col>*/}

                            {/*                </Row>*/}

                            {/*            </Col>*/}
                            {/*        </Row>*/}

                                    {/* <Row>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Label className="whiteLabel">Audience</Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div>
                                                        <Row className="mb-2">
                                                            <Col>
                                                                <Select
                                                                    className="react-select "
                                                                    classNamePrefix="react-select"
                                                                    name="singleSelect"
                                                                    options={[{ id: 1, label: "Age" }]}
                                                                    value={{ id: 1, label: "Age" }}
                                                                    disabled
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Select
                                                                    className="react-select "
                                                                    classNamePrefix="react-select"
                                                                    name="singleSelect"
                                                                    options={[{ value: 1, label: "Is any of" }]}
                                                                    value={{ value: 1, label: "Is any of" }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Select
                                                                        className="react-select klaxon-multi"
                                                                        classNamePrefix="react-select"
                                                                        placeholder="Ages"
                                                                        name="multipleSelect"
                                                                        closeMenuOnSelect={false}
                                                                        isMulti
                                                                        value={multipleSelectAudienceAge}
                                                                        onChange={(value) => handleSelectAudienceAge(value)}
                                                                        options={advertTargeting.filter(targ => targ.typeId === 2)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col>
                                                    <div>
                                                        <Row className="mb-2">
                                                            <Col>
                                                                <Select
                                                                    className="react-select "
                                                                    classNamePrefix="react-select"
                                                                    name="singleSelect"
                                                                    options={[{ id: 2, label: "Interests" }]}
                                                                    value={{ id: 2, label: "Interests" }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Select
                                                                    className="react-select "
                                                                    classNamePrefix="react-select"
                                                                    name="singleSelect"
                                                                    options={[{ value: 1, label: "Is any of" }]}
                                                                    value={{ value: 1, label: "Is any of" }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Select
                                                                        className="react-select klaxon-multi"
                                                                        classNamePrefix="react-select"
                                                                        placeholder="Interests"
                                                                        name="multipleSelect"
                                                                        closeMenuOnSelect={false}
                                                                        isMulti
                                                                        value={multipleSelectAudienceInterest}
                                                                        onChange={(value) => handleSelectAudienceInterest(value)}
                                                                        options={advertTargeting.filter(targ => targ.typeId === 3)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>

                                                    </div>
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row> */}

                                    {/*<Row>*/}
                                    {/*    <Col>*/}
                                    {/*        <Row>*/}
                                    {/*            <Col>*/}
                                    {/*                <Label className="labelText">Day & Time</Label>*/}
                                    {/*            </Col>*/}
                                    {/*        </Row>*/}
                                    {/*        <Row>*/}
                                    {/*            <Col>*/}
                                    {/*                <div>*/}
                                    {/*                    <Row className="mb-2">*/}
                                    {/*                        <Col>*/}
                                    {/*                            <Select*/}
                                    {/*                                className="react-select inputAlt"*/}
                                    {/*                                classNamePrefix="react-select"*/}
                                    {/*                                name="singleSelect"*/}
                                    {/*                                options={[{ value: 1, label: "Time" }]}*/}
                                    {/*                                value={{ value: 1, label: "Time" }}*/}
                                    {/*                            />*/}
                                    {/*                        </Col>*/}
                                    {/*                        <Col>*/}
                                    {/*                            <Select*/}
                                    {/*                                className="react-select inputAlt"*/}
                                    {/*                                classNamePrefix="react-select"*/}
                                    {/*                                name="singleSelect"*/}
                                    {/*                                options={[{ value: 1, label: "Is any of" }]}*/}
                                    {/*                                value={{ value: 1, label: "Is any of" }}*/}
                                    {/*                            />*/}
                                    {/*                        </Col>*/}
                                    {/*                        <Col>*/}
                                    {/*                            <FormGroup>*/}
                                    {/*                                <Select*/}
                                    {/*                                    className="react-select klaxon-multi inputAlt"*/}
                                    {/*                                    classNamePrefix="react-select"*/}
                                    {/*                                    placeholder="Time"*/}
                                    {/*                                    name="multipleSelect"*/}
                                    {/*                                    closeMenuOnSelect={false}*/}
                                    {/*                                    isMulti*/}
                                    {/*                                    value={multipleSelectTime}*/}
                                    {/*                                    onChange={(value) => handleSelectTime(value)}*/}
                                    {/*                                    options={props.advertTargeting.filter(targ => targ.typeId === 4)}*/}
                                    {/*                                />*/}
                                    {/*                            </FormGroup>*/}
                                    {/*                        </Col>*/}
                                    {/*                    </Row>*/}
                                    {/*                </div>*/}
                                    {/*            </Col>*/}
                                    {/*        </Row>*/}

                                    {/*    </Col>*/}
                                    {/*</Row>*/}

                                    {/* <Row>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <Label className="whiteLabel">Inventory</Label>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div>
                                                        <Row className="mb-2">
                                                            <Col>
                                                                <Select
                                                                    className="react-select "
                                                                    classNamePrefix="react-select"
                                                                    name="singleSelect"
                                                                    options={[{ value: 1, label: "Ad Position" }]}
                                                                    value={{ value: 1, label: "Ad Position" }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <Select
                                                                    className="react-select "
                                                                    classNamePrefix="react-select"
                                                                    name="singleSelect"
                                                                    options={[{ value: 1, label: "Is any of" }]}
                                                                    value={{ value: 1, label: "Is any of" }}
                                                                />
                                                            </Col>
                                                            <Col>
                                                                <FormGroup>
                                                                    <Select
                                                                        className="react-select klaxon-multi"
                                                                        classNamePrefix="react-select"
                                                                        placeholder="Inventory"
                                                                        name="multipleSelect"
                                                                        closeMenuOnSelect={false}
                                                                        isMulti
                                                                        value={multipleSelectInventory}
                                                                        onChange={(value) => handleSelectInventory(value)}
                                                                        options={advertTargeting.filter(targ => targ.typeId === 5)}
                                                                    />
                                                                </FormGroup>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </Row>

                                        </Col>
                                    </Row> */}

                            {/*    </div>*/}
                            {/*</CollapseForm>*/}
                        </Col>
                        {/*<Col sm="4">*/}
                        {/*    <div style={{ backgroundColor: "rgba(225, 225, 225, 0.3)", padding: "5px 10px", display: "flex", justifyContent: "space-between", alignItems: "center" }}>*/}
                        {/*        <p style={{ margin: 0 }}>Reach Forecast</p>*/}
                        {/*    </div>*/}
                        {/*    <div style={{ backgroundColor: "rgba(225, 225, 225, 0.4)", padding: "5px 10px 5px", display: "flex", flexDirection: "column" }}>*/}
                        {/*        <div className="mb-2">*/}
                        {/*            <h3 style={{ margin: 0 }}>Target Impressions</h3>*/}
                        {/*            <p style={{ margin: 0 }}>Target number of Listens</p>*/}
                        {/*            <h3 style={{ margin: 0 }}>1,000,000</h3>*/}
                        {/*        </div>*/}
                        {/*        <div className="mb-2">*/}
                        {/*            <h3 style={{ margin: 0 }}>Estimated Available Impressions</h3>*/}
                        {/*            <p style={{ margin: 0 }}>Number of estimated podcast listens within the specified timeframe that match your target audience.</p>*/}
                        {/*            <h3 style={{ margin: 0 }}>250,000</h3>*/}
                        {/*        </div>*/}
                        {/*        {isBadTargeting &&*/}
                        {/*            <div style={{ width: "90%", margin: "auto", backgroundColor: "rgba(225, 225, 225, 0.3)", padding: 10, borderRadius: 7, marginBottom: 5 }}>*/}
                        {/*                <h4 style={{ margin: 0 }}>Target likely to be missed</h4>*/}
                        {/*                <p style={{ margin: 0 }}>Given the budget, targeting criteria and campaign length, there is a chance the campaign will not reach the desired target impressions.</p>*/}
                        {/*                <p style={{ margin: 0, fontWeight: "bold" }}>Steps to resolve:</p>*/}
                        {/*                <ul>*/}
                        {/*                    <li>Increase the budget</li>*/}
                        {/*                    <li>Add more categories</li>*/}
                        {/*                    <li>Increase the campaign length</li>*/}
                        {/*                    <li>Reduce Advance Targeting criteria</li>*/}
                        {/*                </ul>*/}
                        {/*                <div className="text-center">*/}
                        {/*                    <button type="submit" style={{ minWidth: 70, backgroundColor: "#F33B30", minHeight: 35, maxHeight: 35, border: "none", boxShadow: "none", borderRadius: 5, color: "#FFF", fontWeight: "bold" }}>RECALCULATE</button>*/}
                        {/*                </div>*/}

                        {/*            </div>*/}
                        {/*        }*/}
                        {/*    </div>*/}

                        {/*</Col>*/}
                    </Row>
                }

            </Col>
        </div>
    )
}

export default WizardAdTarget 