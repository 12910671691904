// Hand in as array for spacing
const tutorialText = {
    HOME: ["Hello and welcome to this exclusive behind-the-scenes look at Klaxon's proof-of-concept website.",
        "We hope to give you a glimpse of the incredible potential of this game-changing media platform.",
        "If you're reading this, it is because you've received an invite from the Klaxon team. You are among a carefully selected group of friends and experts.",
        "We invite you to follow our progress and help shape a new service that will transform how people create and share news and information important to them.",
        "Please remember that this website is just designed to give you a flavour of what to expect. It is not a fully functional site, and you may encounter some bugs and rough edges.",
        "As you read this, the Klaxon team is working behind the scenes on the first public-facing, fully operational platform.",
        "Meanwhile, we hope this proof-of-concept website not only whets your appetite for what is to follow but also encourages you to provide us with your feedback and suggestions.",
        "Please go ahead and sign in."],

    DASHBOARD: ["This is the Dashboard.",
        "From here, users can see an overview of their subscribers, listening statistics, and advertising revenue.",
        "The Dashboard will also provide important and useful information to make creating new podcasts as straightforward and rewarding as possible."],

    ADS: ["This is the Ads page.",
        "Here you can see how much revenue has been generated from adverts in your podcasts, and even choose to create your own adverts in the Klaxon Audience Network advertising system to appear in other podcasts."],

    PODCASTS: ["This is the Podcasts page.",
        "This is where users will see a list of their active podcasts.",
        "That's right - why have just one podcast when you can have multiple podcasts about multiple topics?"],

    CREATE_PODCAST: ["If this is the user's first time, they will have the opportunity to watch a how-to video at this point.",
        "This is then where the user enters the details of the podcast they would like to create.",
        "This part of the proof-of-concept project is operational, so go ahead and enter some text here. Nothing will be made public at this stage, so don't worry about typos or grammar!"]
}

export default tutorialText