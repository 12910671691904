import { Col, Row } from "reactstrap"

const HelpCheckout = () => {
    return (
        <Col>

            <Row>
                <Col>
                    <h3>
                        Overview
                    </h3>
                    <p>
                        Review your advertising campaign budget and any download fees and quickly and securely make your payment.
                    </p>
                    <p>
                        Your advert will start to be broadcast on the date and time you specified and will run until the end date you set if your budget allows. You'll be notified if your advertising budget is running low and can always top it up if you wish in the Ads section of the site.
                    </p>
                    <p>
                        And you can also listen to and see stats for your adverts at all times in the Ads section. The adverts you want to download will also be available there.
                    </p>
                </Col>
            </Row>

            <Row>
                <Col>
                    <h3>Questions? Email us at <a style={{ textDecoration: "underline", color: "",}} href="mailto:hello@klaxon.ai">hello@klaxon.ai</a></h3>
                </Col>
            </Row>


        </Col>
    )
}

export default HelpCheckout