import React, { useEffect, useState, useRef } from "react";
import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import getJWTToken from "config/jwtToken";
import Swal from "sweetalert2";
import ReactBSAlert from "react-bootstrap-sweetalert"
import ReactPlayer from "react-player";
import MyModal from "components/Shared/MyModal";
import TutorialModal from "../../components/Shared/TutorialModal";
import tutorialText from "config/tutorialText";
import "../../assets/css/custom-css.css"
import CreateAd from "../../components/Ads/CreateAd";

// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    Row,
    Col,
    Table,
    UncontrolledTooltip,
    Input,
    Spinner

} from "reactstrap";

function Ads() {

    const modalWidth = window.innerWidth < 1050 ? "90%" : "1000px"

    const [searchResults, setSearchResults] = useState([])
    const [advertData, setAdvertData] = useState([])
    const [selectedAd, setSelectedAd] = useState([])
    const [modalOpen, setModalOpen] = useState(false);
    const [alert, setAlert] = useState(null)
    const [showTutorial, setShowTutorial] = useState(true);
    const [isLoading, setIsLoading] = useState(false)

    const [advertPreview, setAdvertPreview] = useState(null)
    const [selectedAdvertObject, setSelectedAdvertObject] = useState(null)

    const [isPlaying, setIsPlaying] = useState(false)
    const [duration, setDuration] = useState(null);
    const playerRef = useRef(null)
    const [currentAudioElapsedFraction, setCurrentAudioElapsedFraction] = useState(0.0)

    const [missingFields, setMissingFields] = useState([])

    const [currentPage, setCurrentPage] = useState(1)
    const [pageSize, setPageSize] = useState(10)
    const [totalPages, setTotalPages] = useState(0);
    const [paginatedData, setPaginatedData] = useState([])

    const hideAlert = () => {
        setAlert(null)
    }


    const [baseObject, setBaseObject] = useState({
        id: 0,
        clientId: 0,
        name: "",
        description: "",
        duration: "00:00:00",
        budget: 0,
        budgetRemaining: 0,
        cpmPre: 0,
        cpmMid: 0,
        cpmPost: 0,
        statusId: 0,
        quantityOfPlays: 0,
        mP3File: null,
        mP3FileUrl: null,
        topics: '',
        positions: '',
        fileType: ""
    })

    useEffect(() => {
        const getLoginType = localStorage.getItem("accountType")
        let tempFileType = Number(getLoginType) === 1 ? "mp3" : "wav"

        setBaseObject({ ...baseObject, fileType: tempFileType })

    }, [])

    const toggleModal = () => {
        setModalOpen(!modalOpen);
    };

    const handleSearchBar = (query) => {
        const filtered = advertData.filter((e) =>
            e.name.toLowerCase().includes(query.toLowerCase())
        );
        setSearchResults(filtered);
        setCurrentPage(1);
    };

    const handleEditAd = async (id) => {
        setMissingFields([])
        try {
            const resp = await apiCall(
                apiConfig.ADVERT.GET_SINGLE + `?id=${id}`,
                "get"
            );
            if (resp.status === 200) {
                setSelectedAd(resp.data)
                toggleModal()
            }

        } catch (error) {
            console.error(error.message);
            setIsLoading(false)
        }

    }


    const handleDeleteAdClicked = (id) => {
        setSelectedAd(advertData.find((ad) => ad.id === id))
        const fastData = advertData.find((ad) => ad.id === id)

        setAlert(
            <ReactBSAlert
                warning
                style={{ display: "block", marginTop: "-100px" }}
                title="Are you sure?"
                onConfirm={() => handleAdDelete(id)}
                onCancel={() => hideAlert()}
                confirmBtnBsStyle="info"
                cancelBtnBsStyle="danger"
                confirmBtnText="Yes, delete!"
                cancelBtnText="Cancel"
                showCancel
                btnSize=""
            >
                You are about to delete {fastData.name}.
            </ReactBSAlert>
        )
    }

    const handleAdDelete = async (id) => {
        try {
            const resp = await apiCall(
                apiConfig.ADVERT.DELETE + `?id=${id}`,
                "delete"
            );
            if (resp.status === 200) {
                Swal.fire({
                    position: "center",
                    icon: "success",
                    title: "Advert Deleted",
                    showConfirmButton: false,
                    timer: 2500,
                    customClass: {
                        container: 'custom-swal-container',
                        popup: 'custom-swal-popup',
                        title: 'custom-swal-title',
                        icon: 'custom-swal-icon',
                    },
                })
                hideAlert()
                getAds()
            } else {
                Swal.fire({
                    position: "center",
                    icon: "error",
                    title: "Something went wrong",
                    showConfirmButton: false,
                    timer: 2500,
                    customClass: {
                        container: 'custom-swal-container',
                        popup: 'custom-swal-popup',
                        title: 'custom-swal-title',
                        icon: 'custom-swal-icon',
                    },
                })
            }


        } catch (error) {
            console.error(error.message);
            hideAlert()
        }
    }

    const getAds = async () => {
        setIsLoading(true)

        const accountType = Number(localStorage.getItem("accountType"))
        const cId = Number(localStorage.getItem("cId"))
        const isB2BAccount = accountType === 1
        try {
            const resp = await apiCall(
                apiConfig.ADVERT.GET_ALL + `?clientId=${isB2BAccount ? cId : 0}`,
                "get"
            );
            if (resp.status === 200) {
                setIsLoading(false)
                //Show most recent first
                setAdvertData(resp.data.reverse())
                setSearchResults(resp.data)
            }

        } catch (error) {
            console.error(error.message);
            setIsLoading(false)
        }
    };

    const [languages, setLanguages] = useState(false)
    const getLanguages = async () => {
        try {
            const resp = await apiCall(
                apiConfig.VOICE.GET_LANGUAGES,
                "get"
            );
            if (resp.status === 200) {
                const formattedLang = resp.data.map((lang) => {
                    return ({
                        value: lang.id,
                        label: lang.name
                    })
                })
                setLanguages(formattedLang)
            }

        } catch (error) {
            console.error(error.message);
        }
    }

    const createEditAdvert = async () => {
        if (!handleValidSubmit()) {
            return
        }

        if (selectedAd.id === 0) {

            const accountType = Number(localStorage.getItem("accountType"))
            const cId = Number(localStorage.getItem("cId"))
            const isB2BAccount = accountType === 1

            if (isB2BAccount) {
                selectedAd.clientId = cId
            }

            try {
                const resp = await apiCall(
                    apiConfig.ADVERT.ADD,
                    "post", selectedAd
                );
                if (resp.status === 200) {
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Advert created",
                        showConfirmButton: false,
                        timer: 2500,
                        customClass: {
                            container: 'custom-swal-container',
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            icon: 'custom-swal-icon',
                        },
                    })
                    toggleModal()
                    getAds()
                } else {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Something went wrong",
                        showConfirmButton: false,
                        timer: 2500,
                        customClass: {
                            container: 'custom-swal-container',
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            icon: 'custom-swal-icon',
                        },
                    })
                }


            } catch (error) {
                console.error(error.message);
            }
        } else {
            try {
                const resp = await apiCall(
                    apiConfig.ADVERT.UPDATE + `?id=${selectedAd.id}`,
                    "put", selectedAd
                );
                if (resp.status === 200) {
                    Swal.fire({
                        position: "center",
                        icon: "success",
                        title: "Advert Updated",
                        showConfirmButton: false,
                        timer: 2500,
                        customClass: {
                            container: 'custom-swal-container',
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            icon: 'custom-swal-icon',
                        },
                    })
                    getAds()
                    toggleModal()
                } else {
                    Swal.fire({
                        position: "center",
                        icon: "error",
                        title: "Something went wrong",
                        showConfirmButton: false,
                        timer: 2500,
                        customClass: {
                            container: 'custom-swal-container',
                            popup: 'custom-swal-popup',
                            title: 'custom-swal-title',
                            icon: 'custom-swal-icon',
                        },
                    })
                }


            } catch (error) {
                console.error(error.message);
            }
        }
    };

    const formatCurrency = (value) => {
        if (value !== undefined && value !== null) {
            const formattedValue = value.toLocaleString('en-GB', {
                style: 'currency',
                currency: 'GBP'
            });

            return formattedValue;
        }
        return null;
    }

    const handleTimes = (e) => {
        if (duration) {
            setCurrentAudioElapsedFraction(e.playedSeconds / duration);

            if (e.playedSeconds >= duration) {
                setSelectedAdvertObject(null);
                setAdvertPreview(null);
                setIsPlaying(false);
            }
        }
    }

    const handleSelectedAdPlayPause = () => {
        setIsPlaying(!isPlaying)
    }

    const handleSelectedAdStop = () => {
        setSelectedAdvertObject(null)
        setAdvertPreview(null)
        setIsPlaying(false)
    }

    const handleSeek = (e) => {
        playerRef.current.seekTo(e.target.value)
    }

    const handleValidSubmit = () => {
        const missingInputs = []
        if (selectedAd.name === "") missingInputs.push("name")

        if (selectedAd.mP3File === null) {
            if (selectedAd.mP3FileUrl === null) missingInputs.push("mp3")
        }

        if (selectedAd.budget === 0) missingInputs.push("budget")

        if (selectedAd.cpmPre === 0 && selectedAd.cpmMid === 0 && selectedAd.cpmPost === 0) missingInputs.push("cpm")

        if (missingInputs.length > 0) {
            setMissingFields(missingInputs)
            return false
        }
        setMissingFields([])
        return true
    }

    const userToken = getJWTToken().decoded.nameid
    const superUser = [1, 2, 3, 4, 6]
    const isSuperUser = superUser.includes(Number(userToken))

    useEffect(() => {
        getAds();
        getLanguages()
    }, []);

    window.addEventListener("clientChanged", getAds)

    const dataToPaginate = searchResults.length > 0 ? searchResults : advertData

    useEffect(() => {
        const total = Math.ceil(dataToPaginate.length / pageSize)
        setTotalPages(total)

        const paginatedSlice = dataToPaginate.slice(
            (currentPage - 1) * pageSize,
            currentPage * pageSize
        );
        setPaginatedData(paginatedSlice)
    }, [dataToPaginate, currentPage, pageSize])


    const MAX_VISIBLE_PAGES = 8;

    const getPageNumbers = () => {
        const totalPages = Math.ceil(advertData.length / pageSize)
        const pageNumbers = []

        if (totalPages <= MAX_VISIBLE_PAGES) {
            for (let i = 1; i <= totalPages; i++) {
                pageNumbers.push(i);
            }
        } else {
            for (let i = 1; i <= 3; i++) {
                pageNumbers.push(i)
            }

            if (currentPage > 4) {
                pageNumbers.push("...")
            }

            const startPage = Math.max(4, currentPage - 1)
            const endPage = Math.min(totalPages - 1, currentPage + 1)

            for (let i = startPage; i <= endPage; i++) {
                pageNumbers.push(i)
            }

            if (endPage < totalPages - 1) {
                pageNumbers.push("...")
            }

            if (totalPages > 3) {
                pageNumbers.push(totalPages)
            }
        }

        return [...new Set(pageNumbers)]
    };

    return (
        <>
            {
                isSuperUser ? (
                    <>
                        {alert}
                        < TutorialModal
                            showTutorial={showTutorial}
                            pageName='ads'
                            title="Ads"
                            text={tutorialText.ADS}
                            setShowTutorial={setShowTutorial} />
                        <MyModal isOpen={modalOpen} toggle={toggleModal} title="Advert details" width={modalWidth} buttonAction={createEditAdvert}>
                            <CreateAd baseObject={selectedAd} setBaseObject={setSelectedAd} missingFields={missingFields} languages={languages} />
                        </MyModal>
                        <div className="content">

                            <Row>
                                <Col md="12">
                                    <ReactPlayer url={advertPreview}
                                        playing={isPlaying}
                                        controls={false}
                                        ref={playerRef}
                                        onProgress={(e) => handleTimes(e)}
                                        onDuration={(d) => setDuration(d)}
                                        height='0px'
                                        width='0px'
                                        className="podcastPlayer"
                                        config={{ file: { attributes: { controlsList: 'nodownload' } } }}
                                    />
                                    <Card className="formStyle text-white">
                                        <CardHeader>
                                            <Col>
                                                <Row className="">
                                                    <CardTitle tag="h4">Ads</CardTitle>
                                                    {/*<button className="buttonStyle buttonPrimary" onClick={handleCreateAd}>Create Ad</button>*/}
                                                </Row>
                                                <Row sm="1" md="2" lg="4"><Input className="inputStyle placeholderWhite" type="text" placeholder="Search" onChange={(e) => handleSearchBar(e.target.value)} /></Row>
                                            </Col>
                                        </CardHeader>
                                        <CardBody>
                                            <div className="tableOverflow">
                                                <Table className="table">
                                                    <thead className="text-primary">
                                                        <tr style={{ color: "grey" }}>
                                                            <th>Name</th>
                                                            <th>Budget</th>
                                                            <th>Budget used</th>
                                                            <th>Plays</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {!isLoading ?
                                                            paginatedData.length > 0 ?
                                                                paginatedData.map((e) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td>{e.name}</td>
                                                                                <td>{formatCurrency(e.budget)}</td>
                                                                                <td>{formatCurrency(e.budgetUsed)}</td>
                                                                                <td>{e.advertPlays}</td>
                                                                                <td className="text-right">
                                                                                    <Button
                                                                                        className="btn-icon btn-neutral dropdownIgnore editButton"
                                                                                        style={{ color: "" }}
                                                                                        id="tooltip159182735"
                                                                                        size="sm"
                                                                                        type="button"
                                                                                        onClick={() => handleEditAd(e.id)}
                                                                                    >
                                                                                        <i className="fa fa-play" style={{ fontSize: 18 }} />
                                                                                    </Button>
                                                                                    <UncontrolledTooltip
                                                                                        delay={0}
                                                                                        target="tooltip159182735"
                                                                                        style={{ backgroundColor: "#F33B30", color: "white" }}
                                                                                    >
                                                                                        Play
                                                                                    </UncontrolledTooltip>
                                                                                    <Button
                                                                                        className="btn-icon btn-neutral dropdownIgnore"
                                                                                        style={{ color: "#f33b30" }}
                                                                                        id="tooltip808966390"
                                                                                        size="sm"
                                                                                        type="button"
                                                                                        onClick={() => handleDeleteAdClicked(e.id)}
                                                                                    >
                                                                                        <i className="fa fa-times" style={{ fontSize: 18 }} />
                                                                                    </Button>
                                                                                    <UncontrolledTooltip
                                                                                        delay={0}
                                                                                        target="tooltip808966390"
                                                                                        style={{ backgroundColor: "#F33B30", color: "white" }}
                                                                                    >
                                                                                        Delete
                                                                                    </UncontrolledTooltip>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                })
                                                                : <tr>
                                                                    <td colSpan="8">
                                                                        <div style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}>
                                                                            <p>No adverts available</p>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            : <tr>
                                                                <td colSpan="8">
                                                                    <div style={{ display: "flex", justifyContent: "center", marginTop: "3%" }}>
                                                                        <Spinner color="danger" />
                                                                    </div>
                                                                </td>
                                                            </tr>}

                                                    </tbody>
                                                </Table>
                                                <div style={{ display: "flex", backgroundColor: "", justifyContent: "center", alignItems: "center", gap: 25 }}>
                                                    <div style={{ display: "flex", gap: 25 }}>
                                                        <button
                                                            onClick={() => setCurrentPage(1)}
                                                            disabled={currentPage === 1}
                                                            className="buttonStyle buttonPrimary"
                                                            style={{ visibility: currentPage === 1 && "hidden" }}
                                                        >
                                                            First
                                                        </button>
                                                        <button
                                                            onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                                            disabled={currentPage === 1}
                                                            className="buttonStyle buttonPrimary"
                                                            style={{ visibility: currentPage === 1 && "hidden" }}
                                                        >
                                                            Previous
                                                        </button>
                                                    </div>
                                                    <div style={{ display: "flex", gap: 25 }}>
                                                        {getPageNumbers().map((number) => (
                                                            <button
                                                                key={number}
                                                                onClick={() => {
                                                                    if (number === "...") {
                                                                        // Handle logic to move to the next range of pages if necessary
                                                                        setCurrentPage(Math.min(currentPage + 4, totalPages)); // Adjust as needed
                                                                    } else {
                                                                        setCurrentPage(number);
                                                                    }
                                                                }}
                                                                style={{
                                                                    border: "none",
                                                                    minWidth: 25,
                                                                    minHeight: 25,
                                                                    borderRadius: "50%",
                                                                    backgroundColor: currentPage === number ? "#F33B30" : "transparent",
                                                                    color: ""
                                                                }}
                                                                className="labelText"
                                                                disabled={number === "..."} // Disable the ellipsis button
                                                            >
                                                                {number}
                                                            </button>
                                                        ))}
                                                    </div>
                                                    <div style={{ display: "flex", gap: 25 }}>
                                                        <button
                                                            onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                                            disabled={currentPage === totalPages}
                                                            className="buttonStyle buttonPrimary"
                                                            style={{ visibility: currentPage === totalPages && "hidden" }}
                                                        >
                                                            Next
                                                        </button>
                                                        <button
                                                            onClick={() => setCurrentPage(totalPages)}
                                                            className="buttonStyle buttonPrimary"
                                                            style={{ visibility: currentPage === totalPages && "hidden" }}
                                                        >
                                                            Last
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </div>
                    </>
                ) : (
                    <div className="content">
                        <h2>You do not have permission to view this page</h2>
                    </div>
                )}</>
    );
}

export default Ads;