import { Row, Col, FormGroup, Input, Label, Spinner } from "reactstrap"
import { useEffect, useState } from "react"
import GeneratedAd from "../AdWizardComps/GeneratedAd"
import { FileUploader } from "react-drag-drop-files";
import Select from "react-select";
import WizardPlayer from "../AdWizardComps/audioEditorComp/WizardPlayer";

const WizardAdCreate = (props) => {
    const [isExactText, setIsExactText] = useState(false)
    const [selectedAd, setSelectedAd] = useState(0)
    const [isUploadingNew, setIsUploadingNew] = useState(false)

    const fileTypes = ["MP3", "WAVE", "OGG"]
    const [file, setFile] = useState(null)
    const handleFileChange = (uploadedFile) => {
        const reader = new FileReader();

        reader.onloadend = () => {
            const base64String = reader.result.split(',')[1]
            setFile(base64String);
            props.setBaseObject({ ...props.baseObject, uploadedBase64: base64String })
        };

        if (uploadedFile) {
            reader.readAsDataURL(uploadedFile); // Convert the file to base64
        }
    };

    const [singeSelectDuration, setSingleSelectDuration] = useState({ label: "30 seconds", id: 6 })
    const [durations, setDurations] = useState()
    const handleDurationChange = (value) => {
        setSingleSelectDuration(value)
        props.setBaseObject({ ...props.baseObject, length: value.id })
    }

    const handleSelectAd = (id) => {
        setSelectedAd(id)
        props.setBaseObject({ ...props.baseObject, selectedContent: id })
    }

    const generateDurations = () => {
        const options = [];

        options.push(
            { label: "30 seconds", id: 6 }
        )
        setDurations(options);
    };

    useEffect(() => {
        generateDurations()
    }, [])

    useEffect(() => {
        if (props.baseObject?.content && selectedAd === 0) {
            setSelectedAd(props.baseObject.content[0].id)
        }
    }, [props.baseObject?.content])

    const handleSetGeneration = (isExact) => {
        setIsExactText(isExact)
        props.setBaseObject({ ...props.baseObject, isExactScript: isExact })
    }

    useEffect(() => {
        if (!props.isGenerate) {
            if (file) {
                props.setIsOwnAdvert(true)
            } else {
                props.setIsOwnAdvert(false)
            }
        } else {
            props.setIsOwnAdvert(false)
        }
    }, [props.isGenerate, file])

    useEffect(() => {
        if (props.baseObject.uploadedBase64 != null) {
            setFile(props.baseObject.uploadedBase64)
        }
    }, [props.baseObject])


    useEffect(() => {
        if (isExactText) {
            props.setBaseObject({ ...props.baseObject, script: props.exactScript });
            props.setScript(props.exactScript)
        } else {
            props.setBaseObject({ ...props.baseObject, script: props.nonExactScript });
            props.setScript(props.nonExactScript)
        }
    }, [isExactText]);

    const [playingAudioId, setPlayingAudioId] = useState(null)


    const handleInputChange = (e, wordLimit) => {
        const wordCount = e.target.value.trim().split(/\s+/).length;

        if (wordCount <= wordLimit) {
            // Adjust textarea height dynamically
            e.target.style.height = "auto";
            e.target.style.height = `${e.target.scrollHeight}px`;
            e.target.style.maxHeight = "200px";
            e.target.style.overflowY = "auto";
            e.target.style.setProperty("border", "1px solid transparent", "important");

            const updatedScript = e.target.value;
            props.setScript(updatedScript); // Update local state

            if (isExactText)
                props.setExactScript(updatedScript)
            else
                props.setNonExactScript(updatedScript)

            props.baseObject.script = updatedScript; // Update the prop
        } else {
            e.target.style.setProperty("border", "1px solid #F33B30", "important");
        }
    };

    return (
        <div className="pb-3">
            <Col>
                <Row className="justify-content-between mt-4">
                    <Col style={{ borderRadius: 7, padding: 7, cursor: "pointer", marginLeft: 5, marginRight: 5, minHeight: 85, display: "flex", justifyContent: "center", flexDirection: "column", backgroundColor: props.isGenerate && "rgba(243,59,48)" }} className={` borderLight`} sm="" onClick={() => props.setIsGenerate(true)}>
                        <p style={{ margin: 0, marginLeft: 5 }}>Create Advert</p>
                        <p style={{ margin: 0, marginLeft: 5 }}>Let our AI create an ad for you or enter your own advert script.</p>
                    </Col>
                    <Col style={{ borderRadius: 7, padding: 7, cursor: "pointer", marginLeft: 5, marginRight: 5, minHeight: 85, display: "flex", justifyContent: "center", flexDirection: "column", backgroundColor: !props.isGenerate && "rgba(243,59,48)" }} className={` borderLight`} sm="" onClick={() => props.setIsGenerate(false)}>
                        <p style={{ margin: 0, marginLeft: 5 }}>Upload Advert</p>
                        <p style={{ margin: 0, marginLeft: 5 }}>Upload an existing audio advert.</p>
                    </Col>
                </Row>
                <Row className="mt-4">
                    {props.isGenerate ? <div style={{ width: "100%" }}>

                        Create Advert

                        <Row>
                            {/*<Col sm="6">*/}
                            {/*    <FormGroup className="">*/}
                            {/*        <Label className="labelText">Length</Label>*/}

                            {/*        <Select*/}
                            {/*            className="react-select "*/}
                            {/*            classNamePrefix="react-select"*/}
                            {/*            name="singleSelect"*/}
                            {/*            value={singeSelectDuration}*/}
                            {/*            onChange={(value) => handleDurationChange(value)}*/}
                            {/*            options={durations}*/}

                            {/*        />*/}

                            {/*    </FormGroup>*/}
                            {/*</Col>*/}
                        </Row>
                        <div style={{ display: "flex", gap: 15 }} className="mt-2">
                            <FormGroup check>
                                <Label check className="labelText">
                                    <Input type="checkbox" checked={!isExactText} onClick={() => handleSetGeneration(false)} />
                                    <span className="form-check-sign" />
                                    AI script generation
                                </Label>
                            </FormGroup>
                            <FormGroup check>
                                <Label check className="labelText">
                                    <Input type="checkbox" checked={isExactText} onClick={() => handleSetGeneration(true)} />
                                    <span className="form-check-sign" />
                                    Write own script
                                </Label>
                            </FormGroup>
                        </div>

                        <Row>
                            <Col sm="6" style={{ paddingRight: 15 }}>
                                {!isExactText ? (
                                    <>
                                        <Input
                                            className="inputStyle placeholderWhite reviewInput"
                                            placeholder={
                                                "Enter words or phrases about what you want to advertise, along with your business name and contact details, to enable our AI to create your advert script for you."
                                            }
                                            type="textarea"
                                            value={props.script}
                                            onChange={(e) => handleInputChange(e, 250)} // Word limit for AI generation
                                            style={{
                                                minHeight: 100,
                                                padding: 10
                                            }}
                                            onBlur={(e) =>
                                                e.target.style.setProperty(
                                                    "border",
                                                    "1px solid transparent",
                                                    "important"
                                                )
                                            }
                                        />
                                        <p style={{ margin: 0, textAlign: "right" }}>
                                            {props.script ? props.script.trim().split(/\s+/).length : 0}/250 words
                                        </p>
                                    </>
                                ) : (
                                    <>
                                        <Input
                                            className="inputStyle placeholderWhite reviewInput"
                                            placeholder={
                                                "Enter the exact wording you want used in your advert. 75 words maximum."
                                            }
                                            type="textarea"
                                            value={props.script}
                                            onChange={(e) => handleInputChange(e, 75)} // Word limit for exact script
                                            style={{
                                                minHeight: 100,
                                                padding: 10
                                            }}
                                            onBlur={(e) =>
                                                e.target.style.setProperty(
                                                    "border",
                                                    "1px solid transparent",
                                                    "important"
                                                )
                                            }
                                        />
                                        <p style={{ margin: 0, textAlign: "right" }}>
                                            {props.script ? props.script.trim().split(/\s+/).length : 0}/75 words
                                        </p>
                                    </>
                                )}
                            </Col>
                        </Row>
                        <Row>
                            <Col sm="6">
                                {
                                    props.isGenerating ?
                                        <button type="submit" disabled style={{ minWidth: "100%", backgroundColor: "#F33B30", minHeight: 40, maxHeight: 40, border: "none", boxShadow: "none", borderRadius: 5, color: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", gap: 10 }}><i className="fa fa-list-alt" />
                                            Create {props.baseObject.id !== 0 && "NEW"} Advert (1 credit) <Spinner size="sm" />
                                        </button>
                                        :
                                        <button onClick={() => props.handleAddAdvert()} type="submit" style={{ minWidth: "100%", backgroundColor: "#F33B30", minHeight: 40, maxHeight: 40, border: "none", boxShadow: "none", borderRadius: 5, color: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", gap: 10 }}><i className="fa fa-list-alt" />
                                            Create {props.baseObject.id !== 0 && "NEW"} Advert (1 credit)
                                        </button>
                                }
                                {
                                    props.isGeneratingError &&
                                    <div>
                                        <span style={{ color: "#F33B30", fontWeight: "bold" }}>Something went wrong, please try again later</span>
                                    </div>
                                }
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            {props.baseObject?.content && props.baseObject?.content.length > 0 &&
                                <Col>
                                    <div className="mt-2 mb-2">
                                        <p>
                                            Your advert has been created!
                                        </p>
                                        <p style={{ margin: 0 }}>
                                            Choose from 1 of the 3 versions below or change the voice and background music and regenerate any of them. Want to change the words?
                                        </p>
                                        <p>
                                            Simply change the details in the text box above and click the 'Create Advert' button again.
                                        </p>
                                    </div>
                                    {!props.baseObject?.content ?
                                        <div style={{ width: "100%", backgroundColor: "#F33B30", border: "none", boxShadow: "none", borderRadius: 5, color: "#FFF", padding: 10, margin: "auto", display: "flex", flexDirection: "column", gap: 10 }}>
                                            <div style={{ display: "flex", gap: 15, alignItems: "center" }}>
                                                <i className="fa fa-info-circle" />
                                                Your Generated Ads
                                            </div>
                                            <div>Your Klaxon.AI ads will appear here once you have generated them.</div>
                                        </div>
                                        :
                                        <div>
                                            {props.baseObject?.content && props.baseObject?.content.length > 0 &&
                                                props.baseObject?.content.map((ad, index) => {
                                                    return (
                                                        <Row key={index} className="mb-4">

                                                            <Col>
                                                                <Label className="labelText">Option {index + 1}</Label>
                                                                <Col sm="">
                                                                    <GeneratedAd advert={ad} setModalPage={props.setModalPage} regenerateContent={props.regenerateContent} handlePreviewVoice={props.handlePreviewVoice} voices={props.voices} music={props.music} singleLoadId={props.singleLoadId} loadingAudio={props.loadingAudio} playingAudioId={playingAudioId} setPlayingAudioId={setPlayingAudioId} audioId={index} />

                                                                </Col>
                                                                <Col className="" style={{ display: "flex", justifyContent: "center" }}>
                                                                    {/*<FormGroup check>*/}
                                                                    {/*    <Label check >*/}
                                                                    {/*        <Input type="checkbox" checked={selectedAd === ad.id} onClick={() => handleSelectAd(ad.id)} />*/}
                                                                    {/*        <span className="form-check-sign" />*/}
                                                                    {/*    </Label>*/}
                                                                    {/*</FormGroup>*/}
                                                                    <button type="submit" style={{ position: "relative", minWidth: "50%", backgroundColor: "#F33B30", minHeight: 40, maxHeight: 40, border: "none", boxShadow: "none", borderRadius: 5, color: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", gap: 10, cursor: "pointer" }}
                                                                        onClick={() => handleSelectAd(ad.id)}
                                                                    >
                                                                        {selectedAd === ad.id ? "Advert selected" : "Select this advert"}
                                                                        {selectedAd === ad.id &&
                                                                            <div style={{ position: "absolute", right: 20 }}>
                                                                                <i className="fa fa-check" />
                                                                            </div>
                                                                        }
                                                                    </button>
                                                                </Col>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                            }
                                        </div>
                                    }
                                </Col>
                            }

                        </Row>

                    </div>
                        :
                        <div style={{ width: "100%" }}>
                            {!file ?
                                <Col>
                                    <div>Upload Advert</div>
                                    <div style={{ backgroundColor: "" }}>
                                        <FileUploader handleChange={handleFileChange} name="file" types={fileTypes} classes="wideUpload klaxonColorUpload" label="Click to upload or drag and drop" />
                                    </div>
                                </Col> :
                                <Col className="mt-4 mb-4 pb-4">
                                    <div>Preview Advert</div>
                                    <div>
                                        <div className="pb-4">
                                            <WizardPlayer fileURL={file} />
                                        </div>
                                        <div>
                                            {!isUploadingNew ?
                                                <button onClick={() => setIsUploadingNew(true)} type="submit" style={{ backgroundColor: "#F33B30", minHeight: 40, maxHeight: 40, border: "none", boxShadow: "none", borderRadius: 5, color: "#FFF", display: "flex", justifyContent: "center", alignItems: "center", gap: 10 }}>
                                                    Upload a different advert
                                                </button>
                                                :
                                                <FileUploader handleChange={handleFileChange} name="file" types={fileTypes} classes="wideUpload klaxonColorUpload" label="Upload a different advert" />
                                            }

                                        </div>
                                    </div>
                                </Col>
                            }

                        </div>
                    }

                </Row>


            </Col>
        </div>
    )

}

export default WizardAdCreate 