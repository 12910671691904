import { jwtDecode } from "jwt-decode";
import Cookies from "js-cookie";

const getJWTToken = () => {
    let obj;
    const cookie = Cookies.get("userData");

    const token =
        cookie != undefined
            ? JSON.parse(Cookies.get("userData"))?.token
            : null;

    const decodedtoken =
        cookie != undefined
            ? jwtDecode(token)
            : null;

    obj = {
        decoded: decodedtoken,
        token: token
    }
    return obj;
}

export default getJWTToken;
