/*!

=========================================================
* Paper Dashboard PRO React - v1.3.2
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-dashboard-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { Route, Routes } from "react-router-dom";

import AuthNavbar from "components/Navbars/AuthNavbar.js";
import Footer from "components/Footer/Footer.js";

import routes from "routes.js";

var ps;

function Pages() {
  const fullPages = React.useRef();
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(fullPages.current);
    }
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  });
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.collapse) {
        return getRoutes(prop.views);
      }
      if (prop.layout === "/auth") {
        return (
          <Route path={prop.path} element={prop.component} key={key} exact />
        );
      } else {
        return null;
      }
    });
  };
  return (
    <>
      
      <div className="wrapper wrapper-full-page" ref={fullPages}>
              <div className="" style={{
                  backgroundSize: "cover",
                  position: "relative",
                  width: "100%",
                  backgroundPosition: "center center",
                  //zIndex: 2,
                  height: "100%",
                  minHeight: "100vh",
                  backgroundColor: "rgba(50, 50, 50, 0)",
                  //justifyContent: "center",
                  alignItems: "center",
                  display: "flex"
              }}>
          <Routes>{getRoutes(routes)}</Routes>
        </div>
      </div>
    </>
  );
}

export default Pages;
