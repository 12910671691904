import React, { useState } from 'react'; // Don't forget to import useState
import { useFormik } from 'formik';
import apiConfig from "config/apiConfig";
import * as Yup from 'yup';
import "assets/css/custom-css.css"
import klaxonLogoNew from "assets/img/klaxonImages/klaxonFull.png"
import axios from "axios"

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardTitle,
    Row,
    Col,
    Table,
    UncontrolledTooltip,
    Input

} from "reactstrap";

const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
    message: Yup.string().min(20, 'Message must be 20 or more characters'),
});

function ContactForm() {
    const [messageSent, setMessageSent] = useState(false);
    const [sendError, setSendError] = useState(false);

    // Move the MyForm component outside of the Contact function
    const MyForm = () => {
        const formik = useFormik({
            initialValues: {
                name: '',
                email: '',
                message: '',
            },
            validationSchema: validationSchema,
            onSubmit: async values => { // Add async keyword to onSubmit function
                try {
                    const contactUs = {
                        name: values.name,
                        email: values.email,
                        message: values.message
                    };

                    let url = `${apiConfig.baseUrl}${apiConfig.HOME.CONTACT_US}`;
                    await axios
                        .post(url, contactUs)
                        .then((res) => {
                            if (res.status === 200) {
                                setMessageSent(true)
                                setSendError(false)
                            }

                        })
                        
                } catch (err) {
                    console.error(err)
                    setSendError(true); // Handle fetch errors by setting error state
                    setMessageSent(false); // Reset the success state
                }
            },
        });

        return (
            <form onSubmit={formik.handleSubmit}>
                <div>
                    <label htmlFor="name" className="text-white">Name</label>
                    <input
                        type="text"
                        id="name"
                        name="name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.name}
                        placeholder="Name"
                        className="inputStyle placeholderWhite"
                        style={{borderRadius: 5}}
                    />
                    {formik.touched.name && formik.errors.name ? (
                        <div className="contact-error">{formik.errors.name}</div>
                    ) : null}
                </div>

                <div>
                    <label htmlFor="email" className="text-white">Email</label>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.email}
                        placeholder="Email"
                        className="inputStyle placeholderWhite"
                        style={{ borderRadius: 5 }}
                    />
                    {formik.touched.email && formik.errors.email ? (
                        <div className="contact-error">{formik.errors.email}</div>
                    ) : null}
                </div>

                <div>
                    <label htmlFor="message" className="text-white">Message</label>
                    <textarea
                        id="message"
                        name="message"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.message}
                        placeholder="Message"
                        rows={6}
                        className="inputStyle placeholderWhite"
                        style={{ borderRadius: 5 }}
                    />
                    {formik.touched.message && formik.errors.message ? (
                        <div className="contact-error">{formik.errors.message}</div>
                    ) : null}
                </div>
                <center>
                    <button className="buttonStyle buttonPrimary mb-2 mt-2" type="submit">Send</button>
                    <p className="formTerms text-white mt-2">By submitting this form, you agree to our <a style={{
                        color: "white",
                        textDecoration: "underline white",
                        cursor: "pointer"
                    }} href="/privacy">Privacy Policy</a>.</p>
                </center>
            </form>
        );
    };

    return (
        <>
            {!messageSent &&
                <Row className="contact-form formStyle" style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems:"center" }}>
                    {!messageSent && (
                        <>
                            <img className="mb-4 mt-2" src={klaxonLogoNew} style={{ width: "60%" }} />
                            <h3 class="readForm">Have <strong>your</strong> say!</h3>
                            <MyForm />
                        </>
                    )}
                    {sendError && (
                        <div className="d-flex justify-content-center align-items-center text-white text-center">
                            <h5 style={{ width: "90%", margin: 5 }}>There was an error sending your message. If this persists, please send an email to  <a style={{ textDecoration: "underline", color: "white" }} href="mailto:hello@klaxon.ai">hello@klaxon.ai</a></h5>
                        </div>
                    )}
                </Row>
            }
            {messageSent && (
                <div className="contacted d-flex align-items-center">
                    <h3 className="text-white">Thanks for contacting us. We will get back to you shortly.</h3>
                </div>
            )}
        </>
    );
}

export default ContactForm;

