import React, { useState, useEffect, useRef, useContext } from 'react';
import { FileUploader } from "react-drag-drop-files";

const UploadMusic = (props) => {
	const inputFile = useRef(null);
	const [file, setFile] = useState(null);

	useEffect(() => {
		if (file) {
			props.setFileURL(file);
		}
	}, [file, props.setFileURL, props.history]);

	const handleFileUpload = (e) => {
		setFile(URL.createObjectURL(e.target.files[0]));
	};

	const fileTypes = ["MP3", "WAV"]
	const handleFileChange = (uploadedFile) => {
		const previewURL = URL.createObjectURL(uploadedFile);
		props.setFileURL(previewURL);

		const reader = new FileReader();
		reader.onloadend = () => {
			const fileType = uploadedFile.name.split(".").pop();

			const base64String = reader.result.split(",")[1];
			props.setBaseObject({
				...props.baseObject,
				base64: base64String,
				fileType: fileType
			});
		};
		reader.readAsDataURL(uploadedFile);
	}

	useEffect(() => {
		if (props.baseObject?.url) {
			props.setFileURL(props.baseObject.url)
		}
	}, [props.baseObject])

	return (
		<div className='upload-audio'>
			
			<div style={{ backgroundColor: "" }}>
				<FileUploader handleChange={handleFileChange} name="file" types={fileTypes} classes="wideUpload" label="Click to upload or drag and drop" />
			</div>
			<input
				type='file'
				id='file'
				ref={inputFile}
				style={{ display: 'none' }}
				accept='audio/*'
				onChange={handleFileUpload}
			/>
		</div>
	);
};

export default UploadMusic;