import React, { useEffect } from "react";
import AboutImage from "assets/img/monks.jpg";

// reactstrap components
import {
    CardBody,
    Row,
    Col,
} from "reactstrap";
function AboutUs() {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <>
            <div className="content" style={{ marginTop: 78, paddingLeft: "12%", paddingRight: "12%" }}>
                <Col>
                    <Row>
                        <Col md="12" className="text-white">
                            <Col>
                                <Row className="justify-content-center">
                                    <h3 className="text-center" style={{ color: "white", marginTop: 20 }}><b>About us</b></h3>
                                </Row>

                            </Col>

                            <CardBody>

                                <Row>

                                    <Col>
                                        <p>
                                            We love telling stories. And we love that technology makes
                                            it easier for everyone to tell stories.
                                        </p>
                                        <p>
                                            As former journalists our three co-founders are all too
                                            aware that in the past the ability to produce and
                                            disseminate news and information was in the hands of those
                                            with the skills and deep pockets to do so.
                                        </p>
                                        <p>
                                            Monks in the Middle Ages created a global
                                            multi-million-pound industry handwriting copies of the bible
                                            and manuscripts for the rich and powerful.
                                        </p>
                                        <p>
                                            They were replaced by the Gutenberg printing press which
                                            lasted a few hundred years until radio and television and
                                            the internet arrived last century.
                                        </p>
                                        <p>
                                            Now we have people producing video on their smartphones and
                                            reaching millions every day. But podcasting remains beyond
                                            the reach of most people. There are still huge barriers
                                            preventing most people around the world from creating their
                                            own podcasts – time, knowledge, skills and access to
                                            expensive recording equipment studios – are some.{" "}
                                        </p>
                                        <p>
                                            Language is another barrier. With 80% of the world not
                                            speaking English translating words, written or spoken, is a
                                            hugely costly hurdle to anyone trying to distribute news and
                                            information globally.
                                        </p>
                                        <p>
                                            We believe advances in artificial intelligence now mean the
                                            barriers are gone. And we are on a mission.
                                        </p>
                                        <p>
                                            A mission to democratise podcasting by making it accessible
                                            to anyone with access to the internet, anywhere in the
                                            world, while sharing the financial rewards with our users.
                                        </p>
                                    </Col>

                                    <Col
                                        md="6"
                                        style={{
                                            display: "flex",
                                            alignContent: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <img
                                            src={AboutImage}
                                            style={{
                                                width: "100%",
                                                height: "80%",
                                                objectFit: "cover",
                                            }}
                                        />
                                    </Col>

                                </Row>

                            </CardBody>


                        </Col>
                    </Row>
                </Col>
            </div>
        </>
    );
}

export default AboutUs;
