import React, { useState } from "react"
import {Row, Col, Button } from "reactstrap"
function PaymentStep3(props) {

    
    
    return (
        <Row>
            <Col className="text-center">
                <p className="text-white">Your purchase has been complete</p>
                <button className="buttonStyle buttonPrimary" onClick={props.toggle}>Let's explore</button>
            </Col>
        </Row>
    )
}

export default PaymentStep3