import React, { useState, useEffect } from "react";
import ReactWizard from "react-bootstrap-wizard";
import apiConfig from "config/apiConfig";
import apiCall from "utils/apiCall";
import { useNavigate } from "react-router-dom";
import TutorialModal from "../../components/Shared/TutorialModal";
import Swal from "sweetalert2";

import { Col, Row, Spinner } from "reactstrap";

import tutorialText from "../../config/tutorialText";
import getJWTToken from "../../config/jwtToken";
// import Step1 from "../../components/Wizards/WizardSteps/Step1";
// import Step2 from "../../components/Wizards/WizardSteps/Step2";
// import Step3 from "../../components/Wizards/WizardSteps/Step3";
// import Step4 from "../../components/Wizards/WizardSteps/Step4";
import BStep1 from "../../components/Wizards/BusinessWizardSteps/BStep1";
import BStep2 from "../../components/Wizards/BusinessWizardSteps/BStep2";
import BStep3 from "../../components/Wizards/BusinessWizardSteps/BStep3";
import BStep4 from "../../components/Wizards/BusinessWizardSteps/BStep4";
import BStep5 from "../../components/Wizards/BusinessWizardSteps/BStep5";

function CreatePodcast() {
  const navigation = useNavigate();
  const loginType = Number(localStorage.getItem("accountType"));

  const [dropdownFrequencys, setDropdownFrequencys] = useState([]);
  const [dropdownDurations, setDropdownDurations] = useState([]);
  const [dropdownReviewPeriods, setDropdownReviewPeriods] = useState([]);

  const [dropdownVoices, setDropdownVoices] = useState([]);
  const [dropdownLanguages, setDropdownLanguages] = useState([]);
  const [voicePreview, setVoicePreview] = useState([]);

  const [showTutorial, setShowTutorial] = useState(true);
  const [showVideoTutorial, setShowVideoTutorial] = useState(
    localStorage.getItem("videoTutorial") ? false : true
  );

  const [preparingPodcast, setPreparingPodcast] = useState(false);
  const [generationStep, setGenerationStep] = useState(1);

  //Titles
  const [titleNumber, setTitleNumber] = useState(0);

  //props for reactWizard = title = { titleList[titleNumber]}
  // Titles removed. Each step is always mounted, nor is the previous button actually accessible
  const [baseObject, setBaseObject] = useState({
    id: 0,
    userId: getJWTToken().decoded.nameid,
    startDateTime: new Date().toISOString("O"),
    name: "",
    description: "",
    image: "",
    frequencyId: 0,
    frequencyName: "",
    durationId: 0,
    durationName: "",
    reviewPeriodId: 0,
    reviewPeriodName: "None, just publish it",
    defaultVoiceId: 0,
    isPro: true,
    defaultLanguageId: 11,
    maxEpisodes: 0,
    topics: [],
    clientId: 0,
    includeAds: true,
    categories: "0",
  });

  const [bBaseObject, setBBaseObject] = useState({
    id: 0,
    userId: getJWTToken().decoded.nameid,
    startDateTime: new Date().toISOString("O"),
    name: "",
    description: "",
    image: "",
    frequencyId: 1,
    frequencyName: "Instant",
    durationId: 0,
    reviewPeriodId: 0,
    reviewPeriodName: "",
    durationName: "",
    defaultLanguageId: 11,
    defaultVoiceId: 0,
    currentEpisode: 1,
    maxEpisodes: 1,
    clientId: 0,
    includeAds: true,
    topics: [],
    categories: "0",
  });

  const [voicePreviewModel, setVoicePreviewModel] = useState({
    voiceId: null,
    voiceStyleId: null,
    languageId: null,
  });

  const [estimatedLength, setEstimatedLength] = useState(0);
  const getEstimatedPodcastLength = async (
    podcastSectionArray,
    advertDuration
  ) => {
    try {
      const resp = await apiCall(
        apiConfig.PODCAST_EPISODE.GET_ESTIMATED_PODCAST_LENGTH +
          `?voiceId=${bBaseObject.defaultVoiceId}&userId=${
            getJWTToken().decoded.nameid
          }`,
        "post",
        podcastSectionArray
      );
      setEstimatedLength(resp.data + advertDuration);
    } catch (error) {
      console.error(error.message);
    }
  };

  const [clientData, setClientData] = useState([]);
  const getClients = async () => {
    try {
      const resp = await apiCall(apiConfig.CLIENT.GET_ALL, "get");
      if (resp.status === 200) {
        const dataCopy = resp.data.map((e) => {
          return {
            value: e.id,
            label: e.name,
          };
        });
        setClientData(dataCopy);
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const [userGeneratedContent, setUserGeneratedContent] = useState(null);
  const [isScriptFailed, setIsScriptFailed] = useState(false);
  const generateScriptForUser = async (content) => {
    setIsScriptFailed(false);
    try {
      const resp = await apiCall(
        apiConfig.PODCAST_EPISODE.GENERATE_SCRIPTS +
          `?duration=${bBaseObject.durationId}&frequency=${bBaseObject.frequencyName}&title=${bBaseObject.name}&content=${content}`,
        "get"
      );
      setUserGeneratedContent(resp.data);
    } catch (error) {
      console.error(error.message);
      setIsScriptFailed(true);
    }
  };

  //b2b creation
  const handleCreatePodcast = async () => {
    try {
      const resp = await apiCall(apiConfig.PODCAST.CREATE, "post", bBaseObject);
      return resp.data;
    } catch (error) {
      console.error(error.message);
    }
  };

  const [previousGeneration, setPreviousGeneration] = useState(null);
  const handleBusinessEpisodeCreate = async (businessEpisode) => {
    try {
      const resp = await apiCall(
        apiConfig.PODCAST_EPISODE.ADD_BUSINESS_EPISODE,
        "post",
        businessEpisode
      );
      if (resp.status === 200) {
        setPreviousGeneration(resp.data);
        return resp.data;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error.message);
      return false;
    }
  };

  const businesssEpisodeSectionUpdate = async (businessEpisode) => {
    try {
      const resp = await apiCall(
        apiConfig.PODCAST_EPISODE.UPDATE_BUSINESS_EPISODE,
        "put",
        businessEpisode
      );
      if (resp.status === 200) {
        setPreviousGeneration(resp.data);
        return resp.data;
      } else {
        return false;
      }
    } catch (error) {
      console.error(error.message);
      return false;
    }
  };

  const [generationStatus, setGenerationStatus] = useState("");
  let generationTimeout;
  const handleBusinessEpisodeStatus = async (id) => {
    try {
      let resp;
      if (loginType === 0) {
        resp = await apiCall(
          apiConfig.PODCAST_EPISODE.CHECK_EPISODE_GENERATION_STATUS_B2C +
            `?id=${id}`,
          "get"
        );
      } else {
        resp = await apiCall(
          apiConfig.PODCAST_EPISODE.CHECK_EPISODE_GENERATION_STATUS +
            `?id=${id}`,
          "get"
        );
      }

      if (resp.status === 200 && resp.data === "Complete") {
        setGenerationStatus(resp.data);
        if (loginType === 0) {
          clearTimeout(generationTimeout);
          setGenerationStep(2);
          return;
        } else {
          clearTimeout(generationTimeout);
          setGenerationStatus("Preparing your download");
          setB2BDownloadLink(null);
          setB2BPreviewAudio(null);
          setTimeout(() => handleDownloadPodcast(id), 5000);
          return;
        }
      }
      generationTimeout = setTimeout(
        () => handleBusinessEpisodeStatus(id),
        5000
      );
    } catch (error) {
      clearTimeout(generationTimeout);
      return;
    }
  };

  const [frequencyBusiness, setFrequencyBusiness] = useState([]);
  const getDropdownFrequencys = async () => {
    try {
      const resp = await apiCall(apiConfig.PODCAST.GET_FREQUENCIES, "get");
      //Get rid of first option so they don't soft lock the modal on step 2
      setDropdownFrequencys(
        resp.data
          .filter((e) => e.id !== 1)
          .map((e) => ({
            value: e.id,
            label: e.name,
          }))
      );
      setFrequencyBusiness(
        resp.data.map((e) => ({
          value: e.id,
          label: e.name,
        }))
      );
    } catch (error) {
      console.error(error.message);
    }
  };

  const getDropdownDurations = async () => {
    try {
      const resp = await apiCall(apiConfig.PODCAST.GET_DURATIONS, "get");
      setDropdownDurations(
        resp.data.map((e) => ({
          value: e.id,
          label: e.name,
        }))
      );
    } catch (error) {
      console.error(error.message);
    }
  };

  const getDropdownReviewPeriods = async () => {
    try {
      const resp = await apiCall(apiConfig.PODCAST.GET_REVIEW_PERIODS, "get");
      setDropdownReviewPeriods(
        resp.data.map((e) => ({
          value: e.id,
          label: e.name,
        }))
      );
    } catch (error) {
      console.error(error.message);
    }
  };

  const getVoices = async () => {
    try {
      const resp = await apiCall(apiConfig.VOICE.GET_AZURE_VOICES, "get");
      setDropdownVoices(resp.data);
    } catch (error) {
      console.error(error.message);
    }
    setIsVoicesLoaded(true);
  };
  const [isVoicesLoaded, setIsVoicesLoaded] = useState(false);
  const [premiumVoices, setPremiumVoices] = useState([]);
  const getPremiumVoices = async () => {
    try {
      const resp = await apiCall(apiConfig.VOICE.GET_EL_VOICES, "get");
      if (resp.status === 200) {
        const updatedVoices = resp.data.map((voice) => {
          return {
            ...voice, // Copy all other properties
            voiceAiValue: voice.voicePreview, // Set voiceAiValue to the value of voicePreview
          };
        });
        setPremiumVoices(updatedVoices);
      }
    } catch (error) {
      console.error(error.message);
    }
    setIsVoicesLoaded(true);
  };

  const getLanguages = async () => {
    try {
      const resp = await apiCall(apiConfig.VOICE.GET_LANGUAGES, "get");

      setDropdownLanguages(
        resp.data.map((e) => ({
          value: e.id,
          label: e.name,
          aiValue: e.aiValue.substring(0, 2),
        }))
      );
    } catch (error) {
      console.error(error.message);
    }
  };

  const getPreviews = async () => {
    if (voicePreviewModel.languageId != null) {
      try {
        const resp = await apiCall(
          apiConfig.VOICE.GET_PREVIEW +
            `?voiceId=${voicePreviewModel.voiceId}&voiceStyleId=${voicePreviewModel.voiceStyleId}&languageId=${voicePreviewModel.languageId}`,
          "get"
        );
        setVoicePreview(resp.data);
      } catch (error) {
        console.error(error.message);
      }
    }
  };

  const createPodcast = async () => {
    setPreparingPodcast(true); // Disable buttons when user submits
    //+ `?userId=${getJWTToken().decoded.nameid}`
    try {
      const resp = await apiCall(apiConfig.PODCAST.CREATE, "post", baseObject);
      if (resp.status === 200) {
        Swal.fire({
          position: "top-end",
          icon: "success",
          title: "Podcast created successfully.",
          html: "<p>We will notifiy you via email when your podcast is ready</p>",
          showConfirmButton: true,
          timer: 5000,
          customClass: {
            container: "custom-swal-container",
            popup: "custom-swal-popup",
            title: "custom-swal-title",
            icon: "custom-swal-icon",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            navigation("/admin/podcasts");
          } else {
            navigation("/admin/podcasts");
          }
        });
      }
    } catch (error) {
      console.error(error.message);
      setPreparingPodcast(false); // Renable the buttons on fail
    }
  };

  const [adverts, setAdverts] = useState([]);
  const getAdverts = async () => {
    const accountType = Number(localStorage.getItem("accountType"));
    const cId = Number(localStorage.getItem("cId"));
    const isB2BAccount = accountType === 1;
    let clientId = 0;
    if (isB2BAccount) {
      clientId = cId;
    }

    try {
      const resp = await apiCall(
        apiConfig.ADVERT.GET_ALL + `?clientId=${clientId}`,
        "get"
      );
      setAdverts(
        resp.data.map((e) => {
          return {
            label: e.name,
            value: e.id,
            duration: e.duration,
          };
        })
      );
    } catch (error) {
      console.error(error.message);
    }
  };

  const [categories, setCategories] = useState([]);
  const getCategories = async () => {
    try {
      const resp = await apiCall(apiConfig.ADVERT.GET_CATEGORIES, "get");
      if (resp.status === 200) {
        setCategories(
          resp.data.map((cat) => {
            return {
              label: cat.name,
              value: cat.id,
            };
          })
        );
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const [B2BDownloadLink, setB2BDownloadLink] = useState(null);
  const handleDownloadPodcast = async (id) => {
    try {
      const resp = await apiCall(
        apiConfig.PODCAST_EPISODE.DOWNLOAD_BUSINESS_EPISODE + `?id=${id}`,
        "get"
      );
      setB2BDownloadLink(resp.data);
      handlePreviewPodcast(id);
    } catch (error) {
      setTimeout(() => handleDownloadPodcast(id), 2500);
      console.error(error.message);
    }
  };

  const handleDownloadClicked = (podcastName) => {
    try {
      if (/^[A-Za-z0-9+/]+={0,2}$/.test(B2BDownloadLink)) {
        const decodedData = atob(B2BDownloadLink);
        const dataArray = new Uint8Array(decodedData.length);

        for (let i = 0; i < decodedData.length; i++) {
          dataArray[i] = decodedData.charCodeAt(i);
        }

        const blob = new Blob([dataArray], { type: "audio/mpeg" });
        const link = document.createElement("a");

        link.href = window.URL.createObjectURL(blob);
        link.download = `${podcastName}`;
        link.click();
      } else {
        console.error("Invalid base64 string");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const [podcastDuration, setPodcastDuration] = useState(0);
  const getDuration = (audioObj) => {
    let durationObject = { durationSeconds: 0, durationTimeSpan: "00:00:00" };

    durationObject.durationSeconds = convertTimeSpan(audioObj.duration);
    durationObject.durationTimeSpan = formatDuration(
      durationObject.durationSeconds
    );
    setPodcastDuration(durationObject);
  };

  const formatDuration = (durationInSeconds) => {
    const hours = Math.floor(durationInSeconds / 3600);
    const minutes = Math.floor((durationInSeconds % 3600) / 60);
    const seconds = Math.floor(durationInSeconds % 60);

    const formattedHours = hours > 0 ? String(hours).padStart(2, "0") : "";
    const formattedMinutes = String(minutes).padStart(2, "0");
    const formattedSeconds = String(seconds).padStart(2, "0");

    const formattedTime = [formattedHours, formattedMinutes, formattedSeconds]
      .filter((value) => value !== "")
      .join(":");

    return formattedTime;
  };

  const convertTimeSpan = (timeString) => {
    const [timePart, milliseconds] = timeString.split(".");
    const [hours, minutes, seconds] = timePart.split(":").map(Number);

    let totalSeconds = hours * 3600 + minutes * 60 + seconds;

    if (milliseconds) {
      totalSeconds += parseFloat(`0.${milliseconds}`);
    }

    return totalSeconds;
  };

  const [B2BPreviewAudio, setB2BPreviewAudio] = useState(null);
  const [generationError, setGenerationError] = useState(false);
  let errorCount = 0;
  const handlePreviewPodcast = async (id) => {
    if (errorCount < 10) {
      try {
        const resp = await apiCall(
          apiConfig.PODCAST_EPISODE.GET_BUSINESS_EPISODE + `?id=${id}`,
          "get"
        );

        if (resp.data.duration.startsWith("00:00:00")) {
          errorCount++;
          setTimeout(() => handlePreviewPodcast(id), 5000);
          return;
        }
        setGenerationStatus("Available");
        setGenerationStep(2);
        setB2BPreviewAudio([resp.data]);
        getDuration(resp.data);
        return;
      } catch (error) {
        errorCount++;
        setTimeout(() => handlePreviewPodcast(id), 5000);
        console.error(error.message);
      }
    }

    if (errorCount >= 10) {
      setGenerationError(true);
      return;
    }
  };

  const handleHide = () => {
    localStorage.setItem("videoTutorial", "true");
    setShowVideoTutorial(false);
  };

  const handleShowAgain = () => {
    localStorage.removeItem("videoTutorial");
    setShowVideoTutorial(true);
  };

  useEffect(() => {
    //step 2
    getDropdownFrequencys();
    getDropdownDurations();
    getDropdownReviewPeriods();
    getClients();
    getCategories();
    //step 3
    if (loginType === 0) {
      getVoices();
    } else {
      getPremiumVoices();
    }
    getLanguages();
    getAdverts();
  }, []);

  useEffect(() => {
    getPreviews();
  }, [voicePreviewModel]);

  useEffect(() => {
    const newDateTime = new Date();
    newDateTime.setMinutes(newDateTime.getMinutes() + 10);

    setBaseObject({
      ...baseObject,
      startDateTime: newDateTime.toISOString("O"),
    });
  }, []);

  const handleNavigatePodcast = () => {
    navigation("/admin/podcasts");
  };

  const [isInputFull, setIsInputFull] = useState(false);

  // var steps = [
  //     {
  //         stepName: "Topics",
  //         stepIcon: "fa-solid fa-globe",
  //         component: Step1,
  //         stepProps: {
  //             baseObject: baseObject,
  //             setBaseObject: setBaseObject,
  //             titleNumber: titleNumber,
  //             setTitleNumber: setTitleNumber,
  //         }
  //     },
  //     {
  //         stepName: "Details",
  //         stepIcon: "fa-solid fa-circle-info",
  //         component: Step2,
  //         stepProps: {
  //             baseObject: baseObject,
  //             setBaseObject: setBaseObject,
  //             dropdownDurations: dropdownDurations,
  //             dropdownFrequencys: dropdownFrequencys,
  //             dropdownReviewPeriods: dropdownReviewPeriods,
  //             titleNumber: titleNumber,
  //             setTitleNumber: setTitleNumber,
  //             categories: categories
  //         }
  //     },
  //     {
  //         stepName: "Voice",
  //         stepIcon: "fa-solid fa-microphone",
  //         component: Step3,
  //         stepProps: {
  //             baseObject: baseObject,
  //             setBaseObject: setBaseObject,
  //             voicePreviewModel: voicePreviewModel,
  //             setVoicePreviewModel: setVoicePreviewModel,
  //             dropdownLanguages: dropdownLanguages,
  //             dropdownVoices: dropdownVoices,
  //             voicePreview: voicePreview,
  //             titleNumber: titleNumber,
  //             setTitleNumber: setTitleNumber
  //         }
  //     },
  //     {
  //         stepName: "Name",
  //         stepIcon: "fa-solid fa-pen",
  //         component: Step4,
  //         stepProps: {
  //             baseObject: baseObject,
  //             setBaseObject: setBaseObject,
  //             preparingPodcast: preparingPodcast
  //         }
  //     }
  // ];

  var bSteps = [
    {
      stepName: "Details",
      stepIcon: "fa-solid fa-globe",
      component: BStep1,
      stepProps: {
        baseObject: bBaseObject,
        setBaseObject: setBBaseObject,
        titleNumber: titleNumber,
        setTitleNumber: setTitleNumber,
        dropdownDurations: dropdownDurations,
        dropdownFrequencys: frequencyBusiness,
        dropdownClients: clientData,
        titleNumber: titleNumber,
        setTitleNumber: setTitleNumber,
        getAdverts: getAdverts,
        categories: categories,
        isBusiness: loginType === 1,
      },
    },
    {
      stepName: "Voice",
      stepIcon: "fa-solid fa-microphone",
      component: BStep2,
      stepProps: {
        baseObject: bBaseObject,
        setBaseObject: setBBaseObject,
        dropdownLanguages: dropdownLanguages,
        dropdownVoices: loginType === 1 ? premiumVoices : dropdownVoices,
        voicePreview: voicePreview,
        titleNumber: titleNumber,
        setTitleNumber: setTitleNumber,
        isVoicesLoaded: isVoicesLoaded,
        titleNumber: titleNumber,
        setTitleNumber: setTitleNumber,
        setVoicePreviewModel: setVoicePreviewModel,
        voicePreviewModel: voicePreviewModel,
        isBusiness: loginType === 1,
      },
    },
    {
      stepName: "Generate",
      stepIcon: "fa-solid fa-circle-info",
      component: BStep3,
      stepProps: {
        baseObject: bBaseObject,
        setBaseObject: setBBaseObject,
        dropdownVoices: premiumVoices,
        getEstimatedPodcastLength: getEstimatedPodcastLength,
        estimatedLength: estimatedLength,
        adverts: adverts,
        generateScriptForUser: generateScriptForUser,
        userGeneratedContent: userGeneratedContent,
        isScriptFailed: isScriptFailed,
        titleNumber: titleNumber,
        setTitleNumber: setTitleNumber,
        isBusiness: loginType === 1,
      },
    },
    {
      stepName: "Content",
      stepIcon: "fa-solid fa-circle-info",
      component: BStep4,
      stepProps: {
        baseObject: bBaseObject,
        setBaseObject: setBBaseObject,
        dropdownVoices: loginType === 1 ? premiumVoices : dropdownVoices,
        getEstimatedPodcastLength: getEstimatedPodcastLength,
        estimatedLength: estimatedLength,
        adverts: adverts,
        handleCreatePodcast: handleCreatePodcast,
        userGeneratedContent: userGeneratedContent,
        handleBusinessEpisodeCreate: handleBusinessEpisodeCreate,
        handleBusinessEpisodeStatus: handleBusinessEpisodeStatus,
        titleNumber: titleNumber,
        setTitleNumber: setTitleNumber,
        previousGeneration: previousGeneration,
        setB2BDownloadLink: setB2BDownloadLink,
        setB2BPreviewAudio: setB2BPreviewAudio,
        setGenerationStep: setGenerationStep,
        setIsInputFull: setIsInputFull,
        businesssEpisodeSectionUpdate: businesssEpisodeSectionUpdate,
        isBusiness: loginType === 1,
      },
    },
    {
      stepName: "Review",
      stepIcon: "fa-solid fa-circle-info",
      component: BStep5,
      stepProps: {
        baseObject: bBaseObject,
        setBaseObject: setBBaseObject,
        generationStatus: generationStatus,
        podcastDuration: podcastDuration,
        handleDownloadClicked: handleDownloadClicked,
        B2BPreviewAudio: B2BPreviewAudio,
        generationError: generationError,
        generationStep: generationStep,
        isBusiness: loginType === 1,
      },
    },
  ];

  useEffect(() => {
    if (titleNumber > 1) {
      window.dispatchEvent(new Event("userClickedNext"));
    }
  }, [titleNumber]);

  return (
    <>
      <TutorialModal
        showTutorial={showTutorial}
        pageName="createPodcast"
        title="Creating a podcast"
        text={tutorialText.CREATE_PODCAST}
        setShowTutorial={setShowTutorial}
      />
      <div className="wizardContent">
        <Col className="mr-auto ml-auto" md="10">
          {!showVideoTutorial ? (
            <>
              <ReactWizard
                steps={/*loginType === 0 ? steps :*/ bSteps}
                validate
                headerTextCenter
                finishButtonClasses={
                  !preparingPodcast ? "buttonStyle buttonPrimary" : ""
                }
                nextButtonClasses={
                  !isInputFull ? "buttonStyle buttonPrimary" : ""
                }
                previousButtonClasses={
                  !preparingPodcast ? "buttonStyle buttonSecondary" : ""
                }
                finishButtonClick={() => handleNavigatePodcast()}
                finishButtonText={
                  !preparingPodcast ? (
                    "Finish"
                  ) : (
                    <div>
                      <Spinner size="sm" style={{ marginRight: 5 }} /> Finish
                    </div>
                  )
                }
              />
              <u
                onClick={() => handleShowAgain()}
                style={{
                  textDecoration: "underline",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                <strong>Show tutorial</strong>
              </u>
            </>
          ) : (
            <>
              <Row className="justify-content-center pt-4 labelText">
                <h3>How to make a podcast</h3>
              </Row>
              <Col className="text-center labelText">
                <p style={{ fontSize: 16 }}>
                  If this is your first time creating a podcast with Klaxon.AI
                </p>
                <p style={{ fontSize: 16 }}>This video may be helpful</p>
                <div
                  style={{
                    backgroundColor: "grey",
                    border: "1px solid black",
                    height: 360,
                    width: 640,
                    margin: "auto",
                  }}
                ></div>
              </Col>
              <Col className="pt-4">
                <Row className="justify-content-center">
                  <button
                    className="buttonStyle buttonPrimary mb-3"
                    onClick={() => setShowVideoTutorial(false)}
                  >
                    I understand
                  </button>
                </Row>
                <Row className="justify-content-center labelText">
                  <u
                    onClick={() => handleHide()}
                    style={{
                      textDecoration: "underline",
                      color: "",
                      cursor: "pointer",
                    }}
                  >
                    <strong>Don't show again</strong>
                  </u>
                </Row>
              </Col>
            </>
          )}
        </Col>
      </div>
    </>
  );
}

export default CreatePodcast;
