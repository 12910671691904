import React from "react";
import { Row, Col, Spinner } from "reactstrap";
import MiniPlayer from "../../AudioPlayers/MiniPlayer";
import { useNavigate } from "react-router-dom";

const BStep5 = React.forwardRef((props, ref) => {
    const navigate = useNavigate();

    React.useImperativeHandle(ref, () => ({
        isValidated: () => {
            if (props.isBusiness) {
                if (props.generationStep === 1) {
                    return false
                } else {
                    return true
                }
            } else {
                return true;
            }
        },
    }));

    return (
        <>
            {props.generationStep === 1 && !props.generationError &&
                <Col>
                    <Row>
                        <Col className="text-center">
                            <h4 className="">Your podcast is being created</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <Spinner style={{ color: "#F33B30" }} />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <h4 className="text-white">{props.generationStatus}</h4>
                        </Col>
                    </Row>
                </Col>    
            }

            {props.generationStep === 2 && !props.generationError && props.isBusiness &&
                <Col>
                    <Row>
                        <Col className="text-center">
                            <h4 className="">Your podcast is ready.</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                            <button className="buttonPrimary buttonStyle" onClick={() => props.handleDownloadClicked(props.baseObject.name) }>Download</button>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center pt-4">
                            <div style={{width: "50%", margin: "auto"} }>
                                <MiniPlayer podcast={props.B2BPreviewAudio} podcastDuration={props.podcastDuration} />
                            </div>
                        </Col>
                    </Row>
                </Col>
            }

            {props.generationStep === 2 && !props.generationError && !props.isBusiness &&
                <Col>
                    <Row>
                        <Col className="text-center">
                            <h4 className="">Your podcast is ready.</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="text-center">
                        <button 
                                className="buttonPrimary buttonStyle" 
                                onClick={() => navigate('/admin/podcasts')} 
                            >
                                Listen Now
                            </button>
                        </Col>
                    </Row>
                </Col>
            }

            {props.generationError &&
                <Col>
                    <Row>
                        <Col className="text-center">
                            <h4 className="">There has been an error generating your podcast</h4>
                        </Col>
                    </Row>
                </Col>
            }
        </>
    );
});

export default BStep5;
