import { useEffect, useState } from "react"
import { FormGroup, Label} from "reactstrap"
import StripeGenericContainer from "../../Stripe/stripeGeneric/StripeGenericContainer"


const Credits = (props) => {
    const [selectedProduct, setSelectedProduct] = useState(null)

    const tempProductObject = {
        transactionType: 1,
        productId: 0,
        description: "",
        amount: 0
    }

    const handleSetProduct = (productId) => {
        setSelectedProduct(productId)

        if (!props.purchaseObject) {
            const copyTemp = { ...tempProductObject }
            copyTemp.productId = productId
            props.setPurchaseObject(copyTemp)
        } else {
            props.setPurchaseObject({ ...props.purchaseObject, productId: productId })
        }
    }

    useEffect(() => {
        setSelectedProduct(null)
    }, [])

    return (
        <div style={{ display: "flex", flexDirection: "column", gap: 15 }}>
            {!props.purchaseObject?.id ?
                <>
                    <p style={{margin: 0}} className="labelText">Credits are used to generate Adverts or Language Translations.</p>
                    <FormGroup>

                    </FormGroup>
                    {props.products && props.products.length > 0 &&
                        props.products.map((product, index) => {
                            return (
                                <div key={index} style={{ padding: 10, borderRadius: 7, display: "flex", flexDirection: "column", gap: 5, border: selectedProduct === product.id ? "2px solid #F33B30" : "2px solid transparent", cursor: "pointer" }} className="smallAltButton" onClick={() => handleSetProduct(product.id)}>
                                    <h3 style={{ margin: 0, display: "flex", alignItems: "center", justifyContent: "space-between" }}>{product.name} for &pound;{product.cost} <i className="fas fa-check-circle" /></h3>
                                    <p style={{ margin: 0, display: "flex", alignItems: "center", gap: 20 }}><i className="far fa-check-circle" />{index === 0 ? "Enough to create 10 English language adverts or translate your advert into 5 languages" : "Enough to create 25 English language adverts or translate your advert into 12 languages" }</p>
                                    <p style={{ margin: 0, display: "flex", alignItems: "center", gap: 20 }}><i className="far fa-check-circle" />No time limit</p>
                                </div>
                            )
                        })
                    }
                </> : <>
                    <div style={{ display: "flex", flexDirection: "column" }} className="labelText">
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <h3 style={{ margin: 0 }}>Product:</h3> <span style={{ marginTop: 5 }}>{props.products.find((product) => product.id == props.purchaseObject.productId).name}</span>
                        </div>
                        <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                            <h3 style={{ margin: 0 }}>Price: </h3><span style={{ marginTop: 5 }}>&pound;{props.purchaseObject?.amount}</span>
                        </div>
                        {/*<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>*/}
                        {/*    <h3 style={{ margin: 0 }}>Balance Used: </h3><span style={{ marginTop: 5 }}>&pound;{props.purchaseObject?.balanceUsed}</span>*/}
                        {/*</div>*/}
                        {/*<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>*/}
                        {/*    <h3 style={{ margin: 0 }}>Amount Due: </h3><span style={{ marginTop: 5 }}>&pound;{props.purchaseObject?.amountDue}</span>*/}
                        {/*</div>*/}
                        {/*<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>*/}
                        {/*    <h3 style={{ margin: 0 }}>Balance remaining after purchase: </h3><span style={{ marginTop: 5 }}>&pound;{props.userBalance - props.purchaseObject?.balanceUsed}</span>*/}
                        {/*</div>*/}
                        <div className="mt-4">
                            <StripeGenericContainer baseObject={props.purchaseObject} setBaseObject={props.setPurchaseObject} renewCredits={props.renewCredits} />
                        </div>
                    </div>
                </>
            }
        </div>
    )
}

export default Credits