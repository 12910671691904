import React from "react";

import {
    CardBody,
    Row,
    Col,
} from "reactstrap";
import ContactForm from "../../components/Forms/ContactForm";

function ContactUs() {
    return (
        <>

            <div className="content" style={{ marginTop: 78, paddingLeft: "5%", paddingRight: "5%" }}>
                <Row>
                    <Col md="12">
                        <CardBody className="mt-4 mb-4">
                            <Row>
                                <Col className="text-center d-flex flex-column" lg="6" md="12">
                                    <Row className="flex-fill" style={{ alignItems: "center" }}>
                                        <Col style={{ display: "flex", height: "150px", width: "100px", alignItems: "flex-end" }}><p style={{ backgroundColor: "#F33B30", color: "white", fontWeight: 600, padding: 20, borderRadius: 25 }}>"Wow such a great website"</p></Col>
                                        <Col style={{ display: "flex", height: "150px", width: "100px", alignItems: "flex-start", justifyContent: "center" }}><p style={{ backgroundColor: "grey", color: "white", fontWeight: 600, padding: 20, borderRadius: 25 }}>"You should add unicorns"</p></Col>
                                    </Row>
                                    <Row className="flex-fill">
                                        <Col className="text-center mt-4">
                                            <h3 className="text-white">What do <strong>you</strong> have to say?</h3>
                                        </Col>
                                    </Row>
                                    <Row className="flex-fill" style={{ alignItems: "center" }}>
                                        <Col style={{ display: "flex", height: "150px", width: "100px", alignItems: "flex-start", justifyContent: "center" }}><p style={{ backgroundColor: "grey", color: "white", fontWeight: 600, padding: 20, borderRadius: 25 }}>"More boxes? Hell yeah!"</p></Col>
                                        <Col style={{ display: "flex", height: "150px", width: "100px", alignItems: "flex-end" }}><p style={{ backgroundColor: "#F33B30", color: "white", fontWeight: 600, padding: 20, borderRadius: 25 }}>"John Johnson lives!"</p></Col>
                                    </Row>
                                </Col>
                                <Col className="d-flex justify-content-center" lg="6" md="12">
                                    <ContactForm />
                                </Col>
                            </Row>
                        </CardBody>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ContactUs;
