import { useState, useEffect } from "react"
import { Row, Col, Badge, Card, UncontrolledTooltip, Tooltip, Spinner } from "reactstrap"

function VoiceCard(props) {
    const [isTooltipOpen, setIsTooltipOpen] = useState(false)
    const [colorScheme, setColorScheme] = useState({
        backgroundColor: "#404040",
        color: "#FFF"
    })

    useEffect(() => {
        if (props.colorSchemeTheme !== "dark") {
            setColorScheme({
                ...colorScheme,
                backgroundColor: "#F0F0F0",
                color: "#000"
            })
        }
    }, [])

    const handleCopyToClipboard = (voiceId) => {
        if (!navigator.clipboard) {
            return
        }

        navigator.clipboard.writeText(voiceId)
            .then(() => {
                setIsTooltipOpen(true)
                setTimeout(() => {
                    setIsTooltipOpen(false)
                }, 1500)
            })
            .catch(err => {
                console.error("Something went wrong", err)
            })
    }

    const [voiceTags, setVoiceTags] = useState([])
    const formatTags = () => {

        let labelArrObject
        const colorCodes = {
            3: "#bf7fff",
            5: "#aec6cf",
            2: "#ff7f7f",
            1: "#ffa94d",
            4: "#7fc7ff"
        }
        if (props.isAdVoice) {

            const labelArr = props.voiceData.labels;
            if (labelArr && labelArr.length > 0) {
                labelArrObject = labelArr.map((tag) => {
                    return {
                        label: tag.name,
                        value: tag.name,
                        textColor: colorCodes[tag.typeId]
                    }
                })
            } else {
                return null
            }
        }

        if (props.isAdMusic) {
            const labelArr = props.voiceData.tags

            if (labelArr && labelArr.length > 0) {
                const colorValues = Object.values(colorCodes)
                labelArrObject = labelArr.map((tag, index) => {
                    return {
                        label: tag.name,
                        value: tag.name,
                        textColor: colorValues[index % colorValues.length]
                    }
                })

            } else {
                return null
            }
        }

        setVoiceTags(labelArrObject)

    }

    useEffect(() => {
        if (props.voiceData && (props.voiceData?.labels || props.voiceData?.tags)) formatTags()
    }, [props.voiceData])

    const [voiceName, setVoiceName] = useState(null)
    useEffect(() => {
        if (props.voiceData?.voiceName) {
            setVoiceName(props.voiceData.voiceName)
        } else if (props.voiceData.name) {
            setVoiceName(props.voiceData.name)
        }
    }, [props.voiceData])


    return (
        <Card className="" style={{ backgroundColor: colorScheme.backgroundColor, color: colorScheme.color, border: !props.showId && props.selectedVoice !== null ? props.selectedVoice === voiceName ? "2px solid #F33B30" : "2px solid transparent" : null }}>
            <Col
                onClick={
                    (props.handleSelectVoice)
                        ? () => props.handleSelectVoice(props.voiceData)
                        : (!props.isAdMusic && props.isAdVoice)
                            ? () => props.setSelectedModalObject({ ...props.selectedModalObject, voiceId: props.voiceData.voiceId, voiceAiValue: props.voiceData.voiceAiValue })
                            : (props.isAdMusic && !props.isAdVoice)
                                ? () => props.setSelectedModalObject({ ...props.selectedModalObject, musicId: props.voiceData.id })
                                : undefined
                }
            >
                <Row className="justify-content-between pl-3 pr-3 pt-2">
                    <h3 style={{ margin: 0 }}>{props.isAdMusic ? props.voiceData.name : props.voiceData.voiceName}</h3>

                    {props.showId &&
                        <>
                            <Badge
                                id={`voice${props.index}`}
                                pill
                                style={{ color: colorScheme.color, cursor: "pointer" }}
                                onClick={() => handleCopyToClipboard(props.voiceData.voiceId)}
                            >ID</Badge>
                            <UncontrolledTooltip
                                delay={0}
                                target={`voice${props.index}`}
                                style={{ backgroundColor: "#F33B30", color: "white" }}
                            >
                                {props.voiceData.voiceId}
                            </UncontrolledTooltip>
                            <Tooltip
                                isOpen={isTooltipOpen}
                                target={`voice${props.index}`}
                                style={{ backgroundColor: "#F33B30", color: "white" }}
                            >
                                Copied to clipboard!
                            </Tooltip>
                        </>
                    }

                </Row>
                <div className="pb-2 pt-2" style={{ display: "flex", flexWrap: "wrap", gap: 5 }}>
                    {voiceTags.map((e) => {
                        return (
                            <Badge
                                pill
                                style={{ color: "#fff", backgroundColor: e.textColor, border: e.textColor, margin: 0 }}
                            >{e.value}</Badge>
                        )
                    })}
                </div>
                <Row>
                    <button style={{ width: "100%", borderBottomLeftRadius: 12, borderBottomRightRadius: 12, border: "none", minHeight: 50, borderTop: `1px solid ${colorScheme.color}`, display: "flex", justifyContent: "center", alignItems: "center", gap: 15, backgroundColor: colorScheme.backgroundColor, color: colorScheme.color, fontSize: 20 }}
                        onClick={() => 
                            props.isBusiness ? 
                            props.handlePreviewVoice(props.isAdVoice ? props.voiceData.voiceAiValue : props.isAdMusic ? props.voiceData.url : props.voiceData.voicePreview)
                            : 
                            props.handlePreviewVoicePersonal(props.voiceData)}                        
                    >
                        {props.loadingAudio && props.loadingAudio.voiceModalPreview && props.loadingAudio.voiceIdString === props.voiceData.voiceAiValue ?
                            <>
                                <Spinner />
                            </> :
                            <>
                                {!props.isAdMusic ?
                                    props.currentPlayingVoice == props.voiceData.voiceId ?
                                        <i className="fa-solid fa-play" style={{ fontSize: 18 }} /> :
                                        <i className="fa-solid fa-volume-high" style={{ fontSize: 18 }} />
                                    :
                                    props.currentPlayingVoice == props.voiceData.id ?
                                        <i className="fa-solid fa-play" style={{ fontSize: 18 }} /> :
                                        <i className="fa-solid fa-volume-high" style={{ fontSize: 18 }} />




                                }
                                Preview
                            </>
                        }
                        
                    </button>
                </Row>
            </Col>
        </Card>
    )
}

VoiceCard.defaultProps = {
    handlePreviewVoice: null,
    handleSelectVoice: null,
    showId: true,
    colorSchemeTheme: "dark",
    selectedVoice: null,
    isAdVoice: false,
    isAdMusic: false,
    setSelectedModalObject: () => { },
    selectedModalObject: null,
    currentPlayingVoice: null,
};

export default VoiceCard